import React, { useEffect, useState, useContext } from "react";
import { Row, Col } from "react-bootstrap";
import TemplateMetricsCard from "../../components/dataRender/dashboardCards/TemplateMetricsCard";
import {HeaderContext} from "../../contexts/HeaderContext";
import BootstrapTablesWrapper from "../../components/dataRender/BootstrapTablesWrapper";
import PerformanceOverTime from "./charts/PerformanceOverTime";
import SpendPie from "./charts/SpendPie";
import {AuthContext} from "../../contexts/AuthContext";
import {BrandContext} from "../../contexts/BrandContext";
import fortress from "../../api/fortress";
import moment from "moment/moment";
import {DollarFormatter, IntFormatter} from "../../components/helper/NumberFormatters";
import WidgetSpinner from "../../components/helper/WidgetSpinner";


const PaidMedia = (props) => {
    const { token } = useContext(AuthContext);
    const { brand } = useContext(BrandContext);
    const { selectedDefaultDates,selectedPreviousCustomDates,currency,marketplace } = useContext(HeaderContext);

    const [executiveSummary, setExecutiveSummary] = useState({});

    const [paidMediaPerformanceColumns, setPaidMediaPerformanceColumns] = useState([]);
    const [paidMediaPerformanceRows, setPaidMediaPerformanceRows] = useState([]);
    const [activePill, setActivePill] = useState("spend");

    const [tacticPieData, setTacticPieData] = useState({spend:[0,0,0]});
    const [adTypePieData, setAdTypePieData] = useState({spend:[0,0,0,0]});


    const [metricsLoading, setMetricsLoading] = useState(true);
    const [tableLoading, setTableLoading] = useState(true);
    const [lineChartLoading, setLineChartLoading] = useState(true);
    const [pieTacticLoading, setPieTacticLoading] = useState(true);
    const [pieAdLoading, setPieAdLoading] = useState(true);
    const pieColors = ["#c2d0f2","#8ea0d5","#c3d404","#9dac00","#a2566c","#84344e"];

    const [lineChartData, setLineChartData] = useState({
        labels: [],
        data: [
            {
                label: "Spend",
                data: [
                ]
            }
        ]
    });

    const pageDollarFormatter = (value) => {
        return DollarFormatter(value, currency, brand.brandLocale);
    };

    const pieFormat = {
        spend: pageDollarFormatter,
        sales: pageDollarFormatter,
        traffic: IntFormatter,
        visibility: IntFormatter,
    };

    useEffect(() => {
        fortress.get("paid-media/fetch-paid-summary-metrics", {
            headers: { Authorization: `Bearer ${token}` },
            params: {
                brand_id: brand.brandId,
                currency: currency,
                start_date: moment(selectedDefaultDates.tf_s).format('YYYY-MM-DD'),
                end_date: moment(selectedDefaultDates.tf_e).format('YYYY-MM-DD'),
                custom_start_date: selectedPreviousCustomDates.pc_s,
                custom_end_date: selectedPreviousCustomDates.pc_e,
            },
        })
            .then(res =>{
                setExecutiveSummary({...res.data,
                    previous_total_spend: 0,
                    previous_total_sales: 0,
                    previous_traffic: 0,
                    previous_tacos: "0",
                    previous_brand_cost_share: "0",
                });
                setMetricsLoading(false);
            });

        fortress.get("paid-media/fetch-paid-summary-chart", {
            headers: { Authorization: `Bearer ${token}` },
            params: {
                brand_id: brand.brandId,
                currency: currency,
                start_date: moment(selectedDefaultDates.tf_s).format('YYYY-MM-DD'),
                end_date: moment(selectedDefaultDates.tf_e).format('YYYY-MM-DD'),
            },
        })
            .then(res =>{
                setLineChartData(res.data);
                setLineChartLoading(false);
            });

        fortress.get("paid-media/fetch-tactic-pie-metrics", {
            headers: { Authorization: `Bearer ${token}` },
            params: {
                brand_id: brand.brandId,
                currency: currency,
                start_date: moment(selectedDefaultDates.tf_s).format('YYYY-MM-DD'),
                end_date: moment(selectedDefaultDates.tf_e).format('YYYY-MM-DD'),
            },
        })
            .then(res =>{
                setTacticPieData(res.data);
                setPieTacticLoading(false);
            });

        fortress.get("paid-media/fetch-ad-type-pie-metrics", {
            headers: { Authorization: `Bearer ${token}` },
            params: {
                brand_id: brand.brandId,
                currency: currency,
                start_date: moment(selectedDefaultDates.tf_s).format('YYYY-MM-DD'),
                end_date: moment(selectedDefaultDates.tf_e).format('YYYY-MM-DD'),
            },
        })
            .then(res =>{
                setAdTypePieData(res.data);
                setPieAdLoading(false);
            });

        fortress.get("paid-media/fetch-paid-summary-table", {
            headers: { Authorization: `Bearer ${token}` },
            params: {
                brand_id: brand.brandId,
                currency: currency,
                start_date: moment(selectedDefaultDates.tf_s).format('YYYY-MM-DD'),
                end_date: moment(selectedDefaultDates.tf_e).format('YYYY-MM-DD'),
            },
        })
            .then(res =>{
                setPaidMediaPerformanceColumns(res.data.columns);
                setPaidMediaPerformanceRows(res.data.rows);
                setTableLoading(false);
            });

    },[token, brand, marketplace, selectedDefaultDates, selectedPreviousCustomDates, currency]);

return (
    <div className="p-3">
        <Row>
            {metricsLoading ? <WidgetSpinner /> : (
                <TemplateMetricsCard
                    visible={true}
                    data={executiveSummary}
                    vsPrevious="previous"
                    // dataMeta={{
                    //     'pt': {'pt_s': snsPerformanceData?.cardData?.pt_week_s ?? 'N/A', 'pt_e': snsPerformanceData?.cardData?.pt_week_e ?? 'N/A'},
                    // }}
                    pageDataLoading={false}
                    textCardProps={{
                        headerTitle: (
                            <span>
                            <h5 className="d-flex">Paid Media Executive Summary</h5>
                        </span>
                        ),
                        direction: "horizontal",
                        minHeight: "15em",
                        variant: "why is margin get added by default?"
                    }}
                    metricsTemplate={[
                        {
                            name: 'Total Spend',
                            metric: 'total_spend',
                            change_metric: 'total_spend',
                            type: 'currency',
                        },
                        {
                            name: 'Attributed Sales',
                            metric: 'total_sales',
                            change_metric: 'total_sales',
                            type: 'currency',
                        },
                        {
                            name: 'Attributed Traffic',
                            metric: 'traffic',
                            change_metric: 'traffic',
                            type: 'currency',
                        },
                        {
                            name: 'tACOS %',
                            metric: 'tacos',
                            change_metric: 'tacos',
                            type: 'percent',
                        },
                        {
                            name: 'Cost Share',
                            metric: 'brand_cost_share',
                            change_metric: 'brand_cost_share',
                            type: 'currency',
                        },
                    ]}
                />
            )
            }

        </Row>
        <Row className="mt-4">
            <Col className="col-md-12" style={{height: "34em"}}>
                {
                    lineChartLoading ? <WidgetSpinner /> :(
                        <PerformanceOverTime showConfigButton={false}
                                             headerTitle="Performance Over Time Spend vs Sales"
                                             widgetData={lineChartData}
                        />
                    )
                }
            </Col>
        </Row>
        <Row className="mt-4 mb-3">
            <Col>
                <div className="pill-list-horizontal">
                    <span className={activePill === "spend" ? 'active-pill':''} onClick={()=>{ setActivePill("spend") }}>Spend</span>
                    <span className={activePill === "sales" ? 'active-pill':''} onClick={()=>{ setActivePill("sales") }}>Sales</span>
                    <span className={activePill === "traffic" ? 'active-pill':''} onClick={()=>{ setActivePill("traffic") }}>Traffic</span>
                    <span className={activePill === "visibility" ? 'active-pill':''} onClick={()=>{ setActivePill("visibility") }}>Visibility</span>
                </div>
            </Col>
        </Row>
        <Row>
            <Col className="col-md-6" style={{height: "34em"}}>
                <SpendPie
                    widgetID={"tacticPie"}
                    headerTitle="Tactic"
                    widgetData={[{backgroundColor: pieColors,labels:["Defense","Incremental","Research"], data: tacticPieData[activePill]} ]}
                    widgetFormatter={pieFormat[activePill]}
                    widgetDataLoading={pieTacticLoading}
                />

            </Col>
            <Col className="col-md-6" style={{height: "34em"}}>
                <SpendPie
                    widgetID={"adTypePie"}
                    headerTitle="Ad Type"
                    widgetData={[{backgroundColor: pieColors,labels:["Sponsored Products","Sponsored Brands","Sponsored Display","DSP"], data: adTypePieData[activePill] }]}
                    widgetFormatter={pieFormat[activePill]}
                    widgetDataLoading={pieAdLoading}
                />

            </Col>
        </Row>
        <Row className="mt-4">
            <Col>
                {tableLoading ? <WidgetSpinner /> : (
                    <BootstrapTablesWrapper
                        data={paidMediaPerformanceRows}
                        columns={paidMediaPerformanceColumns}
                        columnModal="none"
                        keyField="date"
                        hasPagination={true}
                        hasFilter={false}
                    />
                )}
            </Col>
        </Row>
    </div>
    )
}

export default PaidMedia;