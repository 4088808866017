// import React from "react";

export function getOption(options, value) {
    const found = options.filter((option) => option.value === value)
    return found.length > 0 ? found[0] : undefined;
}
export function validateOptionValue(options, value) {
    return options.filter((options) => options.value === value)?.length > 0;
}

export function getArrayObjectValues(array, key) {
    return array.map((object) => object[key] ?? undefined).filter((value) => value !== undefined);
}

export function filterObjectKeys(obj, keysToKeep) {
    return Object.keys(obj)
        .filter(key => keysToKeep.includes(key))
        .reduce((acc, key) => {
            acc[key] = obj[key];
            return acc;
        }, {});
}