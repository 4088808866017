import React, { useContext, useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import { ResponsiveContainer } from "recharts";
import Chart from "react-apexcharts";
import ApexCharts from "apexcharts";
import moment from "moment";

import { timeline, overview } from "../../../api/ppc";

import { AuthContext } from "../../../contexts/AuthContext";
import { BrandContext } from "../../../contexts/BrandContext";
import { HeaderContext } from "../../../contexts/HeaderContext";
import { ToastContext } from "../../../contexts/ToastContext";

import {
    graph_blue,
    graph_blue_dark,
    graph_magenta,
    graph_red,
    defaultGraphLabelStyle,
} from "../../../components/helper/ColorFormatter";
import { CurrencyFormatter, PercentFormatter, NumberFormatter } from "../../../components/helper/IntlNumberFormatter";
import WidgetSpinner from "../../../components/helper/WidgetSpinner";

const calculateChangePerc = (currentValue, previousValue) => {
    if (!isNaN(previousValue) && !isNaN(currentValue)) {
        if (previousValue > 0) {
            return (currentValue - previousValue) / previousValue;
        } else {
            return 1.0;
        }
    }
    return null;
};

function CampaignTimeline(props) {
    const { token } = useContext(AuthContext);
    const { selectedDefaultDates, marketplace, currency } = useContext(HeaderContext);

    const [data, setData] = useState({});
    const [overviewData, setOverviewData] = useState([]);
    const [widgetLoading, setWidgetLoading] = useState(true);

    const [widgetOptions, setWidgetOptions] = useState({
        widgetTitle: "Campaign Timeline",
        showConfigButton: true,
        metricPrefix: "Paid",
        chartId: [...Array(16)].map(() => Math.random().toString(36)[2]).join(""),

        ...props,
    });

    const { brand } = useContext(BrandContext);
    const { toast } = useContext(ToastContext);

    useEffect(() => {
        const getData = async () => {
            setWidgetLoading(true);
            try {
                const res = await timeline(token, {
                    brandid: brand.brandId,
                    tf_s: moment(selectedDefaultDates.tp_s).format('YYYY-MM-DD'),
                    tf_e: moment(selectedDefaultDates.tp_e).format('YYYY-MM-DD'),
                    wow: selectedDefaultDates.timeframe,
                    currency: currency,
                    marketplace: marketplace,
                });

                const res2 = await overview(token, {
                    brandid: brand.brandId,
                    tf_s: moment(selectedDefaultDates.tp_s).format('YYYY-MM-DD'),
                    tf_e: moment(selectedDefaultDates.tp_e).format('YYYY-MM-DD'),
                    wow: selectedDefaultDates.timeframe,
                    currency: currency,
                    marketplace: marketplace,
                });

                setData(res.data[0]);
                setOverviewData(res2.data[0]);

                setWidgetLoading(false);

                ApexCharts.exec(widgetOptions.chartId, "toggleSeries", "Spend");
                ApexCharts.exec(widgetOptions.chartId, "toggleSeries", "CPC");
            } catch (err) {
                setWidgetLoading(false);
                toast(err.message);
            }
        };
        getData();
    }, [token, brand.brandId, setData, toast, selectedDefaultDates, currency, marketplace]);

    const cleanDollarFormatter = new Intl.NumberFormat(brand?.currencyLocale ?? "en-US", {
        style: "currency",
        currency: currency,
        maximumFractionDigits: 2,
        minimumFractionDigits: 0,
    });

    const wholeDollarFormatter = new Intl.NumberFormat(brand?.currencyLocale ?? "en-US", {
        style: "currency",
        currency: currency,
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
    });

    const getPercentColorFormatter = (value, params) => {
        let extra = params?.extra ?? undefined;
        let show_change = params?.show_change ?? false;
        let absolute_value = params?.absolute_value ?? true;

        return (
            <PercentFormatter
                colorText={true}
                colorBackground={false}
                displayArrow={true}
                replaceEmpty={true}
                showChange={show_change}
                displayAbsoluteValue={absolute_value}
                showExtra={extra}
                value={value}
            />
        );
    };

    const getRoasFormatter = (value) => {
        return <NumberFormatter value={value} displayDecimal={true} replaceEmpty={true} showExtra={"x"} />;
    };

    const renderChart = () => {
        let xaxisType = "category";

        let widgetColors = [
            graph_blue_dark,
            graph_red,
            graph_magenta,
            graph_blue,
        ];

        if (
            selectedDefaultDates.timeframe !== "mom" &&
            selectedDefaultDates.timeframe !== "quarter" &&
            selectedDefaultDates.timeframe !== "annual"
        ) {
            xaxisType = "datetime";
        }
        let chartOptions = {
            chart: {
                id: widgetOptions.chartId,
                toolbar: { show: false },
                animations: { enabled: false },
                dropShadow: { enabled: false },
                zoom: { enabled: false },
                height: "100%",
                type: "line",
            },
            legend: { show: true, position: "bottom", horizontalAlign: "center", floating: false, offsetY: 0, offsetX: 0 },
            colors: widgetColors,
            dataLabels: {
                enabled: false,
                enabledOnSeries: [0, 1],
                style: {
                    fontSize: "13px",
                    fontFamily: "Montserrat, sans-serif",
                },
                formatter: function (value, { seriesIndex, dataPointIndex, w }) {
                    return cleanDollarFormatter.format(value);
                },
                background: {
                    enabled: true,
                },
                offsetY: -10,
            },
            stroke: {
                show: true,
                curve: "smooth",
                lineCap: "butt",
                colors: widgetColors,
                width: [3, 3, 3, 3],
                dashArray: [0, 0, 0, 5]
            },
            grid: {
                show: true,
                borderColor: "#eaecec",
                xaxis: { lines: { show: true } },
                yaxis: { lines: { show: true } },
                strokeDashArray: [5, 0],
            },
            markers: {
                size: [1, 1, 1, 1],
                strokeWidth: [5, 2, 2, 2],
                colors: widgetColors,
                strokeColors: widgetColors,
                strokeOpacity: 1,
                fillOpacity: 1,
                hover: { sizeOffset: 1 },
            },
            tooltip: {
                shared: true,
                followCursor: true,
                y: {
                    formatter: function (value, { series, seriesIndex, dataPointIndex, w }) {
                        return cleanDollarFormatter.format(value);
                    },
                },
            },

            xaxis: {
                categories: data?.timeline_columned?.date ?? [],
                type: "category",
                labels: {
                    formatter: (value, timestamp, index) => {
                        if (selectedDefaultDates.timeframe === "mom") {
                            return moment(value).format("MMM YYYY");
                        }
                        if (selectedDefaultDates.timeframe === "wow") {
                            return moment(value).format("MMM DD, YYYY");
                        }
                        if (selectedDefaultDates.timeframe === "daily") {
                            return moment(value).format("MMM DD, YYYY");
                        }
                        return moment(value).format("MMM DD, YYYY");
                    },
                    style: defaultGraphLabelStyle,
                },
                tickPlacement: "on",
            },
            yaxis: [
                {
                    title: {
                        text: "",
                    },
                    opposite: false,
                    show: true,
                    seriesName: "Sales",
                    labels: {
                        formatter: (val) => wholeDollarFormatter.format(val),
                        style: defaultGraphLabelStyle,
                    },
                },
                {
                    title: {
                        text: "",
                    },
                    opposite: true,
                    show: false,
                    seriesName: "Sales",
                    labels: {
                        formatter: (val) => wholeDollarFormatter.format(val),
                        style: defaultGraphLabelStyle,
                    },
                },
                {
                    title: {
                        text: "",
                    },
                    opposite: true,

                    labels: {
                        formatter: (val) => wholeDollarFormatter.format(val),
                        style: defaultGraphLabelStyle,
                    },
                },
                {
                    title: {
                        text: "",
                    },
                    opposite: false,
                    show: false,
                    seriesName: "Sales",
                    labels: {
                        formatter: (val) => wholeDollarFormatter.format(val),
                        style: defaultGraphLabelStyle,
                    },
                },
            ],
        };

        let series = [
            {
                name: "Sales",
                data: data?.timeline_columned[widgetOptions.metricPrefix + " Sales"] ?? [],
                type: "line",
                show: false,
            },
            {
                name: "Spend",
                data: data?.timeline_columned[widgetOptions.metricPrefix + " Spend"] ?? [],
                type: "line",
                show: false,
            },
            {
                name: "CPC",
                data: data?.timeline_columned[widgetOptions.metricPrefix + " CPC"] ?? [],
                type: "line",
                show: false,
            },
            {
                name: "Sales Last Year",
                data: data?.previousTimeline_columned[widgetOptions.metricPrefix + " Sales"] ?? [],
                type: "line",
                show: false,
            },
        ];

        return (
            <ResponsiveContainer>
                <Chart options={chartOptions} series={series} type="line" />
            </ResponsiveContainer>
        );
    };

    const renderNumbers = () => {
        return (
            <div className="d-flex w-100 h-100 flex-column justify-content-center align-items-center">
                <div className="justify-content-center align-items-center">
                    <div className="w-100 text-center text-secondary">
                        <span className="h6">SALES</span>
                    </div>
                    <div className="w-100 text-center">
                        <span className="h1">
                            {
                                <CurrencyFormatter
                                    value={overviewData.sum[0][widgetOptions.metricPrefix + " Sales"]}
                                    displayDecimal={false}
                                    replaceEmpty={true}
                                />
                            }
                        </span>
                    </div>
                    <div className="w-100 pt-2 mb-0 text-center">
                        <span className="body-2">
                            {getPercentColorFormatter(
                                calculateChangePerc(
                                    overviewData.sum[0][widgetOptions.metricPrefix + " Sales"],
                                    overviewData.previousYearSum[0][widgetOptions.metricPrefix + " Sales"]
                                )
                            )}
                        </span>
                    </div>
                </div>

                <div className={`p-2 w-100 d-flex align-items-center mt-5`}>
                    <div className="d-flex px-2 text-left text-secondary">
                        <span className="body-1">Spend</span>
                    </div>
                    <div className="d-flex px-2 flex-fill justify-content-end">
                        <span className="body-1">
                            {
                                <CurrencyFormatter
                                    value={overviewData.sum[0][widgetOptions.metricPrefix + " Spend"]}
                                    displayDecimal={false}
                                    replaceEmpty={true}
                                />
                            }
                        </span>
                    </div>
                    <div className="d-flex px-2 flex-nowrap justify-content-end" style={{ minWidth: "120px" }}>
                        <span className="body-2">
                            {getPercentColorFormatter(
                                calculateChangePerc(
                                    overviewData.sum[0][widgetOptions.metricPrefix + " Spend"],
                                    overviewData.previousYearSum[0][widgetOptions.metricPrefix + " Spend"]
                                )
                            )}
                        </span>
                    </div>
                </div>
                <div className={`p-2 w-100 d-flex align-items-center  border-top mt-2`}>
                    <div className="d-flex px-2 text-left text-secondary">
                        <span className="body-1">RoAS</span>
                    </div>
                    <div className="d-flex px-2 flex-fill justify-content-end">
                        <span className="body-1">
                            {getRoasFormatter(overviewData.sum[0][widgetOptions.metricPrefix + " RoAS"])}
                        </span>
                    </div>
                    <div className="d-flex px-2 flex-nowrap justify-content-end" style={{ minWidth: "120px" }}>
                        <span className="body-2">
                            {getPercentColorFormatter(
                                calculateChangePerc(
                                    overviewData.sum[0][widgetOptions.metricPrefix + " RoAS"],
                                    overviewData.previousYearSum[0][widgetOptions.metricPrefix + " RoAS"]
                                )
                            )}
                        </span>
                    </div>
                </div>

                <div className={`p-2 w-100 d-flex align-items-center border-top border-bottom mt-2`}>
                    <div className="d-flex px-2 text-left text-secondary">
                        <span className="body-1">CPC</span>
                    </div>
                    <div className="d-flex px-2 flex-fill justify-content-end">
                        <span className="body-1">
                            {
                                <CurrencyFormatter
                                    value={overviewData.sum[0][widgetOptions.metricPrefix + " CPC"]}
                                    displayDecimal={true}
                                    replaceEmpty={true}
                                />
                            }
                        </span>
                    </div>
                    <div className="d-flex px-2 flex-nowrap justify-content-end" style={{ minWidth: "120px" }}>
                        <span className="body-2">
                            {getPercentColorFormatter(
                                calculateChangePerc(
                                    overviewData.sum[0][widgetOptions.metricPrefix + " CPC"],
                                    overviewData.previousYearSum[0][widgetOptions.metricPrefix + " CPC"]
                                )
                            )}
                        </span>
                    </div>
                </div>
            </div>
        );
    };

    // console.log(overviewData);
    return (
        <div className="card h-100" /*style={{ overflow: "auto" }}*/>
            <div className="card-header white bg-white bg-white">
                <div className="d-flex flex-row align-items-center">
                    <div className="pr-2 ml-4 m-0 ">
                        <h5 className="mb-2 mt-2">{widgetOptions.widgetTitle}</h5>
                    </div>

                    <div className="ml-auto ">
                        {widgetOptions.showConfigButton === true ? (
                            <button
                                type="button"
                                className="btn btn-primary btn-sm "
                                onClick={() => {
                                    //TODO: Add Configuration Modal
                                }}
                            >
                                Config
                            </button>
                        ) : null}
                    </div>
                </div>
            </div>

            <div className="card-body w-100 h-100 pt-0 pb-0">
                <Row style={{ height: "100%" }}>
                    <Col sm={8} style={{ height: "100%" }}>
                        {widgetLoading ? <WidgetSpinner /> : renderChart()}
                    </Col>
                    <Col sm={4} style={{ height: "100%" }}>
                        {widgetLoading ? <WidgetSpinner /> : renderNumbers()}
                    </Col>
                </Row>
            </div>
        </div>
    );
}

export default CampaignTimeline;
