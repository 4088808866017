import React, { useState, useEffect } from "react";
import { ResponsiveContainer } from "recharts";
import Chart from "react-apexcharts";

import {
    graph_green,
    graph_blue_light,
    graph_gray_dark,
    defaultGraphLabelStyle,
} from "../../../components/helper/ColorFormatter";
import WidgetSpinner from "../../../components/helper/WidgetSpinner";

function BundleLine(props) {
    // console.log('BundleLine', props);

    const {
        widgetID,
        headerTitle = "Bundle Performance over Time",
        headerSubtitle,
        widgetDataLoading = true,
        widgetLabel,
        widgetFormatter,
        widgetData,
    } = props;

    const [widgetLoading, setWidgetLoading] = useState(widgetDataLoading);
    
    useEffect(() => {
        setWidgetLoading(widgetDataLoading && widgetData);
    }, [widgetDataLoading, widgetData]);

    const renderChart = () => {
        const seriesLabels = widgetData?.labels ?? [];
        const seriesColors = (widgetLabel === 'Bundle Units') ? graph_gray_dark 
                        : (widgetLabel === 'Bundle Sales') ? graph_green : graph_blue_light;

        const series = [
            {
                name: widgetLabel ?? "Bundle",
                data: widgetData?.data ?? [],
                type: "line",
                color: seriesColors,
            },
        ];

        const chartOptions = {
            chart: {
                id: widgetID,
                type: "line",
                toolbar: {
                    show: false,
                },
                zoom: {
                    enabled: false,
                },
                dropShadow: {
                    enabled: false,
                },
                animations: {
                    enabled: false,
                },
            },
            colors: [seriesColors],
            legend: { show: false },
            widgetLabels: { enabled: false},
            stroke: {
                show: true,
                curve: "smooth",
                lineCap: "butt",
                colors: [seriesColors],
                width: [3],
            },
            grid: {
                show: true,
                borderColor: "#eaecec",
                xaxis: { lines: { show: true } },
                yaxis: { lines: { show: true } },
            },
            markers: {
                size: [1],
                colors: [seriesColors],
                strokeColors: [seriesColors],
                strokeWidth: [5],
                strokeOpacity: 1,
                fillOpacity: 1,
                hover: { sizeOffset: 1 },
            },
            tooltip: {
                shared: true,
                followCursor: true,
                y: {
                    formatter: function (val, w) {
                        return widgetFormatter(val);
                    },
                },
            },
            xaxis: {
                categories: seriesLabels,
                type: "datetime",
                labels: {
                    style: defaultGraphLabelStyle,
                },
                tickPlacement: "between",
            },
            yaxis: {
                title: {
                    text: "",
                },
                min: 0,
                labels: {
                    formatter: (val) => widgetFormatter(val),
                    style: defaultGraphLabelStyle,
                },
            },
        };

        if (widgetData?.labels?.length > 0
            && widgetData?.data?.length > 0
        ) {
            return (
                <ResponsiveContainer>
                    <Chart options={chartOptions} series={series} type="line" />
                </ResponsiveContainer>
            )
        }
        
        return (
            <span className="d-block h-100 w-100 align-content-center text-center text-bold bg-gray-light">No Data</span>
        );
    };

    return (
        <div className="card h-100 border-0" /*style={{ overflow: "auto" }}*/>
            <div className="card-header bg-white">
                <div className="d-flex flex-row align-items-center">
                    <div>
                        <h5>{headerTitle}</h5>
                        {headerSubtitle && (<p className="body-1 text-secondary mb-0">{headerSubtitle}</p>)}
                    </div>
                </div>
            </div>

            <div className="card-body w-100">{widgetLoading ? <WidgetSpinner /> : renderChart()}</div>
        </div>
    );
}

export default BundleLine;
