import React, { useContext, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Row, Col, Button, Card } from "react-bootstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignOutAlt } from "@fortawesome/free-solid-svg-icons";

import { AuthContext } from "../../contexts/AuthContext";
import { BrandContext } from "../../contexts/BrandContext";
import { HeaderContext } from "../../contexts/HeaderContext";

import BrandSelect from "../../components/header/BrandSelect";

import logo from "../../assets/logo_white.svg";

const ClientWizard = ({ history }) => {
    const { setLogOut } = useContext(AuthContext);
    const { brand, setSelectedBrand } = useContext(BrandContext);
    const { getDefaultDates, setDates } = useContext(HeaderContext);

    const wizardWidth = 600;

    const [disableSaveExit, setDisableSaveExit] = useState(true);
    
    const [selectedBrandSelect, setSelectedBrandSelect] = useState(brand);

    useEffect(() => {
        setDates(getDefaultDates())
    }, []);

    useEffect(() => {
        if (selectedBrandSelect?.brandId) {
            setDisableSaveExit(false);
        }
    }, [selectedBrandSelect]);

    // Update Brand on change
    const onBrandSelectChange = (newValue) => {
        setSelectedBrandSelect(newValue);
    };

    // Save Brand + Storyline on save
    const onButtonSubmit = () => {
        new Promise((resolve, reject) => {
            setSelectedBrand(selectedBrandSelect.brandId);
            setDates(getDefaultDates())

            resolve();
        }).then(() => {
            history.replace("/brand/summary");
        });
    };

    const onLogOut = async () => {
        setLogOut();
    };

    return (
        <div
            className="d-flex flex-column 
                align-items-center justify-content-center bg-gray
            "
            style={{ overflow: "auto", minHeight: "100vh" }}
        >
            <Card
                className="
                    align-items-center bg-gray
                    p-4 m-4 w-100
                "
                style={{ maxWidth: `${wizardWidth}px`, borderColor: 'transparent' }}
            >
                <Col className="w-100 px-0">
                    <div className="d-flex align-items-center justify-content-center">
                      <img src={logo} alt="Catapult" width="520" height="133"></img>
                    </div>

                    <div className="d-flex align-items-center justify-content-between mt-4">
                        <Col className="text-left">
                            {selectedBrandSelect?.brandId !== undefined ? (
                                <Link to="/" className="body-1-bold text-dark">
                                    <span>Back</span>
                                </Link>
                            ) : (
                                ""
                            )}
                        </Col>
                        <Col className="text-right">
                            <Button className="body-1-bold text-dark p-0" variant="link" onClick={onLogOut}>
                                <span className="mr-2"> Log Out </span>
                                <FontAwesomeIcon size={"1x"} icon={faSignOutAlt} />
                            </Button>
                        </Col>
                    </div>
                </Col>

                {/* <Col className="w-100 bg-white border border-dark rounded mt-2 justify-content-center"> */}
                <Col className="w-100 justify-content-center">
                    <Row className="mt-2">
                        <Col>
                            <BrandSelect brand={selectedBrandSelect} onChange={onBrandSelectChange} wizard={true} />
                        </Col>
                    </Row>

                    <Row className="my-4">
                        <Col>
                            <Button
                                className="btn btn-lg btn-block"
                                variant="primary"
                                disabled={disableSaveExit}
                                onClick={onButtonSubmit}
                                type="submit"
                            >
                                Continue
                            </Button>
                        </Col>
                    </Row>
                </Col>
            </Card>
        </div>
    );
};

export default ClientWizard;
