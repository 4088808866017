import React, { useEffect, useState, useContext } from "react";
import { Row, Col, Card, Form, InputGroup, ButtonGroup, ToggleButton } from "react-bootstrap";
import moment from "moment";

import { overview } from "../../api/ppc";
import { AuthContext } from "../../contexts/AuthContext";
import { BrandContext } from "../../contexts/BrandContext";
import { HeaderContext } from "../../contexts/HeaderContext";
import { ToastContext } from "../../contexts/ToastContext";

import Loading from "../../components/helper/Loading";
import TextCard from "../BrandReport/widgets/TextCard";
import useIsMountedRef from "../../components/helper/isMountedRef";
import { CurrencyFormatter, PercentFormatter, NumberFormatter } from "../../components/helper/IntlNumberFormatter";
import ChartDescription from "../../components/helper/ChartDescription";
import BootstrapTablesWrapper from "../../components/dataRender/BootstrapTablesWrapper";

import "./chartStyle.css";

const Overview = (props) => {
    //Grabs user data from AuthContext.js
    const { user, token } = useContext(AuthContext);
    const { brand } = useContext(BrandContext);
    const { toast } = useContext(ToastContext);
    const { 
        setPageTitle,
        pageTitle,
        setPageTimeframe,
        setPageDisplayTimeFrameInterval,
        setPageMarketplace,
        selectedDefaultDates,
        selectedPreviousCustomDates,
        marketplace,
        currency,
        setPageComparativeSelector,
        setComparativeOptions,
        comparativeValue
    } = useContext(HeaderContext);

    const isMountedRef = useIsMountedRef();

    const [data, setData] = useState({});
    const [vsPrevious, setVsPrevious] = useState("py");
    const [loading, setLoading] = useState(true);

    const columns = [
        {
            dataField: "Type",
            text: "Type",
            sort: true,
        },
        {
            text: "Spend",
            dataField: "Spend",
            sort: true,
            formatter: (cell, row, rowIndex) => {
                return <CurrencyFormatter value={cell} displayDecimal={"clean"} replaceEmpty={true} />;
            },
        },
        {
            text: "Sales",
            dataField: "Sales",
            sort: true,
            formatter: (cell, row, rowIndex) => {
                return <CurrencyFormatter value={cell} displayDecimal={"clean"} replaceEmpty={true} />;
            },
        },
        {
            text: "RoAS",
            dataField: "RoAS",
            sort: true,
            formatter: (cell, row, rowIndex) => {
                if (cell) return getRoasFormatter(cell);
            },
        },
        {
            text: "ACOS",
            dataField: "ACOS",
            sort: true,
            formatter: (cell, row, rowIndex) => {
                return <PercentFormatter value={cell} replaceEmpty={true} />;
            },
        },
        {
            text: "CPC",
            dataField: "CPC",
            sort: true,
            formatter: (cell, row, rowIndex) => {
                return <CurrencyFormatter value={cell} displayDecimal={"clean"} replaceEmpty={true} />;
            },
        },
    ];

    /**
     * useEffect with no second arguments = Run on every render
     *
     * Return statement is the cleanup function, runs when the component unmounts (Usually used to cleanup. Here it is used to clean up the page title)
     */
    useEffect(() => {
        setPageTitle("Paid Media Overview");
        setPageMarketplace('Amazon');
        setPageTimeframe("snapshot");
        setPageDisplayTimeFrameInterval(false);
        setComparativeOptions([
            { label: 'Previous Year', value: 'py' },
            // { label: 'Previous Month', value: 'pmsd' },
            { label: 'Previous Days', value: 'pd' },
            { label: 'Custom Date', value: 'pc' },
        ])
        setPageComparativeSelector(true);
        return () => {
            setPageTitle(process.env.REACT_APP_TITLE);
        };
    },[pageTitle]);

    /**
     * useEffect with empty array as second argument = Run only once (on the first render)
     */
    useEffect(() => {
        const getData = async () => {
            setLoading(true);
            try {
                const res = await overview(token, {
                    brandid: brand.brandId,
                    tf_s: moment(selectedDefaultDates.tp_s).format('YYYY-MM-DD'),
                    tf_e: moment(selectedDefaultDates.tp_e).format('YYYY-MM-DD'),
                    pc_s: selectedPreviousCustomDates.pc_s,
                    pc_e: selectedPreviousCustomDates.pc_e,
                    wow: 'daily', // snapshot timeframe and dates
                    currency: currency,
                    marketplace: marketplace,
                });

                res.data[0].sum.map((val) => {
                    Object.keys(val).forEach((key) => {
                        if (key !== "date") {
                            val[key] = parseFloat(val[key]);
                        }
                    });
                    return val;
                });

                res.data[0].previousSum.map((val) => {
                    Object.keys(val).forEach((key) => {
                        if (key !== "date") {
                            val[key] = parseFloat(val[key]);
                        }
                    });
                    return val;
                });

                res.data[0].previousYearSum.map((val) => {
                    Object.keys(val).forEach((key) => {
                        if (key !== "date") {
                            val[key] = parseFloat(val[key]);
                        }
                    });
                    return val;
                });

                res.data[0].tableData = [
                    {
                        Type: "Sponsored Products",
                        Spend: res.data[0].sum[0]["SP Spend"],
                        Sales: res.data[0].sum[0]["SP Sales"],
                        RoAS: res.data[0].sum[0]["SP RoAS"],
                        ACOS: res.data[0].sum[0]["SP ACoS"],
                        CPC: res.data[0].sum[0]["SP CPC"],
                    },
                    {
                        Type: "Sponsored Brands",
                        Spend: res.data[0].sum[0]["SB Spend"],
                        Sales: res.data[0].sum[0]["SB Sales"],
                        RoAS: res.data[0].sum[0]["SB RoAS"],
                        ACOS: res.data[0].sum[0]["SB ACoS"],
                        CPC: res.data[0].sum[0]["SB CPC"],
                    },
                    {
                        Type: "Sponsored Display",
                        Spend: res.data[0].sum[0]["SD Spend"],
                        Sales: res.data[0].sum[0]["SD Sales"],
                        RoAS: res.data[0].sum[0]["SD RoAS"],
                        ACOS: res.data[0].sum[0]["SD ACoS"],
                        CPC: res.data[0].sum[0]["SD CPC"],
                    },
                    {
                        Type: "DSP",
                        Spend: res.data[0].sum[0]["DSP Spend"],
                        Sales: res.data[0].sum[0]["DSP Sales"],
                        RoAS: ((res?.data[0]?.sum[0]["DSP Spend"] ?? 0) > 0 ? (res?.data[0]?.sum[0]["DSP Sales"] ?? 0) / (res?.data[0]?.sum[0]["DSP Spend"] ?? 0) : 0),
                        ACOS: ((res?.data[0]?.sum[0]["DSP Sales"] ?? 0) > 0 ? (res?.data[0]?.sum[0]["DSP Spend"] ?? 0) / (res?.data[0]?.sum[0]["DSP Sales"] ?? 0) : 0),
                        CPC: ((res?.data[0]?.sum[0]["DSP Clicks"] ?? 0) > 0 ? (res?.data[0]?.sum[0]["DSP Spend"] ?? 0) / (res?.data[0]?.sum[0]["DSP Clicks"] ?? 0) : 0),
                    },
                ];

                setData(res.data[0]);

                setLoading(false);
            } catch (err) {
                setLoading(false);
                toast(err.message);
            }
        };

        if (Object.keys(selectedDefaultDates).length > 0) {
            getData();
        }
    }, [brand.brandId, selectedDefaultDates, selectedPreviousCustomDates, currency, token, marketplace]);

    const getPercentColorFormatter = (value, params) => {
        let extra = params?.extra ?? undefined;
        let show_change = params?.show_change ?? false;
        let absolute_value = params?.absolute_value ?? true;
        let display_decimal = params?.display_decimal ?? true;

        return (
            <PercentFormatter
                colorText={true}
                colorBackground={false}
                displayArrow={true}
                replaceEmpty={true}
                showChange={show_change}
                displayAbsoluteValue={absolute_value}
                showExtra={extra}
                displayDecimal={display_decimal}
                value={value}
            />
        );
    };

    const getPreviousValue = (pd_value, py_value, pc_value) => {
        if (comparativeValue.value === 'pd') {
            return pd_value;
        }
        else if(comparativeValue.value === 'pc'){
            return pc_value;
        }
        else {
            return py_value;
        }
    }

    const getRoasFormatter = (value) => {
        return <NumberFormatter value={value} displayDecimal={true} replaceEmpty={true} showExtra={"x"} />;
    };

    const calculateChangePerc = (currentValue, previousValue) => {
        if (!isNaN(previousValue) && !isNaN(currentValue)) {
            if (previousValue > 0) {
                return (currentValue - previousValue) / previousValue;
            } else {
                if (previousValue == 0 && currentValue == 0) {
                    return 0;
                }
                return 1.0;
            }
        }
        return null;
    };

    return loading === true ? (
        <Loading />
    ) : (
        <main className="d-flex justify-content-start align-items-top">
            <Col>
                <Row className="mt-5">
                    <Col sm={12}>
                        <TextCard
                            headerTitle={"Overview"}
                            variant={"none"}
                            direction="horizontal"
                            minHeight={"auto"}
                            data={
                                data && [
                                    [
                                        "PPC Sales",
                                        <CurrencyFormatter
                                            value={data.sum[0]["Paid Sales"]}
                                            displayDecimal={false}
                                            replaceEmpty={true}
                                        />,
                                        getPercentColorFormatter(
                                            calculateChangePerc(
                                                data.sum[0]["Paid Sales"],
                                                getPreviousValue(
                                                    data.previousSum[0]["Paid Sales"]
                                                    , data.previousYearSum[0]["Paid Sales"]
                                                    , data.previous_custom_sum[0]["Paid Sales"]
                                                )
                                            )
                                        ),
                                        <div>
                                            <p className="mb-0">Previous Paid Sales</p>
                                            <p className="mb-0">
                                                {
                                                    <CurrencyFormatter
                                                        value={getPreviousValue(
                                                            data.previousSum[0]["Paid Sales"]
                                                            , data.previousYearSum[0]["Paid Sales"]
                                                            , data.previous_custom_sum[0]["Paid Sales"]
                                                        )}
                                                        displayDecimal={false}
                                                        replaceEmpty={true}
                                                    />
                                                }
                                            </p>
                                        </div>,
                                    ],
                                    [
                                        "PPC Spend",
                                        <CurrencyFormatter
                                            value={data.sum[0]["Paid Spend"]}
                                            displayDecimal={false}
                                            replaceEmpty={true}
                                        />,
                                        getPercentColorFormatter(
                                            calculateChangePerc(
                                                data.sum[0]["Paid Spend"],
                                                getPreviousValue(
                                                    data.previousSum[0]["Paid Spend"]
                                                    , data.previousYearSum[0]["Paid Spend"]
                                                    , data.previous_custom_sum[0]["Paid Spend"]
                                                )
                                            )
                                        ),
                                        <div>
                                            <p className="mb-0">Previous Paid Spend</p>
                                            <p className="mb-0">
                                                {
                                                    <CurrencyFormatter
                                                        value={getPreviousValue(
                                                            data.previousSum[0]["Paid Spend"]
                                                            , data.previousYearSum[0]["Paid Spend"]
                                                            , data.previous_custom_sum[0]["Paid Spend"]
                                                        )}
                                                        displayDecimal={false}
                                                        replaceEmpty={true}
                                                    />
                                                }
                                            </p>
                                        </div>,
                                    ],
                                    [
                                        "PPC Orders",
                                        <NumberFormatter
                                            value={data.sum[0]["Paid Orders"]}
                                            displayDecimal={false}
                                            replaceEmpty={true}
                                        />,
                                        getPercentColorFormatter(
                                            calculateChangePerc(
                                                data.sum[0]["Paid Orders"],
                                                getPreviousValue(
                                                    data.previousSum[0]["Paid Orders"]
                                                    , data.previousYearSum[0]["Paid Orders"]
                                                    , data.previous_custom_sum[0]["Paid Orders"]
                                                )
                                            )
                                        ),
                                        <div>
                                            <p className="mb-0">Previous Paid Orders</p>
                                            <p className="mb-0">
                                                {
                                                    <NumberFormatter
                                                        value={getPreviousValue(
                                                            data.previousSum[0]["Paid Orders"]
                                                            , data.previousYearSum[0]["Paid Orders"]
                                                            , data.previous_custom_sum[0]["Paid Orders"]
                                                        )}
                                                        displayDecimal={false}
                                                        replaceEmpty={true}
                                                    />
                                                }
                                            </p>
                                        </div>,
                                    ],
                                    [
                                        "RoAS",
                                        getRoasFormatter(data.sum[0]["Paid RoAS"]),
                                        getPercentColorFormatter(
                                            calculateChangePerc(
                                                data.sum[0]["Paid RoAS"],
                                                getPreviousValue(
                                                    data.previousSum[0]["Paid RoAS"]
                                                    , data.previousYearSum[0]["Paid RoAS"]
                                                    , data.previous_custom_sum[0]["Paid RoAS"]
                                                )
                                            )
                                        ),
                                        <div>
                                            <p className="mb-0">Previous RoAS</p>
                                            <p className="mb-0">{getRoasFormatter(
                                                getPreviousValue(
                                                    data.previousSum[0]["Paid RoAS"]
                                                    , data.previousYearSum[0]["Paid RoAS"]
                                                    , data.previous_custom_sum[0]["Paid RoAS"]
                                                )
                                            )}</p>
                                        </div>,
                                    ],
                                ]
                            }
                            widgetDataLoading={isMountedRef.current && loading}
                        />
                    </Col>
                </Row>

                <Row className="mt-4">
                    <Col lg={6} xl={3} className="mb-2">
                        <TextCard
                            headerTitle={"Sponsored Products"}
                            minHeight={"15em"}
                            minWidthPct={"70px"}
                            data={
                                data && [
                                    [
                                        "Sales",
                                        <CurrencyFormatter
                                            value={data.sum[0]["SP Sales"]}
                                            displayDecimal={false}
                                            replaceEmpty={true}
                                        />,
                                        getPercentColorFormatter(
                                            calculateChangePerc(data.sum[0]["SP Sales"], 
                                                getPreviousValue(
                                                    data.previousSum[0]["SP Sales"]
                                                    , data.previousYearSum[0]["SP Sales"]
                                                    , data.previous_custom_sum[0]["SP Sales"]
                                                )
                                            ),
                                            {display_decimal: false}
                                        ),
                                        <div>
                                            <p className="mb-0">Previous Sales</p>
                                            <p className="mb-0">
                                                {
                                                    <CurrencyFormatter
                                                        value={getPreviousValue(
                                                            data.previousSum[0]["SP Sales"]
                                                            , data.previousYearSum[0]["SP Sales"]
                                                            , data.previous_custom_sum[0]["SP Sales"]
                                                        )}
                                                        displayDecimal={false}
                                                        replaceEmpty={true}
                                                    />
                                                }
                                            </p>
                                        </div>,
                                    ],
                                    [
                                        "Spend",
                                        <CurrencyFormatter
                                            value={data.sum[0]["SP Spend"]}
                                            displayDecimal={false}
                                            replaceEmpty={true}
                                        />,
                                        getPercentColorFormatter(
                                            calculateChangePerc(data.sum[0]["SP Spend"], 
                                                getPreviousValue(
                                                    data.previousSum[0]["SP Spend"]
                                                    , data.previousYearSum[0]["SP Spend"]
                                                    , data.previous_custom_sum[0]["SP Spend"]
                                                )
                                            ),
                                            {display_decimal: false}
                                        ),
                                        <div>
                                            <p className="mb-0">Previous Spend</p>
                                            <p className="mb-0">
                                                {
                                                    <CurrencyFormatter
                                                        value={getPreviousValue(
                                                            data.previousSum[0]["SP Spend"]
                                                            , data.previousYearSum[0]["SP Spend"]
                                                            , data.previous_custom_sum[0]["SP Spend"]
                                                        )}
                                                        displayDecimal={false}
                                                        replaceEmpty={true}
                                                    />
                                                }
                                            </p>
                                        </div>,
                                    ],
                                    [
                                        "RoAS",
                                        getRoasFormatter(
                                            data.sum[0]["SP Spend"] > 0
                                                ? data.sum[0]["SP Sales"] / data.sum[0]["SP Spend"]
                                                : 0
                                        ),
                                        getPercentColorFormatter(
                                            calculateChangePerc(
                                                data.sum[0]["SP Spend"] > 0
                                                    ? data.sum[0]["SP Sales"] / data.sum[0]["SP Spend"]
                                                    : 0
                                                , (getPreviousValue(
                                                    data.previousSum[0]["SP Spend"] > 0
                                                        ? data.previousSum[0]["SP Sales"] / data.previousSum[0]["SP Spend"]
                                                        : 0
                                                    ,
                                                    data.previousYearSum[0]["SP Spend"] > 0
                                                        ? data.previousYearSum[0]["SP Sales"] / data.previousYearSum[0]["SP Spend"]
                                                        : 0
                                                    ,
                                                    data.previous_custom_sum[0]["SP Spend"] > 0
                                                        ? data.previous_custom_sum[0]["SP Sales"] / data.previous_custom_sum[0]["SP Spend"]
                                                        : 0
                                                ))
                                            ),
                                            {display_decimal: false}
                                        ),
                                        <div>
                                            <p className="mb-0">Previous RoAS</p>
                                            <p className="mb-0">
                                                {getRoasFormatter(
                                                    getPreviousValue(
                                                        data.previousSum[0]["SP Spend"] > 0
                                                            ? data.previousSum[0]["SP Sales"] / data.previousSum[0]["SP Spend"]
                                                            : 0
                                                        ,
                                                        data.previousYearSum[0]["SP Spend"] > 0
                                                            ? data.previousYearSum[0]["SP Sales"] / data.previousYearSum[0]["SP Spend"]
                                                            : 0
                                                        ,
                                                        data.previous_custom_sum[0]["SP Spend"] > 0
                                                            ? data.previous_custom_sum[0]["SP Sales"] / data.previous_custom_sum[0]["SP Spend"]
                                                            : 0
                                                    )
                                                )}
                                            </p>
                                        </div>,
                                    ],
                                    [
                                        "CPC",
                                        <CurrencyFormatter
                                            value={data.sum[0]["SP CPC"]}
                                            displayDecimal={true}
                                            replaceEmpty={true}
                                        />,
                                        getPercentColorFormatter(
                                            calculateChangePerc(data.sum[0]["SP CPC"], 
                                                getPreviousValue(
                                                    data.previousSum[0]["SP CPC"]
                                                    , data.previousYearSum[0]["SP CPC"]
                                                    , data.previous_custom_sum[0]["SP CPC"]
                                                )
                                            ),
                                            {display_decimal: false}
                                        ),
                                        <div>
                                            <p className="mb-0">Previous CPC</p>
                                            <p className="mb-0">
                                                {
                                                    <CurrencyFormatter
                                                        value={getPreviousValue(
                                                            data.previousSum[0]["SP CPC"]
                                                            , data.previousYearSum[0]["SP CPC"]
                                                            , data.previous_custom_sum[0]["SP CPC"]
                                                        )}
                                                        displayDecimal={true}
                                                        replaceEmpty={true}
                                                    />
                                                }
                                            </p>
                                        </div>,
                                    ],
                                ]
                            }
                            widgetDataLoading={isMountedRef.current && loading}
                        />
                    </Col>

                    <Col lg={6} xl={3} className="mb-2">
                        <TextCard
                            headerTitle={"Sponsored Brands"}
                            minHeight={"15em"}
                            minWidthPct={"70px"}
                            data={
                                data && [
                                    [
                                        "Sales",
                                        <CurrencyFormatter
                                            value={data.sum[0]["SB Sales"]}
                                            displayDecimal={false}
                                            replaceEmpty={true}
                                        />,
                                        getPercentColorFormatter(
                                            calculateChangePerc(data.sum[0]["SB Sales"], 
                                                getPreviousValue(
                                                    data.previousSum[0]["SB Sales"]
                                                    , data.previousYearSum[0]["SB Sales"]
                                                    , data.previous_custom_sum[0]["SB Sales"]
                                                )
                                            ),
                                            {display_decimal: false}
                                        ),
                                        <div>
                                            <p className="mb-0">Previous Sales</p>
                                            <p className="mb-0">
                                                {
                                                    <CurrencyFormatter
                                                        value={getPreviousValue(
                                                            data.previousSum[0]["SB Sales"]
                                                            , data.previousYearSum[0]["SB Sales"]
                                                            , data.previous_custom_sum[0]["SB Sales"]
                                                        )}
                                                        displayDecimal={false}
                                                        replaceEmpty={true}
                                                    />
                                                }
                                            </p>
                                        </div>,
                                    ],
                                    [
                                        "Spend",
                                        <CurrencyFormatter
                                            value={data.sum[0]["SB Spend"]}
                                            displayDecimal={false}
                                            replaceEmpty={true}
                                        />,
                                        getPercentColorFormatter(
                                            calculateChangePerc(data.sum[0]["SB Spend"], 
                                                getPreviousValue(
                                                    data.previousSum[0]["SB Spend"]
                                                    , data.previousYearSum[0]["SB Spend"]
                                                    , data.previous_custom_sum[0]["SB Spend"]
                                                )
                                            ),
                                            {display_decimal: false}
                                        ),
                                        <div>
                                            <p className="mb-0">Previous Spend</p>
                                            <p className="mb-0">
                                                {
                                                    <CurrencyFormatter
                                                        value={getPreviousValue(
                                                            data.previousSum[0]["SB Spend"]
                                                            , data.previousYearSum[0]["SB Spend"]
                                                            , data.previous_custom_sum[0]["SB Spend"]
                                                        )}
                                                        displayDecimal={false}
                                                        replaceEmpty={true}
                                                    />
                                                }
                                            </p>
                                        </div>,
                                    ],
                                    [
                                        "RoAS",
                                        getRoasFormatter(
                                            data.sum[0]["SB Spend"] > 0
                                                ? data.sum[0]["SB Sales"] / data.sum[0]["SB Spend"]
                                                : 0
                                        ),
                                        getPercentColorFormatter(
                                            calculateChangePerc(
                                                data.sum[0]["SB Spend"] > 0
                                                    ? data.sum[0]["SB Sales"] / data.sum[0]["SB Spend"]
                                                    : 0
                                                ,
                                                getPreviousValue(
                                                    data.previousSum[0]["SB Spend"] > 0
                                                        ? data.previousSum[0]["SB Sales"] / data.previousSum[0]["SB Spend"]
                                                        : 0
                                                    ,
                                                    data.previousYearSum[0]["SB Spend"] > 0
                                                        ? data.previousYearSum[0]["SB Sales"] / data.previousYearSum[0]["SB Spend"]
                                                        : 0
                                                    ,
                                                    data.previous_custom_sum[0]["SB Spend"] > 0
                                                        ? data.previous_custom_sum[0]["SB Sales"] / data.previous_custom_sum[0]["SB Spend"]
                                                        : 0
                                                )
                                            ),
                                            {display_decimal: false}
                                        ),
                                        <div>
                                            <p className="mb-0">Previous RoAS</p>
                                            <p className="mb-0">
                                                {getRoasFormatter(
                                                    getPreviousValue(
                                                        data.previousSum[0]["SB Spend"] > 0
                                                            ? data.previousSum[0]["SB Sales"] / data.previousSum[0]["SB Spend"]
                                                            : 0
                                                        ,
                                                        data.previousYearSum[0]["SB Spend"] > 0
                                                            ? data.previousYearSum[0]["SB Sales"] / data.previousYearSum[0]["SB Spend"]
                                                            : 0
                                                        ,
                                                        data.previous_custom_sum[0]["SB Spend"] > 0
                                                            ? data.previous_custom_sum[0]["SB Sales"] / data.previous_custom_sum[0]["SB Spend"]
                                                            : 0
                                                    )
                                                )}
                                            </p>
                                        </div>,
                                    ],
                                    [
                                        "CPC",
                                        <CurrencyFormatter
                                            value={data.sum[0]["SB CPC"]}
                                            displayDecimal={true}
                                            replaceEmpty={true}
                                        />,
                                        getPercentColorFormatter(
                                            calculateChangePerc(data.sum[0]["SB CPC"], 
                                                getPreviousValue(
                                                    data.previousSum[0]["SB CPC"]
                                                    , data.previousYearSum[0]["SB CPC"]
                                                    , data.previous_custom_sum[0]["SB CPC"]
                                                )
                                            ),
                                            {display_decimal: false}
                                        ),
                                        <div>
                                            <p className="mb-0">Previous CPC</p>
                                            <p className="mb-0">
                                                {
                                                    <CurrencyFormatter
                                                        value={getPreviousValue(
                                                            data.previousSum[0]["SB CPC"]
                                                            , data.previousYearSum[0]["SB CPC"]
                                                            , data.previous_custom_sum[0]["SB CPC"]
                                                        )}
                                                        displayDecimal={true}
                                                        replaceEmpty={true}
                                                    />
                                                }
                                            </p>
                                        </div>,
                                    ],
                                ]
                            }
                            widgetDataLoading={isMountedRef.current && loading}
                        />
                    </Col>
                    <Col lg={6} xl={3} className="mb-2">
                        <TextCard
                            headerTitle={"Sponsored Display"}
                            minHeight={"15em"}
                            minWidthPct={"70px"}
                            minWith={"260px"}
                            data={
                                data && [
                                    [
                                        "Sales",
                                        <CurrencyFormatter
                                            value={data.sum[0]["SD Sales"]}
                                            displayDecimal={false}
                                            replaceEmpty={true}
                                        />,
                                        getPercentColorFormatter(
                                            calculateChangePerc(data.sum[0]["SD Sales"], 
                                                getPreviousValue(
                                                    data.previousSum[0]["SD Sales"]
                                                    , data.previousYearSum[0]["SD Sales"]
                                                    , data.previous_custom_sum[0]["SD Sales"]
                                                )
                                            ),
                                            {display_decimal: false}
                                        ),
                                        <div>
                                            <p className="mb-0">Previous Sales</p>
                                            <p className="mb-0">
                                                {
                                                    <CurrencyFormatter
                                                        value={getPreviousValue(
                                                            data.previousSum[0]["SD Sales"]
                                                            , data.previousYearSum[0]["SD Sales"]
                                                            , data.previous_custom_sum[0]["SD Sales"]
                                                        )}
                                                        displayDecimal={true}
                                                        replaceEmpty={true}
                                                    />
                                                }
                                            </p>
                                        </div>,
                                    ],
                                    [
                                        "Spend",
                                        <CurrencyFormatter
                                            value={data.sum[0]["SD Spend"]}
                                            displayDecimal={false}
                                            replaceEmpty={true}
                                        />,
                                        getPercentColorFormatter(
                                            calculateChangePerc(data.sum[0]["SD Spend"], 
                                                getPreviousValue(
                                                    data.previousSum[0]["SD Spend"]
                                                    , data.previousYearSum[0]["SD Spend"]
                                                    , data.previous_custom_sum[0]["SD Spend"]
                                                )
                                            ),
                                            {display_decimal: false}
                                        ),
                                        <div>
                                            <p className="mb-0">Previous Spend</p>
                                            <p className="mb-0">
                                                {
                                                    <CurrencyFormatter
                                                        value={getPreviousValue(
                                                            data.previousSum[0]["SD Spend"]
                                                            , data.previousYearSum[0]["SD Spend"]
                                                            , data.previous_custom_sum[0]["SD Spend"]
                                                        )}
                                                        displayDecimal={true}
                                                        replaceEmpty={true}
                                                    />
                                                }
                                            </p>
                                        </div>,
                                    ],
                                    [
                                        "RoAS",
                                        getRoasFormatter(
                                            data.sum[0]["SD Spend"] > 0
                                                ? data.sum[0]["SD Sales"] / data.sum[0]["SD Spend"]
                                                : 0
                                        ),
                                        getPercentColorFormatter(
                                            calculateChangePerc(
                                                data.sum[0]["SD Spend"] > 0
                                                    ? data.sum[0]["SD Sales"] / data.sum[0]["SD Spend"]
                                                    : 0
                                                ,
                                                getPreviousValue(
                                                    data.previousSum[0]["SD Spend"] > 0
                                                        ? data.previousSum[0]["SD Sales"] / data.previousSum[0]["SD Spend"]
                                                        : 0
                                                    , 
                                                    data.previousYearSum[0]["SD Spend"] > 0
                                                        ? data.previousYearSum[0]["SD Sales"] / data.previousYearSum[0]["SD Spend"]
                                                        : 0
                                                    ,
                                                    data.previous_custom_sum[0]["SD Spend"] > 0
                                                        ? data.previous_custom_sum[0]["SD Sales"] / data.previous_custom_sum[0]["SD Spend"]
                                                        : 0
                                                )
                                            ),
                                            {display_decimal: false}
                                        ),
                                        <div>
                                            <p className="mb-0">Previous RoAS</p>
                                            <p className="mb-0">
                                                {getRoasFormatter(
                                                    getPreviousValue(
                                                        data.previousSum[0]["SD Spend"] > 0
                                                            ? data.previousSum[0]["SD Sales"] / data.previousSum[0]["SD Spend"]
                                                            : 0
                                                        , 
                                                        data.previousYearSum[0]["SD Spend"] > 0
                                                            ? data.previousYearSum[0]["SD Sales"] / data.previousYearSum[0]["SD Spend"]
                                                            : 0
                                                        ,
                                                        data.previous_custom_sum[0]["SD Spend"] > 0
                                                            ? data.previous_custom_sum[0]["SD Sales"] / data.previous_custom_sum[0]["SD Spend"]
                                                            : 0
                                                    )
                                                )}
                                            </p>
                                        </div>,
                                    ],
                                    [
                                        "CPC",
                                        <CurrencyFormatter
                                            value={data.sum[0]["SD CPC"]}
                                            displayDecimal={true}
                                            replaceEmpty={true}
                                        />,
                                        getPercentColorFormatter(
                                            calculateChangePerc(data.sum[0]["SD CPC"], 
                                                getPreviousValue(
                                                    data.previousSum[0]["SD CPC"]
                                                    , data.previousYearSum[0]["SD CPC"]
                                                    , data.previous_custom_sum[0]["SD CPC"]
                                                )
                                            ),
                                            {display_decimal: false}
                                        ),
                                        <div>
                                            <p className="mb-0">Previous CPC</p>
                                            <p className="mb-0">
                                                {
                                                    <CurrencyFormatter
                                                        value={getPreviousValue(
                                                            data.previousSum[0]["SD CPC"]
                                                            , data.previousYearSum[0]["SD CPC"]
                                                            , data.previous_custom_sum[0]["SD CPC"]
                                                        )}
                                                        displayDecimal={true}
                                                        replaceEmpty={true}
                                                    />
                                                }
                                            </p>
                                        </div>,
                                    ],
                                ]
                            }
                            widgetDataLoading={isMountedRef.current && loading}
                        />
                    </Col>
                    <Col lg={6} xl={3} className="mb-2">
                        <TextCard
                            headerTitle={"DSP"}
                            minHeight={"15em"}
                            minWidthPct={"70px"}
                            data={
                                data && [
                                    [
                                        "Sales",
                                        <CurrencyFormatter
                                            value={data.sum[0]["DSP Sales"]}
                                            displayDecimal={false}
                                            replaceEmpty={true}
                                        />,
                                        getPercentColorFormatter(
                                            calculateChangePerc(data.sum[0]["DSP Sales"], 
                                                getPreviousValue(
                                                    data.previousSum[0]["DSP Sales"]
                                                    , data.previousYearSum[0]["DSP Sales"]
                                                    , data.previous_custom_sum[0]["DSP Sales"]
                                                )
                                            ),
                                            {display_decimal: false}
                                        ),
                                        <div>
                                            <p className="mb-0">Previous Sales</p>
                                            <p className="mb-0">
                                                {
                                                    <CurrencyFormatter
                                                        value={getPreviousValue(
                                                            data.previousSum[0]["DSP Sales"]
                                                            , data.previousYearSum[0]["DSP Sales"]
                                                            , data.previous_custom_sum[0]["DSP Sales"]
                                                        )}
                                                        displayDecimal={true}
                                                        replaceEmpty={true}
                                                    />
                                                }
                                            </p>
                                        </div>,
                                    ],
                                    [
                                        "Spend",
                                        <CurrencyFormatter
                                            value={data.sum[0]["DSP Spend"]}
                                            displayDecimal={false}
                                            replaceEmpty={true}
                                        />,
                                        getPercentColorFormatter(
                                            calculateChangePerc(data.sum[0]["DSP Spend"], 
                                                getPreviousValue(
                                                    data.previousSum[0]["DSP Spend"]
                                                    , data.previousYearSum[0]["DSP Spend"]
                                                    , data.previous_custom_sum[0]["DSP Spend"]
                                                )
                                            ),
                                            {display_decimal: false}
                                        ),
                                        <div>
                                            <p className="mb-0">Previous Spend</p>
                                            <p className="mb-0">
                                                {
                                                    <CurrencyFormatter
                                                        value={getPreviousValue(
                                                            data.previousSum[0]["DSP Spend"]
                                                            , data.previousYearSum[0]["DSP Spend"]
                                                            , data.previous_custom_sum[0]["DSP Spend"]
                                                        )}
                                                        displayDecimal={true}
                                                        replaceEmpty={true}
                                                    />
                                                }
                                            </p>
                                        </div>,
                                    ],
                                    [
                                        "RoAS",
                                        getRoasFormatter(
                                            data.sum[0]["DSP Spend"] > 0
                                                ? data.sum[0]["DSP Sales"] / data.sum[0]["DSP Spend"]
                                                : 0
                                        ),
                                        getPercentColorFormatter(
                                            calculateChangePerc(
                                                data.sum[0]["DSP Spend"] > 0
                                                    ? data.sum[0]["DSP Sales"] / data.sum[0]["DSP Spend"]
                                                    : 0
                                                ,
                                                getPreviousValue(
                                                    data.previousSum[0]["DSP Spend"] > 0
                                                        ? data.previousSum[0]["DSP Sales"] / data.previousSum[0]["DSP Spend"]
                                                        : 0
                                                    , 
                                                    data.previousYearSum[0]["DSP Spend"] > 0
                                                        ? data.previousYearSum[0]["DSP Sales"] / data.previousYearSum[0]["DSP Spend"]
                                                        : 0
                                                    ,
                                                    data.previous_custom_sum[0]["DSP Spend"] > 0
                                                        ? data.previous_custom_sum[0]["DSP Sales"] / data.previous_custom_sum[0]["DSP Spend"]
                                                        : 0
                                                )
                                            ),
                                            {display_decimal: false}
                                        ),
                                        <div>
                                            <p className="mb-0">Previous RoAS</p>
                                            <p className="mb-0">
                                                {getRoasFormatter(
                                                    getPreviousValue(
                                                        data.previousSum[0]["DSP Spend"] > 0
                                                            ? data.previousSum[0]["DSP Sales"] / data.previousSum[0]["DSP Spend"]
                                                            : 0
                                                        , 
                                                        data.previousYearSum[0]["DSP Spend"] > 0
                                                            ? data.previousYearSum[0]["DSP Sales"] / data.previousYearSum[0]["DSP Spend"]
                                                            : 0
                                                        ,
                                                        data.previous_custom_sum[0]["DSP Spend"] > 0
                                                            ? data.previous_custom_sum[0]["DSP Sales"] / data.previous_custom_sum[0]["DSP Spend"]
                                                            : 0
                                                    )
                                                )}
                                            </p>
                                        </div>,
                                    ],
                                    [
                                        "CPC",
                                        <CurrencyFormatter
                                            value={(data.sum[0]["DSP Clicks"] > 0 ? (data.sum[0]["DSP Spend"] ?? 0) / (data.sum[0]["DSP Clicks"] ?? 0) : 0)}
                                            displayDecimal={true}
                                            replaceEmpty={true}
                                        />,
                                        getPercentColorFormatter(
                                            calculateChangePerc((data.sum[0]["DSP Clicks"] > 0 ? (data.sum[0]["DSP Spend"] ?? 0) / (data.sum[0]["DSP Clicks"] ?? 0) : 0), 
                                                getPreviousValue(
                                                    (data.previousSum[0]["DSP Clicks"] > 0 ? (data.previousSum[0]["DSP Spend"] ?? 0) / (data.previousSum[0]["DSP Clicks"] ?? 0) : 0)
                                                    , (data.previousYearSum[0]["DSP Clicks"] > 0 ? (data.previousYearSum[0]["DSP Spend"] ?? 0) / (data.previousYearSum[0]["DSP Clicks"] ?? 0) : 0)
                                                    , (data.previous_custom_sum[0]["DSP Clicks"] > 0 ? (data.previous_custom_sum[0]["DSP Spend"] ?? 0) / (data.previous_custom_sum[0]["DSP Clicks"] ?? 0) : 0)
                                                )
                                            ),
                                            {display_decimal: false}
                                        ),
                                        <div>
                                            <p className="mb-0">Previous CPC</p>
                                            <p className="mb-0">
                                                {
                                                    <CurrencyFormatter
                                                        value={getPreviousValue(
                                                            (data.previousSum[0]["DSP Clicks"] > 0 ? (data.previousSum[0]["DSP Spend"] ?? 0) / (data.previousSum[0]["DSP Clicks"] ?? 0) : 0)
                                                            , (data.previousYearSum[0]["DSP Clicks"] > 0 ? (data.previousYearSum[0]["DSP Spend"] ?? 0) / (data.previousYearSum[0]["DSP Clicks"] ?? 0) : 0)
                                                            , (data.previous_custom_sum[0]["DSP Clicks"] > 0 ? (data.previous_custom_sum[0]["DSP Spend"] ?? 0) / (data.previous_custom_sum[0]["DSP Clicks"] ?? 0) : 0)
                                                        )}
                                                        displayDecimal={true}
                                                        replaceEmpty={true}
                                                    />
                                                }
                                            </p>
                                        </div>,
                                    ],
                                ]
                            }
                            widgetDataLoading={isMountedRef.current && loading}
                        />
                    </Col>
                </Row>
                
                <Row className="mt-4">
                    <Col>
                        {data.tableData && (
                            <BootstrapTablesWrapper
                                data={data.tableData}
                                keyField="Type"
                                description={
                                    <ChartDescription
                                        title={"PPC Overview"}
                                    />
                                }
                                hasPagination={false}
                                hasFilter={false}
                                columns={columns}
                            />
                        )}
                    </Col>
                </Row>
            </Col>
        </main>
    );
};

export default Overview;
