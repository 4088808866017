import React, { useContext, useEffect, useState } from 'react';
import { Card, Col, Row, Tab, Tabs } from 'react-bootstrap';
import moment from 'moment';

import { AuthContext } from '../../contexts/AuthContext';
import { BrandContext } from '../../contexts/BrandContext';
import { HeaderContext } from '../../contexts/HeaderContext';

import BootstrapTablesWrapper from '../../components/dataRender/BootstrapTablesWrapper';
import WidgetSpinner from '../../components/helper/WidgetSpinner';
import ProductImageThumbnail from '../../components/helper/productImageThumbnail';
import { VpartFilterCard } from '../../components/helper/VpartFilter.js';

import { brandMetrics, vPartMetrics } from '../../api/brandData';

import QuantitativeMetricCards, { defaultQuantitativeMetricCards } from './QuantitativeMetricCards';
import QuantitativeApexChart from './QuantitativeApexChart';
import QuantitativePivotTable from './QuantitativePivotTable';
import { getQuantitativeDataTableCols } from './metricsVars';

const defaultSortSales = [
  {
    dataField: "Total Sales",
    order: "desc",
  },
];

const defaultSortDate = [
  {
    dataField: "date",
    order: "desc",
  },
];

const QuantitativeReport = (props) => {
  // console.log('QuantitativeReport', props);

  const { token } = useContext(AuthContext);
  const { brand } = useContext(BrandContext);
  const {
    pageTitle,
    setPageTitle,
    setPageTimeframe,
    setPageDisplayTimeFrameInterval,
    setPageComparativeSelector,
    selectedDefaultDates,
    currency,
    marketplace,
    generateDownloadFilename,
  } = useContext(HeaderContext);

  useEffect(() => {
    setPageTitle('Quantitative Report');
    setPageTimeframe('timeframe');
    setPageDisplayTimeFrameInterval(true);
    setPageComparativeSelector(false);
    return () => {
      setPageTitle(process.env.REACT_APP_TITLE);
    };
  }, [pageTitle, setPageComparativeSelector, setPageDisplayTimeFrameInterval, setPageTimeframe, setPageTitle]);

  const [dataLoading, setDataLoading] = useState(true);
  const [dataVpartsLoading, setDataVpartsLoading] = useState(true);

  // Metrics Chart Filter Card
  const [selectedMetricsCards, setSelectedMetricsCards] = useState({
    metrics: defaultQuantitativeMetricCards,
    dataLabels: ['Total Sales'],
  });
  const [random, setRandom] = useState(Math.random());
  const reRenderChart = () => setRandom(Math.random());

  // Card Data
  const [chartData, setChartData] = useState();
  const [chartLabels, setChartLabels] = useState();

  // Tabs
  const [activeTab, setActiveTab] = useState('brand');

  // Brand Data
  const [brandDataColumns, setBrandDataColumns] = useState([]);
  const [brandData, setBrandData] = useState();
  const [brandAggregatedData, setBrandAggregatedData] = useState();
  const [brandAggregatedTableData, setBrandAggregatedTableData] = useState();

  // vPart Data
  const [selectedVparts, setSelectedVparts] = useState([]);
  const [vpartData, setVpartData] = useState();
  const [vpartDataColumns, setVpartDataColumns] = useState([]);
  const [vpartPivotData, setVpartPivotData] = useState();

  const updateSelectedVparts = (value) => {
    // console.log('updateSelectedVparts', value);
    setSelectedVparts(value);
  };

  useEffect(() => {
    const getData = async () => {
      setDataLoading(true);
      try {
        const res = await brandMetrics(token, {
          brandid: brand.brandId,
          tf_s: moment(selectedDefaultDates.tf_s).format('YYYY-MM-DD'),
          tf_e: moment(selectedDefaultDates.tf_e).format('YYYY-MM-DD'),
          wow: selectedDefaultDates.timeframe,
          currency: currency,
          marketplace: marketplace,
        });
        const newCols = getQuantitativeDataTableCols(res.data?.metrics);

        // setData(res.data);
        setBrandDataColumns(newCols);
        setBrandData(res.data?.data);
        setChartLabels(res.data?.labels);
        setBrandAggregatedData(res.data?.brand_aggregate);
        setBrandAggregatedTableData([res.data?.brand_aggregate]); // Array of 1
        setChartData(res.data?.chartData);

        setDataLoading(false);
        // console.log("QuantitativeReport Brand Data", res.data, newCols);
      } catch (err) {
        setDataLoading(false);
      }
    };

    const getVpartData = async () => {
      setDataVpartsLoading(true);
      try {
        const searchVparts = selectedVparts?.map((v) => {
          return v.vpart;
        });

        const res = await vPartMetrics(token, {
          brandid: brand.brandId,
          tf_s: moment(selectedDefaultDates.tf_s).format('YYYY-MM-DD'),
          tf_e: moment(selectedDefaultDates.tf_e).format('YYYY-MM-DD'),
          wow: selectedDefaultDates.timeframe,
          vparts: searchVparts,
          currency: currency,
          marketplace: marketplace,
        });

        const newCols = getQuantitativeDataTableCols(res.data.metrics);
        
        setVpartData(res.data);
        setVpartDataColumns(newCols);

        // If vpart selected updated brand data too
        if (searchVparts !== undefined && searchVparts.length > 0) {
          setChartData(res.data?.chartData);
          setChartLabels(res.data?.labels);

          setBrandDataColumns(newCols);
          setBrandData(res.data?.brand_data);
          setBrandAggregatedData(res.data?.brand_aggregate);
          setBrandAggregatedTableData([res.data?.brand_aggregate]); // Array of 1
        }

        setVpartPivotData({
          prePivotData: res.data?.data,
          pivotValues: res.data?.dates,
          pivotColumns: undefined, // Use default. All = (res.data?.metrics)
        });

        // console.log("QuantitativeReport vPart Data", res.data, newCols);
        setDataVpartsLoading(false);
      } catch (err) {
        console.log('vpartdata', err);
        setDataVpartsLoading(false);
      }
    };

    if (brand.brandId !== undefined && selectedDefaultDates.timeframe !== undefined) {
      const searchVparts = selectedVparts?.map((v) => {
        return v.vpart;
      });

      // Reset
      setChartData();
      setChartLabels();

      setBrandData();
      setBrandAggregatedData();
      setBrandAggregatedTableData();

      setVpartData();
      setVpartPivotData();

      // Get, if vpart selected only need vpart, otherwise get both
      if (searchVparts !== undefined && searchVparts.length > 0) {
        getVpartData();
      } else {
        getData();
        getVpartData();
      }
    } else {
      setDataLoading(true);
    }
  }, [token, brand, selectedDefaultDates, selectedVparts, currency, marketplace]);

  return (
    <main>
      <Col className="mt-5">
        <VpartFilterCard onSelectSubmit={(value) => updateSelectedVparts(value)}></VpartFilterCard>

        <Row className="mt-4">
          <Col>
            <QuantitativeMetricCards
              dataLoading={dataLoading}
              brandAggregatedData={brandAggregatedData}
              selectedMetrics={selectedMetricsCards.metrics}
              enabledDataLabels={selectedMetricsCards.dataLabels}
              onChange={(value) => {
                setSelectedMetricsCards(value);
                reRenderChart();
              }}
            />
          </Col>
        </Row>

        <Row className="mt-4">
          <Col sm={12}>
            <Card>
              <Card.Body>
                <QuantitativeApexChart
                  key={random}
                  data={chartData}
                  labels={chartLabels}
                  selectedMetrics={selectedMetricsCards.metrics}
                  enabledDataLabels={selectedMetricsCards.dataLabels}
                  height={500}
                />
              </Card.Body>
            </Card>
          </Col>
        </Row>

        <Row className="mt-4">
          <Col sm={12}>
            <Tabs activeKey={activeTab} onSelect={(k) => setActiveTab(k)}>
              <Tab eventKey={'brand'} title={'Brand'}>
                <div>
                  {dataLoading && !brandData ? (
                    <WidgetSpinner />
                  ) : (
                    <BootstrapTablesWrapper
                      data={brandData ?? []}
                      keyField="date"
                      hasPagination={true}
                      hasFilter={true}
                      headerNowrap={true}
                      rowNowrap={false}
                      exportCSV={{
                        fileName: generateDownloadFilename({ extension: 'csv', suffix: 'brand' }),
                      }}
                      defaultSorted={defaultSortDate}
                      columns={[
                        {
                          dataField: 'date',
                          text: 'Date',
                          sort: true,
                          type: 'date',
                          filter: 'textFilter',
                          headerStyle: { whiteSpace: 'normal', minWidth: '150px' },
                        },
                        ...brandDataColumns,
                      ]}
                    />
                  )}
                </div>
              </Tab>
              <Tab eventKey={'brand_aggregated'} title={'Brand Aggregate'}>
                <div>
                  {dataLoading && !brandAggregatedTableData ? (
                    <WidgetSpinner />
                  ) : (
                    <BootstrapTablesWrapper
                      data={brandAggregatedTableData ?? []}
                      keyField="index"
                      hasPagination={true}
                      hasFilter={true}
                      headerNowrap={true}
                      rowNowrap={false}
                      exportCSV={{
                        fileName: generateDownloadFilename({
                          extension: 'csv',
                          suffix: 'brand_aggregated',
                          useTimeFrameInterval: false,
                        }),
                      }}
                      defaultSorted={defaultSortSales}
                      columns={[
                        {
                          dataField: 'index',
                          text: '',
                          sort: false,
                          hidden: true,
                          type: 'number',
                          // filter: 'textFilter',
                        },
                        ...brandDataColumns,
                      ]}
                    />
                  )}
                </div>
              </Tab>
              <Tab eventKey={'vpart'} title={'vParts'}>
                <div>
                  {dataVpartsLoading && !vpartData?.data ? (
                    <WidgetSpinner />
                  ) : (
                    <BootstrapTablesWrapper
                      data={vpartData?.data ?? []}
                      keyField="id"
                      hasPagination={true}
                      hasFilter={true}
                      headerNowrap={true}
                      rowNowrap={false}
                      exportCSV={{
                        fileName: generateDownloadFilename({ extension: 'csv', suffix: 'vparts' }),
                      }}
                      defaultSorted={[
                        ...defaultSortDate,
                        ...defaultSortSales,
                      ]}
                      columns={[
                        {
                          // Combine Vpart + Short Title + Image
                          dataField: 'vpart',
                          text: 'vPart',
                          sort: true,
                          type: 'string',
                          filter: 'textFilter',
                          filterValue: (cell, row) => `${row['short_title']} ${row['vpart_alt']} ${row['vpart']} ${row['asin']}`,
                          headerStyle: (colum, colIndex) => {
                            return { minWidth: '350px' };
                          },
                          formatter: (cell, row, rowIndex) => {
                            return (
                              <div className="d-flex flex-row flex-nowrap align-items-center">
                                <ProductImageThumbnail
                                  imageSrc={row['image']}
                                  imageLink={row['asin'].length > 0 ? row['asin'][0]['asinLink'] : ''}
                                  imageAlt={
                                    row['vpart_alt'] !== null && row['vpart_alt'] !== undefined && row['vpart_alt'] !== ''
                                      ? row['vpart_alt']
                                      : row['vpart']
                                  }
                                />
                                <div className="d-flex flex-column w-100 ml-2">
                                  <p className="mb-0">{row['short_title']}</p>
                                  <div className="d-flex flex-row justify-content-between text-sm">
                                    <div className="mr-2">
                                      <p className="mb-0">
                                        {row['vpart_alt'] !== null &&
                                        row['vpart_alt'] !== undefined &&
                                        row['vpart_alt'] !== ''
                                          ? row['vpart_alt']
                                          : row['vpart']}
                                      </p>
                                    </div>
                                    <div>
                                      {row['asin'] !== undefined && row['asin'].length > 0
                                        ? row['asin'].map((value) => {
                                            return (
                                              <span className="mr-2" key={value['asin']}>
                                                <a href={value['asinLink']} target={`_${value['asin']}`}>
                                                  {value['asin']}
                                                </a>
                                              </span>
                                            );
                                          })
                                        : ''}
                                    </div>
                                    {/* <p>{row['sku'] !== undefined ? row['sku']?.split(',').join(', ') : row['sku']}</p> */}
                                  </div>
                                </div>
                              </div>
                            );
                          },
                          csvFormatter: (cell, row, rowIndex) => {
                            return (
                              '="' +
                              (row['vpart_alt'] !== null && row['vpart_alt'] !== undefined && row['vpart_alt'] !== ''
                                ? row['vpart_alt']
                                : row['vpart']) +
                              '"'
                            );
                          },
                        },
                        {
                          dataField: 'upc',
                          text: 'UPC',
                          sort: true,
                          hidden: true,
                          type: 'string',
                          filter: 'textFilter',
                          csvFormatter: (cell, row, rowIndex) => {
                            return `="${row['upc']}"`;
                          },
                        },
                        {
                          dataField: 'asin',
                          text: 'ASIN',
                          type: 'string',
                          sort: true,
                          hidden: true,
                          filter: 'textFilter',
                          sortValue: (cell, row) => cell['asin'] ?? '',
                          filterValue: (cell, row, rowIndex) => {
                            return cell?.map((obj) => obj?.asin).join(', ');
                          },
                          formatter: (cell, row, rowIndex) => {
                            return (
                              <div>
                                {cell !== undefined && cell.length > 0
                                  ? cell.map((value) => {
                                      return (
                                        <span className="mr-2" key={value['id']}>
                                          <a href={value['asinLink']} target={`_${value['asin']}`}>
                                            {value['asin']}
                                          </a>
                                        </span>
                                      );
                                    })
                                  : ''}
                              </div>
                            );
                          },
                          csvFormatter: (cell, row, rowIndex) => {
                            return cell?.map((obj) => obj?.asin).join(', ');
                          },
                        },
                        {
                          dataField: 'short_title',
                          text: 'Short Title',
                          sort: true,
                          hidden: true,
                          type: 'string',
                          filter: 'textFilter',
                          headerStyle: { whiteSpace: 'normal', minWidth: '150px' },
                        },
                        {
                          dataField: 'date',
                          text: 'Date',
                          sort: true,
                          type: 'date',
                          filter: 'textFilter',
                          headerStyle: { whiteSpace: 'normal', minWidth: '150px' },
                        },
                        ...vpartDataColumns,
                      ]}
                    />
                  )}
                </div>
              </Tab>
              <Tab eventKey={'vpartaggregate'} title={'vParts Aggregate'}>
                <div>
                  {dataVpartsLoading && !vpartData?.vpart_aggregate ? (
                    <WidgetSpinner />
                  ) : (
                    <BootstrapTablesWrapper
                      data={vpartData?.vpart_aggregate ?? []}
                      keyField="vpart"
                      hasPagination={true}
                      hasFilter={true}
                      headerNowrap={true}
                      rowNowrap={false}
                      exportCSV={{
                        fileName: generateDownloadFilename({
                          extension: 'csv',
                          suffix: 'vparts_aggregated',
                          useTimeFrameInterval: false,
                        }),
                      }}
                      defaultSorted={defaultSortSales}
                      columns={[
                        {
                          // Combine Vpart + Short Title + Image
                          dataField: 'vpart',
                          text: 'vPart',
                          sort: true,
                          type: 'string',
                          filter: 'textFilter',
                          filterValue: (cell, row) => `${row['short_title']} ${row['vpart_alt']} ${row['vpart']} ${row['asin']}`,
                          headerStyle: (colum, colIndex) => {
                            return { minWidth: '350px' };
                          },
                          formatter: (cell, row, rowIndex) => {
                            return (
                              <div className="d-flex flex-row flex-nowrap align-items-center">
                                <ProductImageThumbnail
                                  imageSrc={row['image']}
                                  imageLink={row['asin'].length > 0 ? row['asin'][0]['asinLink'] : ''}
                                  imageAlt={
                                    row['vpart_alt'] !== null && row['vpart_alt'] !== undefined && row['vpart_alt'] !== ''
                                      ? row['vpart_alt']
                                      : row['vpart']
                                  }
                                />
                                <div className="d-flex flex-column w-100 ml-2">
                                  <p className="mb-0">{row['short_title']}</p>
                                  <div className="d-flex flex-row justify-content-between text-sm">
                                    <div className="mr-2">
                                      <p className="mb-0">
                                        {row['vpart_alt'] !== null &&
                                        row['vpart_alt'] !== undefined &&
                                        row['vpart_alt'] !== ''
                                          ? row['vpart_alt']
                                          : row['vpart']}
                                      </p>
                                    </div>
                                    <div>
                                      {row['asin'] !== undefined && row['asin'].length > 0
                                        ? row['asin'].map((value) => {
                                            return (
                                              <span className="mr-2" key={value['asin']}>
                                                <a href={value['asinLink']} target={`_${value['asin']}`}>
                                                  {value['asin']}
                                                </a>
                                              </span>
                                            );
                                          })
                                        : ''}
                                    </div>
                                    {/* <p>{row['sku'] !== undefined ? row['sku']?.split(',').join(', ') : row['sku']}</p> */}
                                  </div>
                                </div>
                              </div>
                            );
                          },
                          csvFormatter: (cell, row, rowIndex) => {
                            return (
                              '="' +
                              (row['vpart_alt'] !== null && row['vpart_alt'] !== undefined && row['vpart_alt'] !== ''
                                ? row['vpart_alt']
                                : row['vpart']) +
                              '"'
                            );
                          },
                        },
                        {
                          dataField: 'upc',
                          text: 'UPC',
                          sort: true,
                          hidden: true,
                          type: 'string',
                          filter: 'textFilter',
                          csvFormatter: (cell, row, rowIndex) => {
                            return `="${row['upc']}"`;
                          },
                        },
                        {
                          dataField: 'asin',
                          text: 'ASIN',
                          type: 'string',
                          sort: true,
                          hidden: true,
                          filter: 'textFilter',
                          sortValue: (cell, row) => cell['asin'] ?? '',
                          filterValue: (cell, row, rowIndex) => {
                            return cell?.map((obj) => obj?.asin).join(', ');
                          },
                          formatter: (cell, row, rowIndex) => {
                            return (
                              <div>
                                {cell !== undefined && cell.length > 0
                                  ? cell.map((value) => {
                                      return (
                                        <span className="mr-2" key={value['id']}>
                                          <a href={value['asinLink']} target={`_${value['asin']}`}>
                                            {value['asin']}
                                          </a>
                                        </span>
                                      );
                                    })
                                  : ''}
                              </div>
                            );
                          },
                          csvFormatter: (cell, row, rowIndex) => {
                            return cell?.map((obj) => obj?.asin).join(', ');
                          },
                        },
                        {
                          dataField: 'short_title',
                          text: 'Short Title',
                          sort: true,
                          hidden: true,
                          type: 'string',
                          filter: 'textFilter',
                          headerStyle: { whiteSpace: 'normal', minWidth: '150px' },
                        },
                        ...vpartDataColumns,
                      ]}
                    />
                  )}
                </div>
              </Tab>
              <Tab eventKey={'vpartspivot'} title={'vParts Pivot'}>
                <QuantitativePivotTable
                  loading={dataVpartsLoading}
                  prePivotData={vpartPivotData?.prePivotData}
                  pivotValues={vpartPivotData?.pivotValues}
                  pivotColumns={vpartPivotData?.pivotColumns}
                />
              </Tab>
            </Tabs>
          </Col>
        </Row>
      </Col>
    </main>
  );
};

export default QuantitativeReport;
