const currency_metric_options = {
    'type': 'number',
    'formatter': 'dollarFormatter',
    'axis': 'currency',
    'line': 'solid',
    'color': 'secondary',
}

const intl_currency_metric_options = {
    'type': 'number',
    'formatter': 'intlCurrencyFormatter',
    'axis': 'currency',
    'line': 'solid',
    'color': 'secondary',
}

const int_metric_options = {
    'type': 'number',
    'formatter': 'intFormatter',
    'axis': 'number',
    'line': 'dash',
    'color': 'tertiary',
}

const ratio_metric_options = {
    'type': 'number',
    'formatter': 'ratioFormatter',
    'axis': 'ratio',
    'line': 'solid',
    'color': 'quaternary',
}

const percent_metric_options = {
    'type': 'number',
    'formatter': 'percentFormatter',
    'axis': 'percent',
    'line': 'dash',
    'color': 'primary',
}

export const metric_property = {
    /* Global */
    'Total Sales' : {
        'label': 'Total Sales',
        'category': 'Global',
        ...currency_metric_options
    },
    'Total Units' : {
        'label': 'Total Units',
        'category': 'Global',
        ...int_metric_options
    },
    'Total Orders' : {
        'label': 'Total Orders',
        'category': 'Global',
        ...int_metric_options
    },
    'Total RoAS' : {
        'label': 'Total RoAS',
        'category': 'Global',
        ...ratio_metric_options
    },
    'Total ACoS' : {
        'label': 'Total ACoS',
        'category': 'Global',
        ...percent_metric_options
    },
    'Total Page Views' : {
        'label': 'Total Page Views',
        'category': 'Global',
        ...int_metric_options
    },
    'Total Sessions' : {
        'label': 'Total Sessions',
        'category': 'Global',
        ...int_metric_options
    },
    'Total CNV (Units)' : {
        'label': 'Total CNV (Units)',
        'category': 'Global',
        ...percent_metric_options
    },
    'Total CNV (Orders)' : {
        'label': 'Total CNV (Orders)',
        'category': 'Global',
        ...percent_metric_options
    },
    'Buybox Percentage' : {
        'label': 'Buybox Percentage',
        'category': 'Global',
        ...percent_metric_options
    },

    /* SNS */
    'SNS Sales' : {
        'label': 'SNS Sales',
        'category': 'SNS',
        ...currency_metric_options
    },
    'SNS Units' : {
        'label': 'SNS Units',
        'category': 'SNS',
        ...int_metric_options
    },
    'SNS Orders' : {
        'label': 'SNS Orders',
        'category': 'SNS',
        ...int_metric_options
    },

    /* Organic */
    'Organic Sales' : {
        'label': 'Organic Sales',
        'category': 'Organic',
        ...currency_metric_options
    },
    'Organic Units' : {
        'label': 'Organic Units',
        'category': 'Organic',
        ...int_metric_options
    },
    'Organic Orders' : {
        'label': 'Organic Orders',
        'category': 'Organic',
        ...int_metric_options
    },
    'Organic Sessions' : {
        'label': 'Organic Sessions',
        'category': 'Organic',
        ...int_metric_options
    },
    'Organic CNV' : {
        'label': 'Organic CNV',
        'category': 'Organic',
        ...percent_metric_options
    },

    /* PPC */
    'Paid Sales' : {
        'label': 'Paid Sales',
        'category': 'Paid',
        ...intl_currency_metric_options
    },
    'Paid Spend' : {
        'label': 'Paid Spend',
        'category': 'Paid',
        ...intl_currency_metric_options
    },
    'Paid Units' : {
        'label': 'Paid Units',
        'category': 'Paid',
        ...int_metric_options
    },
    'Paid Orders' : {
        'label': 'Paid Orders',
        'category': 'Paid',
        ...int_metric_options
    },
    'Paid CNV (Units)' : {
        'label': 'Paid CNV (Units)',
        'category': 'Paid',
        ...percent_metric_options
    },
    'Paid CNV (Orders)' : {
        'label': 'Paid CNV (Orders)',
        'category': 'Paid',
        ...percent_metric_options
    },
    'Paid RoAS' : {
        'label': 'Paid RoAS',
        'category': 'Paid',
        ...ratio_metric_options
    },
    'Paid ACoS' : {
        'label': 'Paid ACoS',
        'category': 'Paid',
        ...percent_metric_options
    },
    'Paid Clicks' : {
        'label': 'Paid Clicks',
        'category': 'Paid',
        ...int_metric_options
    },
    'Paid Impressions' : {
        'label': 'Paid Impressions',
        'category': 'Paid',
        ...int_metric_options
    },
    'Paid CTR' : {
        'label': 'Paid CTR',
        'category': 'Paid',
        ...percent_metric_options
    },
    'Paid CPC' : {
        'label': 'Paid CPC',
        'category': 'Paid',
        ...intl_currency_metric_options
    },
    'Paid CPA' : {
        'label': 'Paid CPA',
        'category': 'Paid',
        ...intl_currency_metric_options
    },
    'Paid CPM' : {
        'label': 'Paid CPM',
        'category': 'Paid',
        ...intl_currency_metric_options
    },
    'SPSB Sales' : {
        'label': 'SPSB Sales',
        'category': 'SPSB',
        ...intl_currency_metric_options
    },
    'SPSB Spend' : {
        'label': 'SPSB Spend',
        'category': 'SPSB',
        ...intl_currency_metric_options
    },
    'SPSB Units' : {
        'label': 'SPSB Units',
        'category': 'SPSB',
        ...int_metric_options
    },
    'SPSB Orders' : {
        'label': 'SPSB Orders',
        'category': 'SPSB',
        ...int_metric_options
    },
    'SPSB CNV (Units)' : {
        'label': 'SPSB CNV (Units)',
        'category': 'SPSB',
        ...percent_metric_options
    },
    'SPSB CNV (Orders)' : {
        'label': 'SPSB CNV (Orders)',
        'category': 'SPSB',
        ...percent_metric_options
    },
    'SPSB RoAS' : {
        'label': 'SPSB RoAS',
        'category': 'SPSB',
        ...percent_metric_options
    },
    'SPSB ACoS' : {
        'label': 'SPSB ACoS',
        'category': 'SPSB',
        ...percent_metric_options
    },
    'SPSB Clicks' : {
        'label': 'SPSB Clicks',
        'category': 'SPSB',
        ...int_metric_options
    },
    'SPSB Impressions' : {
        'label': 'SPSB Impressions',
        'category': 'SPSB',
        ...int_metric_options
    },
    'SPSB CTR' : {
        'label': 'SPSB CTR',
        'category': 'SPSB',
        ...percent_metric_options
    },
    'SPSB CPC' : {
        'label': 'SPSB CPC',
        'category': 'SPSB',
        ...intl_currency_metric_options
    },
    'SPSB CPA' : {
        'label': 'SPSB CPA',
        'category': 'SPSB',
        ...intl_currency_metric_options
    },
    'SPSB CPM' : {
        'label': 'SPSB CPM',
        'category': 'SPSB',
        ...intl_currency_metric_options
    },
    'SP Sales' : {
        'label': 'SP Sales',
        'category': 'SP',
        ...intl_currency_metric_options
    },
    'SP Spend' : {
        'label': 'SP Spend',
        'category': 'SP',
        ...intl_currency_metric_options
    },
    'SP Units' : {
        'label': 'SP Units',
        'category': 'SP',
        ...int_metric_options
    },
    'SP Orders' : {
        'label': 'SP Orders',
        'category': 'SP',
        ...int_metric_options
    },
    'SP CNV (Units)' : {
        'label': 'SP CNV (Units)',
        'category': 'SP',
        ...percent_metric_options
    },
    'SP CNV (Orders)' : {
        'label': 'SP CNV (Orders)',
        'category': 'SP',
        ...percent_metric_options
    },
    'SP RoAS' : {
        'label': 'SP RoAS',
        'category': 'SP',
        ...percent_metric_options
    },
    'SP ACoS' : {
        'label': 'SP ACoS',
        'category': 'SP',
        ...percent_metric_options
    },
    'SP Clicks' : {
        'label': 'SP Clicks',
        'category': 'SP',
        ...int_metric_options
    },
    'SP Impressions' : {
        'label': 'SP Impressions',
        'category': 'SP',
        ...int_metric_options
    },
    'SP CTR' : {
        'label': 'SP CTR',
        'category': 'SP',
        ...percent_metric_options
    },
    'SP CPC' : {
        'label': 'SP CPC',
        'category': 'SP',
        ...intl_currency_metric_options
    },
    'SP CPA' : {
        'label': 'SP CPA',
        'category': 'SP',
        ...intl_currency_metric_options
    },
    'SP CPM' : {
        'label': 'SP CPM',
        'category': 'SP',
        ...intl_currency_metric_options
    },
    'SP Performance Sales' : {
        'label': 'SP Performance Sales',
        'category': 'SP Performance',
        ...intl_currency_metric_options
    },
    'SP Performance Spend' : {
        'label': 'SP Performance Spend',
        'category': 'SP Performance',
        ...intl_currency_metric_options
    },
    'SP Performance Units' : {
        'label': 'SP Performance Units',
        'category': 'SP Performance',
        ...int_metric_options
    },
    'SP Performance Orders' : {
        'label': 'SP Performance Orders',
        'category': 'SP Performance',
        ...int_metric_options
    },
    'SP Performance CNV (Units)' : {
        'label': 'SP Performance CNV (Units)',
        'category': 'SP Performance',
        ...percent_metric_options
    },
    'SP Performance CNV (Orders)' : {
        'label': 'SP Performance CNV (Orders)',
        'category': 'SP Performance',
        ...percent_metric_options
    },
    'SP Performance RoAS' : {
        'label': 'SP Performance RoAS',
        'category': 'SP Performance',
        ...percent_metric_options
    },
    'SP Performance ACoS' : {
        'label': 'SP Performance ACoS',
        'category': 'SP Performance',
        ...percent_metric_options
    },
    'SP Performance Clicks' : {
        'label': 'SP Performance Clicks',
        'category': 'SP Performance',
        ...int_metric_options
    },
    'SP Performance Impressions' : {
        'label': 'SP Performance Impressions',
        'category': 'SP Performance',
        ...int_metric_options
    },
    'SP Performance CTR' : {
        'label': 'SP Performance CTR',
        'category': 'SP Performance',
        ...percent_metric_options
    },
    'SP Performance CPC' : {
        'label': 'SP Performance CPC',
        'category': 'SP Performance',
        ...intl_currency_metric_options
    },
    'SP Performance CPA' : {
        'label': 'SP Performance CPA',
        'category': 'SP Performance',
        ...intl_currency_metric_options
    },
    'SP Performance CPM' : {
        'label': 'SP Performance CPM',
        'category': 'SP Performance',
        ...intl_currency_metric_options
    },
    'SP Defensive Sales' : {
        'label': 'SP Defensive Sales',
        'category': 'SP Defensive',
        ...intl_currency_metric_options
    },
    'SP Defensive Spend' : {
        'label': 'SP Defensive Spend',
        'category': 'SP Defensive',
        ...intl_currency_metric_options
    },
    'SP Defensive Units' : {
        'label': 'SP Defensive Units',
        'category': 'SP Defensive',
        ...int_metric_options
    },
    'SP Defensive Orders' : {
        'label': 'SP Defensive Orders',
        'category': 'SP Defensive',
        ...int_metric_options
    },
    'SP Defensive CNV (Units)' : {
        'label': 'SP Defensive CNV (Units)',
        'category': 'SP Defensive',
        ...percent_metric_options
    },
    'SP Defensive CNV (Orders)' : {
        'label': 'SP Defensive CNV (Orders)',
        'category': 'SP Defensive',
        ...percent_metric_options
    },
    'SP Defensive RoAS' : {
        'label': 'SP Defensive RoAS',
        'category': 'SP Defensive',
        ...percent_metric_options
    },
    'SP Defensive ACoS' : {
        'label': 'SP Defensive ACoS',
        'category': 'SP Defensive',
        ...percent_metric_options
    },
    'SP Defensive Clicks' : {
        'label': 'SP Defensive Clicks',
        'category': 'SP Defensive',
        ...int_metric_options
    },
    'SP Defensive Impressions' : {
        'label': 'SP Defensive Impressions',
        'category': 'SP Defensive',
        ...int_metric_options
    },
    'SP Defensive CTR' : {
        'label': 'SP Defensive CTR',
        'category': 'SP Defensive',
        ...percent_metric_options
    },
    'SP Defensive CPC' : {
        'label': 'SP Defensive CPC',
        'category': 'SP Defensive',
        ...intl_currency_metric_options
    },
    'SP Defensive CPA' : {
        'label': 'SP Defensive CPA',
        'category': 'SP Defensive',
        ...intl_currency_metric_options
    },
    'SP Defensive CPM' : {
        'label': 'SP Defensive CPM',
        'category': 'SP Defensive',
        ...intl_currency_metric_options
    },
    'SP Research Sales' : {
        'label': 'SP Research Sales',
        'category': 'SP Research',
        ...intl_currency_metric_options
    },
    'SP Research Spend' : {
        'label': 'SP Research Spend',
        'category': 'SP Research',
        ...intl_currency_metric_options
    },
    'SP Research Units' : {
        'label': 'SP Research Units',
        'category': 'SP Research',
        ...int_metric_options
    },
    'SP Research Orders' : {
        'label': 'SP Research Orders',
        'category': 'SP Research',
        ...int_metric_options
    },
    'SP Research CNV (Units)' : {
        'label': 'SP Research CNV (Units)',
        'category': 'SP Research',
        ...percent_metric_options
    },
    'SP Research CNV (Orders)' : {
        'label': 'SP Research CNV (Orders)',
        'category': 'SP Research',
        ...percent_metric_options
    },
    'SP Research RoAS' : {
        'label': 'SP Research RoAS',
        'category': 'SP Research',
        ...percent_metric_options
    },
    'SP Research ACoS' : {
        'label': 'SP Research ACoS',
        'category': 'SP Research',
        ...percent_metric_options
    },
    'SP Research Clicks' : {
        'label': 'SP Research Clicks',
        'category': 'SP Research',
        ...int_metric_options
    },
    'SP Research Impressions' : {
        'label': 'SP Research Impressions',
        'category': 'SP Research',
        ...int_metric_options
    },
    'SP Research CTR' : {
        'label': 'SP Research CTR',
        'category': 'SP Research',
        ...percent_metric_options
    },
    'SP Research CPC' : {
        'label': 'SP Research CPC',
        'category': 'SP Research',
        ...intl_currency_metric_options
    },
    'SP Research CPA' : {
        'label': 'SP Research CPA',
        'category': 'SP Research',
        ...intl_currency_metric_options
    },
    'SP Research CPM' : {
        'label': 'SP Research CPM',
        'category': 'SP Research',
        ...intl_currency_metric_options
    },
    'SB Sales' : {
        'label': 'SB Sales',
        'category': 'SB',
        ...intl_currency_metric_options
    },
    'SB Spend' : {
        'label': 'SB Spend',
        'category': 'SB',
        ...intl_currency_metric_options
    },
    'SB Units' : {
        'label': 'SB Units',
        'category': 'SB',
        ...int_metric_options
    },
    'SB Orders' : {
        'label': 'SB Orders',
        'category': 'SB',
        ...int_metric_options
    },
    'SB CNV (Units)' : {
        'label': 'SB CNV (Units)',
        'category': 'SB',
        ...percent_metric_options
    },
    'SB CNV (Orders)' : {
        'label': 'SB CNV (Orders)',
        'category': 'SB',
        ...percent_metric_options
    },
    'SB RoAS' : {
        'label': 'SB RoAS',
        'category': 'SB',
        ...percent_metric_options
    },
    'SB ACoS' : {
        'label': 'SB ACoS',
        'category': 'SB',
        ...percent_metric_options
    },
    'SB Clicks' : {
        'label': 'SB Clicks',
        'category': 'SB',
        ...int_metric_options
    },
    'SB Impressions' : {
        'label': 'SB Impressions',
        'category': 'SB',
        ...int_metric_options
    },
    'SB CTR' : {
        'label': 'SB CTR',
        'category': 'SB',
        ...percent_metric_options
    },
    'SB CPC' : {
        'label': 'SB CPC',
        'category': 'SB',
        ...intl_currency_metric_options
    },
    'SB CPA' : {
        'label': 'SB CPA',
        'category': 'SB',
        ...intl_currency_metric_options
    },
    'SB CPM' : {
        'label': 'SB CPM',
        'category': 'SB',
        ...intl_currency_metric_options
    },
    'SD Sales' : {
        'label': 'SD Sales',
        'category': 'SD',
        ...intl_currency_metric_options
    },
    'SD Spend' : {
        'label': 'SD Spend',
        'category': 'SD',
        ...intl_currency_metric_options
    },
    'SD Units' : {
        'label': 'SD Units',
        'category': 'SD',
        ...int_metric_options
    },
    'SD Orders' : {
        'label': 'SD Orders',
        'category': 'SD',
        ...int_metric_options
    },
    'SD CNV (Units)' : {
        'label': 'SD CNV (Units)',
        'category': 'SD',
        ...percent_metric_options
    },
    'SD CNV (Orders)' : {
        'label': 'SD CNV (Orders)',
        'category': 'SD',
        ...percent_metric_options
    },
    'SD RoAS' : {
        'label': 'SD RoAS',
        'category': 'SD',
        ...percent_metric_options
    },
    'SD ACoS' : {
        'label': 'SD ACoS',
        'category': 'SD',
        ...percent_metric_options
    },
    'SD Clicks' : {
        'label': 'SD Clicks',
        'category': 'SD',
        ...int_metric_options
    },
    'SD Impressions' : {
        'label': 'SD Impressions',
        'category': 'SD',
        ...int_metric_options
    },
    'SD CTR' : {
        'label': 'SD CTR',
        'category': 'SD',
        ...percent_metric_options
    },
    'SD CPC' : {
        'label': 'SD CPC',
        'category': 'SD',
        ...intl_currency_metric_options
    },
    'SD CPA' : {
        'label': 'SD CPA',
        'category': 'SD',
        ...intl_currency_metric_options
    },
    'SD CPM' : {
        'label': 'SD CPM',
        'category': 'SD',
        ...intl_currency_metric_options
    },
    'DSP Spend' : {
        'label': 'DSP Spend',
        'category': 'DSP',
        ...intl_currency_metric_options
    },
    'DSP Sales' : {
        'label': 'DSP Sales',
        'category': 'DSP',
        ...intl_currency_metric_options
    },
    'DSP Impressions' : {
        'label': 'DSP Impressions',
        'category': 'DSP',
        ...int_metric_options
    },
    'DSP BrandSearch' : {
        'label': 'DSP BrandSearch',
        'category': 'DSP',
        ...int_metric_options
    },
    'DSP DPV' : {
        'label': 'DSP DPV',
        'category': 'DSP',
        ...int_metric_options
    },
    'DSP Purchases' : {
        'label': 'DSP Purchases',
        'category': 'DSP',
        ...int_metric_options
    },
    'DSP NTB Sales' : {
        'label': 'DSP NTB Sales',
        'category': 'DSP',
        ...intl_currency_metric_options
    },
    
    /* Customers */
    'Total Customers' : {
        'label': 'Total Customers',
        'category': 'Global',
        ...int_metric_options
    },
    'New To Brand Customers' : {
        'label': 'New To Brand Customers',
        'category': 'Global',
        ...int_metric_options
    },
    'New To Brand Units' : {
        'label': 'New To Brand Units',
        'category': 'Global',
        ...int_metric_options
    },
    'New To Brand Sales' : {
        'label': 'New To Brand Sales',
        'category': 'Global',
        ...currency_metric_options
    },
    'Repeat Customers' : {
        'label': 'Repeat Customers',
        'category': 'Global',
        ...int_metric_options
    },
    'Repeat Units' : {
        'label': 'Repeat Units',
        'category': 'Global',
        ...int_metric_options
    },
    'Repeat Sales' : {
        'label': 'Repeat Sales',
        'category': 'Global',
        ...currency_metric_options
    },

    /* Vparts / Inventory */
    'Average Price' : {
        'label': 'Average Price',
        'category': undefined,
        ...currency_metric_options
    },
    'QoH' : {
        'label': 'QoH',
        'category': undefined,
        ...int_metric_options
    },
}

export const metrics = Object.keys(metric_property);


export const getQuantitativeDataTableCols = (array) => {
    return array.map((value) => {
        const col = {
            dataField: value,
            text: metric_property[value]?.label ?? value,
            type: metric_property[value]?.type ?? "string",
            sort: true,
            formatter: metric_property[value]?.formatter ?? undefined,
            headerStyle: { width: "150px" },
        };
        return col;
    });
};