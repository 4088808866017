import React, { useEffect, useState, useContext } from "react";
import { Row, Col } from "react-bootstrap";
import {HeaderContext} from "../../contexts/HeaderContext";
import {AuthContext} from "../../contexts/AuthContext";
import {BrandContext} from "../../contexts/BrandContext";
import BootstrapTablesWrapper from "../../components/dataRender/BootstrapTablesWrapper";
import {IntFormatter} from "../../components/helper/NumberFormatters";
import fortress from "../../api/fortress";
import moment from "moment";
import WidgetSpinner from "../../components/helper/WidgetSpinner";
import TopProducts from "./charts/TopProducts";

const ProductPerformance = (props) => {
    const { token } = useContext(AuthContext);
    const { brand } = useContext(BrandContext);
    const { selectedDefaultDates,currency,marketplace } = useContext(HeaderContext);

    const [barChartLoading, setBarChartLoading] = useState(true);
    const [tableLoading, setTableLoading] = useState(true);

    const [barChartData, setBarChartData] = useState({
        labels: [],
        data: [
            {
                label: "Paid Sales",
                data: [
                ]
            }
        ]
    });

    const [topProducts, setTopProducts] = useState({
        order_volume:[],
        order_growth:[],
        roas:[],
    });

    const [productPerformance, setProductPerformance] = useState({
        columns: [],
        data: [],
    })

    useEffect(() => {
        async function fetchData() {
            try {
                const chartResponse = await fortress.get("paid-media/fetch-product-chart", {
                    headers: { Authorization: `Bearer ${token}` },
                    params: {
                        brand_id: brand.brandId,
                        currency: currency,
                        start_date: moment(selectedDefaultDates.tf_s).format('YYYY-MM-DD'),
                        end_date: moment(selectedDefaultDates.tf_e).format('YYYY-MM-DD'),
                    },
                });
                setBarChartData(chartResponse.data);
                setBarChartLoading(false);

                const topProducts = await fortress.get("paid-media/fetch-product-metrics", {
                    headers: { Authorization: `Bearer ${token}` },
                    params: {
                        brand_id: brand.brandId,
                        currency: currency,
                        start_date: moment(selectedDefaultDates.tf_s).format('YYYY-MM-DD'),
                        end_date: moment(selectedDefaultDates.tf_e).format('YYYY-MM-DD'),
                    },
                });
                setTopProducts(topProducts.data);
                console.log(topProducts.data);

                const tableData = await fortress.get("paid-media/fetch-product-table", {
                    headers: { Authorization: `Bearer ${token}` },
                    params: {
                        brand_id: brand.brandId,
                        currency: currency,
                        start_date: moment(selectedDefaultDates.tf_s).format('YYYY-MM-DD'),
                        end_date: moment(selectedDefaultDates.tf_e).format('YYYY-MM-DD'),
                    },
                });
                setProductPerformance(tableData.data);
                setTableLoading(false);

            } catch (error) {
                console.error("Error fetching data:", error);
            }
        }
        fetchData();
    },[token, brand, marketplace, selectedDefaultDates, currency]);



return (
    <div className="p-3">
        <Row className="mt-4">
            <Col style={{height: "34em"}}>
                {
                    barChartLoading ? <WidgetSpinner /> :(
                        <TopProducts showConfigButton={false}
                                             headerTitle="Top Paid Sales Products"
                                             widgetData={barChartData}
                        />
                    )
                }
            </Col>
        </Row>

        <Row className="mt-4">
            <Col>
                <div className="metric-card-sm">
                    <span className="text-dark text-h4">Top 5 Products by Order Volume</span>
                    <ul className="mt-3" style={{ listStyle: "none" }}>
                        {topProducts.order_volume.map((item, index) => (
                            <li key={index}>
                                {item.vpart}  {item.orders} Orders
                            </li>
                        ))}
                    </ul>
                </div>
            </Col>
            <Col>
                <div className="metric-card-sm">
                    <span className="text-dark text-h4">Top 5 Products by Order Growth</span>
                    <ul className="mt-3" style={{ listStyle: "none" }}>
                        {topProducts.order_growth.map((item, index) => (
                            <li key={index}>
                                {item.vpart}  {item.orders}
                            </li>
                        ))}
                    </ul>
                </div>
            </Col>
            <Col>
                <div className="metric-card-sm">
                    <span className="text-dark text-h4">Top 5 Products by ROAS</span>
                    <ul className="mt-3" style={{ listStyle: "none" }}>
                        {topProducts.roas.map((item, index) => (
                            <li key={index}>
                                {item.vpart}  {item.roas} ROAS
                            </li>
                        ))}
                    </ul>
                </div>
            </Col>
        </Row>

        <Row className="mt-4">
            <Col>
                {tableLoading ? <WidgetSpinner /> :(
                    <BootstrapTablesWrapper
                        data={productPerformance.rows}
                        columns={productPerformance.columns}
                        columnModal="none"
                        keyField="target"
                        hasPagination={true}
                        hasFilter={false}
                        hasSearch={true}
                        showButtons={true}
                    />
                )}

            </Col>
        </Row>
    </div>
)
}

export default ProductPerformance;