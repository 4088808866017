import React, { useEffect, useState, useContext } from "react";
import { Row, Col, Tabs, Tab } from "react-bootstrap";
import moment from "moment";

import { AuthContext } from "../../contexts/AuthContext";
import { BrandContext } from "../../contexts/BrandContext";
import { HeaderContext } from "../../contexts/HeaderContext";

import BootstrapTablesWrapper from "../../components/dataRender/BootstrapTablesWrapper";
import Loading from "../../components/helper/Loading";
import useIsMountedRef from "../../components/helper/isMountedRef";
import DataError from "../../components/helper/DataError";
import ChartDescription from "../../components/helper/ChartDescription";
import ProductImageThumbnail from "../../components/helper/productImageThumbnail";

import BundleLine from "../BrandReport/widgets/BundleLine";
import BundlePie from "../BrandReport/widgets/BundlePie.js";
import { DollarFormatter, IntFormatter } from "../../components/helper/NumberFormatters";

import { bundlePerformanceCharts, bundlePerformanceData, bundleAggregatedData } from "../../api/brandData";

const percentFormatter = new Intl.NumberFormat("en-US", { style: "percent", minimumFractionDigits: 2 });

const BundlePerformance = (props) => {
    // console.log("BundlePerformance", props);
    const { token } = useContext(AuthContext);
    const { brand } = useContext(BrandContext);
    const { 
        setPageTitle,
        setPageTimeframe,
        setPageDisplayTimeFrameInterval,
        setPageMarketplace,
        selectedDefaultDates,
        currency,
    } = useContext(HeaderContext);

    const [pageDataLoading, setPageDataLoading] = useState(true);
    const isMountedRef = useIsMountedRef();

    const [activeTabChart, setActiveTabChart] = useState("units");
    const [dataCharts, setDataCharts] = useState();

    const [activeTabTable, setActiveTabTable] = useState("aggregated");
    const [bundleTimeTable, setBundleTimeTable] = useState();
    const [bundleAggregatedTable, setbundleAggregatedTable] = useState();

    const [[dataError, errorMessage, errorResponse], setDataError] = useState([false, undefined, undefined]);

    useEffect(() => {
        setPageTitle("Bundles Performance");
        setPageMarketplace('Amazon');
        setPageTimeframe("timeframe");
        setPageDisplayTimeFrameInterval(true);
        return () => {
            setPageTitle(process.env.REACT_APP_TITLE);
        };
    });

    useEffect(() => {
        const getData_bundlePerformanceData = async () => {
            // For DataTable: (Sales Over Time)
            const res = await bundlePerformanceData(token, {
                brandid: brand.brandId,
                tf_s: moment(selectedDefaultDates.tf_s).format('YYYY-MM-DD'),
                tf_e: moment(selectedDefaultDates.tf_e).format('YYYY-MM-DD'),
                wow: selectedDefaultDates.timeframe,
                currency: currency,
            });

            setBundleTimeTable(res.data?.bundlePerformanceData?.data ?? []);
            // console.log("bundlePerformanceData Data", res.data);

            if (
                res?.data?.bundlePerformanceData?.data === undefined
                || res?.data?.bundlePerformanceData?.data.length === 0
            ) {
                setPageDataLoading(false);
                setDataError([true, "No Bundle Data", undefined]);
                return false;
            }

            return true;
        };

        const getData_bundleAggregatedData = async () => {
            // For DataTable: Bundles - Aggregated
            const res = await bundleAggregatedData(token, {
                brandid: brand.brandId,
                tf_s: moment(selectedDefaultDates.tf_s).format('YYYY-MM-DD'),
                tf_e: moment(selectedDefaultDates.tf_e).format('YYYY-MM-DD'),
                wow: selectedDefaultDates.timeframe,
                currency: currency,
            });

            if (res.data?.bundleAggregatedData?.data !== undefined) {
                let total_bundle_sales = res.data.bundleAggregatedData.data.reduce((carry, row, index) => {
                    return carry + row["bundle_sales"] ?? 0;
                }, 0);

                res.data.bundleAggregatedData.data.map((row, index) => {
                    row["percent_total"] = row["bundle_sales"] / total_bundle_sales;
                    return row;
                });

                setbundleAggregatedTable(res.data.bundleAggregatedData.data);
                // console.log("bundleAggregatedData Data", res.data, total_bundle_sales);

                return true;
            }

            return false;
        };

        const getData_bundlePerformanceCharts = async () => {
            // For Chart: Bundles - Line and Pie
            const res = await bundlePerformanceCharts(token, {
                brandid: brand.brandId,
                tf_s: moment(selectedDefaultDates.tf_s).format('YYYY-MM-DD'),
                tf_e: moment(selectedDefaultDates.tf_e).format('YYYY-MM-DD'),
                wow: selectedDefaultDates.timeframe,
                currency: currency,
            });
            
            setDataCharts(res.data);
        };

        const getData = async () => {
            try {
                // For DataTable: Bundles - Sales Over Time
                const bundlePerformanceData = await getData_bundlePerformanceData();
                if (!bundlePerformanceData) {
                    return;
                }

                // For DataTable: Bundles - Aggregated
                await getData_bundleAggregatedData();

                // For Chart: Bundles - Line and Pie
                await getData_bundlePerformanceCharts();

                setPageDataLoading(false);
            } catch (err) {
                // console.log(err);
                setPageDataLoading(false);
                setDataError([true, err.response?.statusText, err.response]);
            }
        };

        // Reset Data
        setPageDataLoading(true);
        setDataError([false, undefined, undefined]);

        setBundleTimeTable(undefined);
        setbundleAggregatedTable(undefined);
        setDataCharts(undefined);

        // Fetch Data
        getData();
    }, [token, brand, selectedDefaultDates, currency]);

    const pageDollarFormatter = (value) => {
        return DollarFormatter(value, currency, brand.brandLocale);
    };

    return pageDataLoading === true ? (
        <Loading></Loading>
    ) : dataError === true ? (
        <DataError showFull="false" status={errorResponse?.status} message={errorMessage} response={errorResponse?.data}></DataError>
    ) : (
        <main className="d-flex justify-content-start align-items-top">
            <Col>
                <Row className="mt-5">
                    <Col>
                        <Tabs activeKey={activeTabChart} onSelect={(k) => setActiveTabChart(k)}>
                            <Tab className="bg-white" eventKey={"units"} title={"Units"}>
                                <Row className="pt-2">
                                    <Col md={8}>
                                        <div style={{ height: "34em" }}>
                                            <BundleLine
                                                widgetID={"bundlePerformanceUnits"}
                                                headerTitle="Bundle Performance Over Time (Units)"
                                                widgetLabel={"Bundle Units"}
                                                widgetData={dataCharts?.bundlePerformanceCharts?.unitsLine}
                                                widgetFormatter={IntFormatter}
                                                widgetDataLoading={isMountedRef && pageDataLoading}
                                            />
                                        </div>
                                    </Col>
                                    <Col md={4}>
                                        <div style={{ height: "34em" }}>
                                            <BundlePie
                                                widgetID={"bundlePieUnits"}
                                                headerTitle="Bundle Percent of Sales (Units)"
                                                widgetData={dataCharts?.bundlePerformanceCharts?.pieUnits}
                                                widgetFormatter={IntFormatter}
                                                widgetDataLoading={isMountedRef && pageDataLoading}
                                            />
                                        </div>
                                    </Col>
                                </Row>
                            </Tab>
                            <Tab className="bg-white" eventKey={"sales"} title={"Sales"}>
                                <Row className="pt-2">
                                    <Col md={8}>
                                        <div style={{ height: "34em" }}>
                                            <BundleLine
                                                widgetID={"bundlePerformanceUnits"}
                                                headerTitle="Bundle Performance Over Time (Sales)"
                                                widgetLabel={"Bundle Sales"}
                                                widgetData={dataCharts?.bundlePerformanceCharts?.salesLine}
                                                widgetFormatter={pageDollarFormatter}
                                                widgetDataLoading={isMountedRef && pageDataLoading}
                                            />
                                        </div>
                                    </Col>
                                    <Col md={4}>
                                        <div style={{ height: "34em" }}>
                                            <BundlePie
                                                widgetID={"bundlePieSales"}
                                                headerTitle="Bundle Percent of Sales (Sales)"
                                                widgetData={dataCharts?.bundlePerformanceCharts?.pieSales}
                                                widgetFormatter={pageDollarFormatter}
                                                widgetDataLoading={isMountedRef && pageDataLoading}
                                            />
                                        </div>
                                    </Col>
                                </Row>
                            </Tab>
                        </Tabs>
                    </Col>
                </Row>

                <Row className="mt-4">
                    <Col>
                        <Tabs activeKey={activeTabTable} onSelect={(k) => setActiveTabTable(k)}>
                            <Tab eventKey={"aggregated"} title={"Bundle Totals"}>
                                <div>
                                    {bundleAggregatedTable && (
                                        <BootstrapTablesWrapper
                                            data={bundleAggregatedTable}
                                            keyField="index"
                                            description={
                                                <ChartDescription
                                                    title={"Bundle Performance Aggregated"}
                                                    description={"Total Bundle Performance in the timeframe"}
                                                />
                                            }
                                            hasPagination={true}
                                            hasFilter={true}
                                            defaultSorted={[
                                                {
                                                    dataField: "bundle_sales",
                                                    order: "desc",
                                                },
                                            ]}
                                            columns={[
                                                // { dataField: "brand_name", text: "Brand", sort: false, type: "string" },
                                                {
                                                    dataField: "bundle_asin",
                                                    text: "Bundle ASIN",
                                                    sort: true,
                                                    type: "string",
                                                    filter: "textFilter",
                                                    headerStyle: (colum, colIndex) => {
                                                        return { minWidth: "135px", maxWidth: "270px" };
                                                    },
                                                    csvFormatter: (cell, row, rowIndex) => {
                                                        return cell;
                                                    },
                                                    formatter: (cell, row, rowIndex) => {
                                                        return (
                                                            <div className="d-flex flex-row flex-nowrap align-items-center">
                                                                {row['largeImage'] && <ProductImageThumbnail
                                                                    imageSrc={row['largeImage']}
                                                                    imageLink={row['asinLink']}
                                                                    imageAlt={row["vpart"]}
                                                                />}
                                                                <div className="d-flex flex-column">
                                                                    <div className="d-flex flex-row">
                                                                        <a
                                                                            href={row["asinLink"]}
                                                                            target="_blank"
                                                                            rel="noopener noreferrer"
                                                                        >
                                                                            {cell}
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        );
                                                    },
                                                },
                                                {
                                                    dataField: "bundle_title",
                                                    text: "Bundle Title",
                                                    type: "string",
                                                    sort: true,
                                                    formatter: (cell, row, rowIndex) => {
                                                        return (
                                                            <div
                                                                style={{
                                                                    display: "-webkit-box",
                                                                    WebkitBoxOrient: "vertical",
                                                                    WebkitLineClamp: 3,
                                                                    overflow: "hidden",
                                                                    textOverflow: "ellipsis",
                                                                }}
                                                                title={cell}
                                                            >
                                                                {cell}
                                                            </div>
                                                        );
                                                    },
                                                },
                                                {
                                                    dataField: "bundle_units",
                                                    text: "Bundle Units",
                                                    type: "number",
                                                    sort: true,
                                                    formatter: "intFormatter",
                                                },
                                                {
                                                    dataField: "bundle_sales",
                                                    text: "Bundle Sales",
                                                    type: "number",
                                                    sort: true,
                                                    formatter: "dollarFormatter",
                                                },
                                                {
                                                    dataField: "percent_total",
                                                    text: "Bundle % of Total",
                                                    type: "number",
                                                    sort: true,
                                                    formatter: "percentFormatter",
                                                },
                                            ]}
                                        />
                                    )}
                                </div>
                            </Tab>
                            <Tab eventKey={"overtime"} title={"Sales Over Time"}>
                                <div>
                                    {bundleTimeTable && (
                                        <BootstrapTablesWrapper
                                            data={bundleTimeTable}
                                            keyField="index"
                                            description={
                                                <ChartDescription
                                                    title={"Bundle Performance Over Time"}
                                                    description={"Raw data of bundle sales over time"}
                                                />
                                            }
                                            hasPagination={true}
                                            hasFilter={true}
                                            defaultSorted={[
                                                {
                                                    dataField: "sale_date",
                                                    order: "desc",
                                                },
                                            ]}
                                            columns={[
                                                // { dataField: "brand_name", text: "Brand", sort: false, type: "string" },
                                                {
                                                    dataField: "bundle_asin",
                                                    text: "Bundle ASIN",
                                                    sort: true,
                                                    type: "string",
                                                    filter: "textFilter",
                                                    headerStyle: (colum, colIndex) => {
                                                        return { minWidth: "135px", maxWidth: "270px" };
                                                    },
                                                    csvFormatter: (cell, row, rowIndex) => {
                                                        return cell;
                                                    },
                                                    formatter: (cell, row, rowIndex) => {
                                                        return (
                                                            <div className="d-flex flex-row flex-nowrap align-items-center">
                                                                {row['largeImage'] && <ProductImageThumbnail
                                                                    imageSrc={row['largeImage']}
                                                                    imageLink={row['asinLink']}
                                                                    imageAlt={row["vpart"]}
                                                                />}
                                                                <div className="d-flex flex-column">
                                                                    <div className="d-flex flex-row">
                                                                        <a
                                                                            href={row["asinLink"]}
                                                                            target="_blank"
                                                                            rel="noopener noreferrer"
                                                                        >
                                                                            {cell}
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        );
                                                    },
                                                },
                                                {
                                                    dataField: "bundle_title",
                                                    text: "Bundle Title",
                                                    type: "string",
                                                    sort: true,
                                                    formatter: (cell, row, rowIndex) => {
                                                        return (
                                                            <div
                                                                style={{
                                                                    display: "-webkit-box",
                                                                    WebkitBoxOrient: "vertical",
                                                                    WebkitLineClamp: 3,
                                                                    overflow: "hidden",
                                                                    textOverflow: "ellipsis",
                                                                }}
                                                                title={cell}
                                                            >
                                                                {cell}
                                                            </div>
                                                        );
                                                    },
                                                },
                                                {
                                                    dataField: "bundle_discount",
                                                    text: "Bundle Discount",
                                                    type: "number",
                                                    sort: true,
                                                    formatter: (cell, row, rowIndex) => {
                                                        return <div>{percentFormatter.format(cell)}</div>;
                                                    },
                                                },
                                                {
                                                    dataField: "sale_date",
                                                    text: "Sale Date",
                                                    type: "date",
                                                    sort: true,
                                                    filter: "dateFilter",
                                                    headerStyle: (colum, colIndex) => {
                                                        return { width: "300px" };
                                                    },
                                                },
                                                {
                                                    dataField: "bundle_price",
                                                    text: "Bundle Price",
                                                    type: "number",
                                                    sort: true,
                                                    formatter: "dollarFormatter",
                                                },
                                                {
                                                    dataField: "bundle_units",
                                                    text: "Bundle Units",
                                                    type: "number",
                                                    sort: true,
                                                    formatter: "intFormatter",
                                                },
                                                {
                                                    dataField: "bundle_sales",
                                                    text: "Bundle Sales",
                                                    type: "number",
                                                    sort: true,
                                                    formatter: "dollarFormatter",
                                                },
                                                {
                                                    dataField: "page_views",
                                                    text: "Page Views",
                                                    type: "number",
                                                    sort: true,
                                                    formatter: "intFormatter",
                                                },
                                                {
                                                    dataField: "cnv",
                                                    text: "CNV",
                                                    type: "number",
                                                    sort: true,
                                                    formatter: "percentFormatter",
                                                },
                                            ]}
                                        />
                                    )}
                                </div>
                            </Tab>
                        </Tabs>
                    </Col>
                </Row>
            </Col>
        </main>
    );
};

export default BundlePerformance;
