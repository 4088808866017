import React, { useContext, useState, useEffect } from "react";
import { ResponsiveContainer } from "recharts";
import Chart from "react-apexcharts";
import WidgetSpinner from "../../../components/helper/WidgetSpinner";
import useIsMountedRef from "../../../components/helper/isMountedRef";
import {HeaderContext} from "../../../contexts/HeaderContext";


const chartFont = {
    colors: "gray",
    fontSize: "12px",
    cssClass: "apexcharts-xaxis-label",
};

function SalesChannelChart(props) {

    const [chartOptions, setChartOptions] = useState({});
    const [series, setSeries] = useState([]);

    const isMountedRef = useIsMountedRef();

    const {
        currency,
        chartData,
        stacked,
        setMounted,
    } = props;

    // const cleanCurrencyFormatter = new Intl.NumberFormat(brand?.currencyLocale ?? "en-US", {
    const cleanCurrencyFormatter = new Intl.NumberFormat( "en-US", {
        style: "currency",
        currency: currency,
        maximumFractionDigits: 2,
        minimumFractionDigits: 0,
    });

    const getCleanCurrencyFormatter = (value) => {
        return cleanCurrencyFormatter.format(value);
    };

    useEffect(() => {
        if (isMountedRef.current) {
            setMounted(true);
        }
    }, [isMountedRef])

    useEffect(() => {

        if (chartData !== undefined) {
            let labels = chartData.labels;

            let new_series = [];
            let new_chartOptions = {};

            for(let i=0; chartData.data.length>i;i++){
                new_series.push({
                    name: chartData.data[i].label,
                    data: chartData.data[i].data,
                })

            }


            new_chartOptions = {
                chart: {
                    id: "barChart",
                    toolbar: {
                        show: true,
                    },
                    zoom: {
                        enabled: false,
                    },
                    dropShadow: {
                        enabled: false,
                    },
                    animations: {
                        enabled: false,
                    },
                    height: "100%",
                    type: 'bar',
                },
                // plotOptions: {
                //     bar: {
                //         borderRadius: 10,
                //     }
                // },
                legend:{show: true},
                // stroke: {
                //     show: true,
                //     width: 2,
                //     colors: ['transparent']
                // },
                // grid: {
                //     show: true,
                //     borderColor: "#eaecec",
                //     xaxis: { lines: { show: true } },
                //     yaxis: { lines: { show: true } },
                // },
                // markers: {
                //     strokeWidth: [5, 5, 5, 5],
                //     strokeOpacity: 1,
                //     fillOpacity: 1,
                //     hover: { sizeOffset: 1 },
                // },
                colors: ['#84344e','#E3FF00','#313132','#9d555e','#8a8a8b','#b4cb00','#dbff51','#c99b98','#b5b5b5','#a7bd00','#84344e'],
                xaxis: {
                    categories: labels,
                    type: "category",
                    labels: {
                        style: chartFont,
                    },
                    tickPlacement: "between",
                },
                yaxis: {
                    title: {
                        text: "",
                    },
                    min: 0,
                    labels: {
                        formatter: (val) => getCleanCurrencyFormatter(val),
                        style: chartFont,
                    },
                },
            };

            setTimeout(()=>{
                setSeries(new_series);
                setChartOptions(new_chartOptions);
            },100);

        }

    }, [props.chartData])

    return (
        <ResponsiveContainer>
            <Chart options={chartOptions} series={series} ref={isMountedRef} type="bar" />
        </ResponsiveContainer>
    );
}

function PerformanceOverTime(props) {
    const { currency } = useContext(HeaderContext);


    // const [timelineData, setTimelineData] = useState(props.widgetData ?? null);
    const timelineData = props.widgetData ?? null;
    const [widgetLoading, setWidgetLoading] = useState((props.widgetData !== null && props.widgetData !== undefined) ? false : (props.widgetDataLoading ?? true));
    const [chartMounted, setChartMounted] = useState(false);


    const {
        headerTitle = "",
        headerSubtitle = "",
    } = props;

    return (
        <div className="card h-100">
            <div className="card-header bg-white">
                <div className="d-flex flex-row align-items-center">
                    <div>
                        <h5 className="mb-0">{headerTitle}</h5>
                        {headerSubtitle !== "" ? <p className="body-1 text-secondary mb-0 mt-2">{headerSubtitle}</p> : ""}
                    </div>

                </div>
            </div>

            <div className="card-body w-100">{
                widgetLoading ?
                    <WidgetSpinner />
                    :
                    timelineData && (
                        <SalesChannelChart
                            currency={currency}
                            chartData={timelineData}
                            stacked={props.stacked ?? null}
                            setMounted={(value) => setChartMounted(value)}
                        />
                    )
            }
            </div>
        </div>
    );
}

export default PerformanceOverTime;
