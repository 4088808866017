import React, { useContext } from 'react';

import { Redirect, Route } from 'react-router-dom';
import { AuthContext } from '../contexts/AuthContext';

const AllowedRoute = ({ component: Component, noRedirect = false, ...rest }) => {
  const { apiUser, loading: isAuthLoading } = useContext(AuthContext);
  const attemptedRoute = rest.location?.pathname;
  const orgPages = apiUser?.organization?.pages || [];
  const pageRoutes = {
    'customer-geography': '/customer/geography',
  };
  const matchingKey = Object.keys(pageRoutes).find((key) => pageRoutes[key] === attemptedRoute);
  let proceed = false;

  if (!matchingKey) {
    proceed = true;
  } else {
    proceed = orgPages.includes(matchingKey);
  }

  return !isAuthLoading && apiUser ? proceed ? <Route {...rest} /> : <Redirect to="/404" /> : null;
};

export default AllowedRoute;
