import React, { forwardRef, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { Button, Card, Col, Modal, Row } from 'react-bootstrap';

import ToolkitProvider, { CSVExport, Search } from 'react-bootstrap-table2-toolkit';
import paginationFactory from 'react-bootstrap-table2-paginator';
import filterFactory, {
  dateFilter,
  multiSelectFilter,
  numberFilter,
  selectFilter,
  textFilter,
} from 'react-bootstrap-table2-filter';
import BootstrapTable from 'react-bootstrap-table-next';

import { BrandContext } from '../../contexts/BrandContext';
import { HeaderContext } from '../../contexts/HeaderContext';

import WidgetSpinner from '../../components/helper/WidgetSpinner';
import {
  tableColorDollarFormatter,
  tableIntFormatter,
  tableNumberFormatter,
  tablePercentBBXColorFormatter,
  tablePercentColorFormatter,
  tablePercentFormatter,
  tableRatioFormatter,
} from '../../components/helper/NumberFormatters';

import { CurrencyFormatter } from '../../components/helper/IntlNumberFormatter';

import DraggableColumnList from '../../components/dataRender/DraggableColumnList';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faColumns, faDownload } from '@fortawesome/free-solid-svg-icons';

import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';

import EventBus from '../EventBus';

const CustomToggleList = ({ columns, onColumnToggle, toggles }) => (
  <div className="btn-group btn-group-toggle " data-toggle="buttons">
    {columns
      .map((column) => ({
        ...column,
        toggle: toggles[column.dataField],
      }))
      .map((column) => (
        <button
          type="button"
          key={column.dataField}
          className={`btn ${column?.toggle ? 'btn-info' : 'btn-info'}`}
          data-toggle="button"
          onClick={() => onColumnToggle(column.dataField)}
        >
          {column.text}
        </button>
      ))}
  </div>
);

/**
 * <BootstrapTablesWrapper
 data={}                         // Required. data for the table
 keyField="index"                // Required. Unique column of the table
 columns={newCols}               // Required.
 minHeight="170px"               // Min height of the table before render
 defaultSorted={defaultSorted}   // Default Sort
 hasPagination={true}            // Paging
 hasFilter={true}                // Filter (Search)
 hasSearch={true}                // Show Search
 tableOnly={true}                // True = just boostraptable. False = Card + table
 columnModal={true}              // Modal selector for columns
 headerNowrap={false}            // For X-Overflow
 rowNowrap={true}                // For X-Overflow
 groupHeaders={true}             // Enable auto group headers when data-group attributes exist
 />
 *
 * @param {*} props
 * @returns BootstrapTablesWrapper
 */
const BootstrapTablesWrapper = forwardRef((props, ref) => {
  const { brand } = useContext(BrandContext);
  const { currency } = useContext(HeaderContext);
  const localRef = useRef(null);

  // Use either the forwarded ref or a local one
  const tableRef = ref || localRef;

  const { SearchBar } = Search;
  const { ExportCSVButton } = CSVExport;

  const [loading, setLoading] = useState(true);
  const [noData, setNoData] = useState(true);

  const [tableColumns, setTableColumns] = useState(props.columns);
  const [tableColumnsTemp, setTableColumnsTemp] = useState(tableColumns);

  const [filterOpen, setFilterOpen] = useState(false);

  const [showColumnModal, setShowColumnModal] = useState(false);

  const {
    keyField,
    defaultSorted = [],
    tableOnly = false,
    description = (props.description ?? '' !== '') ? props.description : undefined,
    wrapperDivClasses = undefined,
    wrapperClasses = '',
    headerClasses = '',
    headerNowrap = props.headerNowrap === true ? 'text-nowrap' : '',
    rowClasses = '',
    rowNowrap = props.rowNowrap === true ? 'text-nowrap' : '',
    columnModal = true,
    tableFilter = props.hasFilter === true ? filterFactory() : undefined,
    hasSearch = true,
    showButtons = true,
    additionalButtons = null,
    groupHeaders = true, // prop to enable/disable group headers
  } = props;

  const tablePaginaton =
    props.hasPagination === true
      ? paginationFactory({ sizePerPage: props.sizePerPage ?? 12, alwaysShowAllBtns: true })
      : undefined;

  const fullHeaderClasses = `${headerNowrap} ${headerClasses}`;
  const fullRowClasses = `${rowNowrap} ${rowClasses}`;

  const handleColumnModalClose = () => {
    setShowColumnModal(false);
  };

  const handleColumnModalShow = () => {
    setShowColumnModal(true);
  };

  const handleColumnModalSave = () => {
    //console.log("handleColumnModalSave", tableColumnsTemp, tableColumns);
    setTableColumns(tableColumnsTemp);
    setShowColumnModal(false);
  };

  const handleColumnChange = (value) => {
    // console.log('handleColumnChange', value, tableColumnsTemp);

    setTableColumnsTemp(value);
    // console.log('handleColumnChange END', value, tableColumnsTemp);
  };
  const formatColumns = (columns) => {
    let newCols = undefined;

    if (columns !== undefined) {
      newCols = columns.map((row, index) => {
        if (row['filter'] !== undefined) {
          if (row['filter'] === 'textFilter') {
            row['filter'] = textFilter();
          } else if (row['filter'] === 'selectFilter') {
            row['filter'] = selectFilter();
          } else if (row['filter'] === 'multiSelectFilter') {
            row['filter'] = multiSelectFilter();
          } else if (row['filter'] === 'numberFilter') {
            row['filter'] = numberFilter();
          } else if (row['filter'] === 'dateFilter') {
            row['filter'] = dateFilter({ style: { maxWidth: '100%' } });
          }
        }

        if (row['formatter'] !== undefined) {
          if (row['formatter'] === 'dollarFormatter') {
            row['formatter'] = tableIntlCurrencyFormatter;
          } else if (row['formatter'] === 'intlCurrencyFormatter') {
            row['formatter'] = tableIntlCurrencyFormatter;
          } else if (row['formatter'] === 'IntlColorCurrencyFormatter') {
            row['formatter'] = tableIntlColorCurrencyFormatter;
          } else if (row['formatter'] === 'tableColorDollarFormatter') {
            row['formatter'] = tableIntlColorCurrencyFormatter;
          } else if (row['formatter'] === 'intlColorCurrencyFormatter') {
            row['formatter'] = tableIntlColorCurrencyFormatter;
          } else if (row['formatter'] === 'percentFormatter') {
            row['formatter'] = tablePercentFormatter;
          } else if (row['formatter'] === 'percentColorFormatter') {
            row['formatter'] = tablePercentColorFormatter;
          } else if (row['formatter'] === 'percentBBXColorFormatter') {
            row['formatter'] = tablePercentBBXColorFormatter;
          } else if (row['formatter'] === 'numberFormatter') {
            row['formatter'] = tableNumberFormatter;
          } else if (row['formatter'] === 'ratioFormatter') {
            row['formatter'] = tableRatioFormatter;
          } else if (row['formatter'] === 'intFormatter') {
            row['formatter'] = tableIntFormatter;
          }
        }

        return row;
      });
    }

    return newCols;
  };

  useEffect(() => {
    // console.log('BootstrapTablesWrapper Init', props);
    if (props.data !== undefined && props.data.length === 0) {
      setTableColumns(formatColumns(props.columns));
      setNoData(true);
    } else if (
      props.data !== undefined &&
      props.data.length > 0 &&
      props.columns !== undefined &&
      props.columns.length > 0
    ) {
      setTableColumns(formatColumns(props.columns));
      setLoading(false);
      setNoData(false);
    } else {
      setLoading(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.data, props.columns, currency]);

  useEffect(() => {
    setLoading(props.loading);
  }, [props.loading]);

  const tableIntlCurrencyFormatter = (cell, row, rowIndex) => {
    if (cell === '' || cell === undefined) return 'N/A';
    return (
      <CurrencyFormatter
        key={0}
        replaceEmpty={true}
        colorText={false}
        displayDecimal={true}
        value={cell}
      />
    );
  };

  const tableIntlColorCurrencyFormatter = (cell, row, rowIndex) => {
    if (cell === '' || cell === undefined) return 'N/A';
    return (
      <CurrencyFormatter
        key={0}
        replaceEmpty={true}
        colorText={false}
        displayDecimal={true}
        value={cell}
      />
    );
  };

  // Function to add group header row after the table is rendered
  const addGroupHeaderRow = useCallback(() => {
    try {
      if (!groupHeaders) return; // Skip if group headers are disabled

      if (tableRef.current) {
        const tableElement = tableRef.current.querySelector('table');
        if (tableElement) {
          // Check if group header already exists
          if (tableElement.querySelector('thead tr.group-header')) {
            // tableElement.querySelector('thead tr.group-header').remove();
            return;
          }
          const thead = tableElement.querySelector('thead');
          if (!thead) return; // Exit if no thead exists
          const originalHeaderRow = Array.from(thead.querySelectorAll('tr') || []).filter(
            (element) => !element.classList.contains('group-header'),
          )?.[0];
          if (!originalHeaderRow) return; // Exit if no header row exists

          // Get all header cells
          const headerCells = originalHeaderRow.querySelectorAll('th');

          // Check if we have any data-group attributes
          let hasDataGroups = false;
          const groups = {};

          // Count columns per group
          headerCells.forEach((cell, idx) => {
            const dataGroupAttr = cell.getAttribute('data-group');
            const dataGroup = dataGroupAttr ?? cell.textContent;

            if (dataGroupAttr) {
              hasDataGroups = true;
            }

            if (!groups[dataGroup]) {
              groups[dataGroup] = {
                count: 1,
                name: dataGroup,
                text: cell.getAttribute('data-group-name') ?? dataGroup,
                startIndex: idx,
                hidden: dataGroupAttr ? false : true,
                useOriginal: dataGroupAttr ? undefined : cell,
              };
            } else {
              groups[dataGroup].count++;
            }
          });

          // Only create the group header if we found data-group attributes
          if (hasDataGroups) {
            // Create group header row
            const groupHeaderRow = document.createElement('tr');
            groupHeaderRow.classList.add('group-header');

            // Sort groups by their starting index to maintain correct order
            const sortedGroups = Object.values(groups).sort((a, b) => a.startIndex - b.startIndex);

            // Create header cells for each group
            sortedGroups.forEach((group) => {
              if (group.useOriginal) {
                const groupHeader = group.useOriginal.cloneNode(true);
                groupHeader.rowSpan = 2;
                groupHeader.style.textAlign = 'center';
                groupHeader.style.verticalAlign = 'middle';
                groupHeaderRow.appendChild(groupHeader);

                group.useOriginal.style.display = 'none';
              } else {
                const groupHeader = document.createElement('th');
                groupHeader.colSpan = group.count;
                groupHeader.textContent = group.text;
                groupHeader.style.textAlign = 'center';
                // groupHeader.style.visibility = group.hidden === true ? 'hidden' : 'visible';
                groupHeaderRow.appendChild(groupHeader);
              }
            });

            // Insert group header row before the original header row
            thead.insertBefore(groupHeaderRow, originalHeaderRow);
          }
        }
      }
    } catch (error) {
      console.error('Error adding group header row:', error);
    }
  }, [groupHeaders, tableRef]);

  useEffect(() => {
    if (tableRef.current && groupHeaders) {
      // Add the group header after table is rendered
      setTimeout(() => {
        addGroupHeaderRow();
      }, 0);
    }
  }, [props, tableRef, groupHeaders, addGroupHeaderRow]);

  const renderTable = () => {
    const rowClicked = {
      onClick: (e, row, rowIndex) => {
        EventBus.$dispatch('table-row-clicked', row);
      },
    };

    return (
      <div
        ref={tableRef}
        className="position-relative"
        style={{ minHeight: props.minHeight ?? '500px' }}
      >
        {loading ? (
          <div className="position-absolute h-100 w-100">
            <WidgetSpinner />
          </div>
        ) : (
          <ToolkitProvider
            bootstrap4
            exportCSV={props?.exportCSV ?? true}
            columnToggle
            search
            keyField={keyField}
            data={noData ? [] : (props?.data ?? [])}
            columns={tableColumns}
          >
            {(props) => (
              <div className="px-3">
                <Row className="justify-content-between body-2">
                  <div className="d-flex">
                    {showButtons && (
                      <>
                        <ExportCSVButton
                          className="btn btn-info my-0"
                          style={{ height: '35px' }}
                          {...props.csvProps}
                        >
                          <FontAwesomeIcon icon={faDownload} /> Export CSV
                        </ExportCSVButton>
                        {additionalButtons && <div className="d-flex">{additionalButtons}</div>}
                      </>
                    )}
                  </div>
                  <div className="d-flex justify-content-end">
                    <Row>
                      {columnModal === true ? (
                        <Col className="text-right">
                          <button className="btn btn-info my-0" onClick={handleColumnModalShow}>
                            <FontAwesomeIcon icon={faColumns} /> Columns
                          </button>
                        </Col>
                      ) : columnModal !== 'none' ? (
                        <Col className="text-right">
                          <button
                            className="btn btn-info my-0"
                            onClick={() => {
                              setFilterOpen(!filterOpen);
                            }}
                          >
                            <FontAwesomeIcon icon={faColumns} /> Columns
                          </button>
                        </Col>
                      ) : (
                        ''
                      )}

                      {hasSearch === true ? (
                        <Col>
                          <SearchBar {...props.searchProps} />
                        </Col>
                      ) : (
                        ''
                      )}
                    </Row>
                  </div>

                  {columnModal === true ? (
                    <Modal show={showColumnModal} onHide={handleColumnModalClose}>
                      <Modal.Header closeButton>
                        <Modal.Title className="body-1-bold">Edit Columns</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <DraggableColumnList
                          updateColumns={(value) => handleColumnChange(value)}
                          keyField={keyField}
                          {...props.columnToggleProps}
                        />
                      </Modal.Body>
                      <Modal.Footer>
                        <Button variant="secondary" onClick={handleColumnModalClose}>
                          {' '}
                          Close{' '}
                        </Button>
                        <Button variant="info" onClick={handleColumnModalSave}>
                          {' '}
                          Save Changes{' '}
                        </Button>
                      </Modal.Footer>
                    </Modal>
                  ) : (
                    filterOpen && (
                      <div className="mt-2 w-100 text-nowrap" style={{ overflowX: 'auto' }}>
                        <CustomToggleList {...props.columnToggleProps} />
                      </div>
                    )
                  )}

                  <div className={'w-100 ' + (wrapperDivClasses ?? 'my-3')}>
                    {tableOnly === true && description !== undefined ? (
                      <div>{description}</div>
                    ) : (
                      ''
                    )}
                    <BootstrapTable
                      {...props.baseProps}
                      rowClasses={fullRowClasses}
                      headerClasses={fullHeaderClasses}
                      wrapperClasses={`${wrapperClasses} table-responsive mb-3`}
                      bootstrap4={true}
                      hover
                      bordered
                      classes="my-0 roboto-medium"
                      pagination={tablePaginaton}
                      filter={tableFilter}
                      defaultSorted={defaultSorted}
                      rowEvents={rowClicked}
                      noDataIndication={() => (
                        <span className="d-block h-100 w-100 align-content-center text-center text-bold">
                          No Data
                        </span>
                      )}
                    />
                  </div>
                </Row>
              </div>
            )}
          </ToolkitProvider>
        )}
      </div>
    );
  };

  return tableOnly !== undefined && tableOnly === true ? (
    renderTable()
  ) : (
    <Card>
      {description && <Card.Header className="bg-white">{description}</Card.Header>}
      <Card.Body>{renderTable()}</Card.Body>
    </Card>
  );
});

export default BootstrapTablesWrapper;
