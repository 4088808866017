import React, { useState } from 'react';
import Select from 'react-select';

export const timeframeIntervalOptions = [
  { value: 'daily', label: 'Daily' },
  { value: 'wow', label: 'Weekly' },
  { value: 'mom', label: 'Monthly' },
  { value: 'quarter', label: 'Quarterly' },
  { value: 'annual', label: 'Yearly' },
];

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    ...(state.data.isLast ? {
      borderTop: '1px solid #d1d5db',
      color: '#2563eb',
      display: 'flex',
      justifyContent: 'space-between',
      width: '100%',
      alignItems: 'center',
    } : {}),
  }),
};

const DateTimeframeIntervalSelect = (props) => {
  const [timeframeInterval, setTimeframeInterval] = useState(timeframeIntervalOptions.filter((option) => option.value === props.value));
  const [modal, setModal] = useState(false);

  const onSelectChange = (option) => {
    setTimeframeInterval(option);
    props.onChange(option.value);
  };

  return (
    <>
      <Select
        styles={customStyles}
        className="text-dark w-100"
        placeholder="Select Interval"
        defaultValue={timeframeInterval}
        value={timeframeInterval}
        options={timeframeIntervalOptions}
        name="timeframeIntervalSelect"
        getOptionLabel={(option) => option.label}
        getOptionValue={(option) => option.value}
        setOptionValue
        onChange={onSelectChange}
        isMulti={false}
        allowSelectAll={false}
        isSearchable={false}
        isLoading={false}
        noOptionsMessage={(inputValue) => 'No Interval Available'}
      />
    </>
  );
};

export default DateTimeframeIntervalSelect;
