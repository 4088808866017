import React, { useEffect, useState, useContext } from "react";
import { Row, Col } from "react-bootstrap";
import moment from "moment";

import { snsPerformance, snsTimeframe, snsSubscriptionsPY } from "../../api/brandData";

import { AuthContext } from "../../contexts/AuthContext";
import { BrandContext } from "../../contexts/BrandContext";
import { HeaderContext } from "../../contexts/HeaderContext";
import { ToastContext } from "../../contexts/ToastContext";

import {
    yesterday,
    YTD_s
} from "../../components/helper/DateVars";
import { CurrencyFormatter, PercentFormatter, NumberFormatter } from "../../components/helper/IntlNumberFormatter";
import useIsMountedRef from "../../components/helper/isMountedRef";
import TemplateMetricsCard from "../../components/dataRender/dashboardCards/TemplateMetricsCard";
import SNSPerformanceTable from "../../components/dataRender/dashboardTables/SNSPerformanceTable";

import TextCard from "../BrandReport/widgets/TextCard";
import SNSPerformancePY from "../BrandReport/widgets/SNSPerformancePY";

const SNSPerformance = (props) => {
    // console.log("SNSPerformance", props);
    const { token, visibleMetrics } = useContext(AuthContext);
    const { brand } = useContext(BrandContext);
    const { toast } = useContext(ToastContext);
    const { setPageTitle, setPageTimeframe, setPageDisplayTimeFrameInterval, setPageMarketplace, currency, marketplace } = useContext(HeaderContext);

    const [pageDataLoading, setPageDataLoading] = useState(true);
    const isMountedRef = useIsMountedRef();

    const [snsPerformanceData, setSNSPerformanceData] = useState();
    const [snsTimeframeData, setSNSTimeframeData] = useState();
    const [snsSubscriptionsData, setSNSSubscriptionsData] = useState();

    const isLoading = () => {
        return (isMountedRef.current && pageDataLoading);
    }

    useEffect(() => {
        setPageTitle("Subscribe & Save Performance");
        setPageMarketplace('Amazon');
        setPageTimeframe("currency");
        setPageDisplayTimeFrameInterval(false);
        return () => {
            setPageTitle(process.env.REACT_APP_TITLE);
        };
    });

    useEffect(() => {
        setPageDataLoading(true);

        const getData = async () => {
            try {
                // Use Only BrandId, get latest
                const res = await snsPerformance(token, {
                    brandid: brand.brandId,
                    tf_s: moment(YTD_s).format('YYYY-MM-DD'),
                    tf_e: moment(yesterday).format('YYYY-MM-DD'),
                    wow: 'mom',
                    currency: currency,
                    marketplace: marketplace,
                });

                if (isMountedRef.current) {
                    setSNSPerformanceData(res?.data);
                }
                // console.log(res?.data);

            } catch (err) {
                toast(err.message);
            }

            try {
                // Use Only BrandId, get latest
                const res2 = await snsTimeframe(token, {
                    brandid: brand.brandId,
                    tf_s: moment(YTD_s).format('YYYY-MM-DD'),
                    tf_e: moment(yesterday).format('YYYY-MM-DD'),
                    wow: 'mom',
                    currency: currency,
                    marketplace: marketplace,
                });

                if (isMountedRef.current) {
                    setSNSTimeframeData(res2?.data);
                }
                // console.log(res2?.data);

            } catch (err) {
                toast(err.message);
            }

            try {
                // Use Only BrandId, get latest
                const res3 = await snsSubscriptionsPY(token, {
                    brandid: brand.brandId,
                    tf_s: moment(YTD_s).format('YYYY-MM-DD'),
                    tf_e: moment(yesterday).format('YYYY-MM-DD'),
                    wow: 'mom',
                    currency: currency,
                    marketplace: marketplace,
                });

                if (isMountedRef.current) {
                    setSNSSubscriptionsData(res3?.data);
                }
                // console.log(res3?.data);

            } catch (err) {
                toast(err.message);
            }
        };

        if (
            brand.brandId !== undefined
        ) {
            setSNSPerformanceData();
            setSNSTimeframeData();
            setSNSSubscriptionsData();
            getData();
            setPageDataLoading(false);
        }
    }, [token, brand, currency, marketplace, isMountedRef, toast]);

    const displayPrevious = (value) => {
        return [`${snsPerformanceData?.cardData?.pt_week_s} - ${snsPerformanceData?.cardData?.pt_week_e}: `, value];
    }

    const linePercentColorFormatter = (value) => {
        return <PercentFormatter
                    key={0}
                    displayDecimal={true}
                    replaceEmpty={true}
                    colorText={true}
                    colorBackground={true}
                    showChange={true}
                    displayArrow={true}
                    value={value}
                />
    };

    const lineIntFormatter = (value) => {
        return <NumberFormatter
                    key={0}
                    displayDecimal={false}
                    replaceEmpty={true}
                    value={value}
                />
    }

    const DollarFormatter = (value) => {
        return new Intl.NumberFormat(brand?.currencyLocale ?? "en-US", {
            style: "currency",
            currency: currency ?? 'USD',
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
        }).format(value);
    }
    const IntFormatter = (value) => {
        return new Intl.NumberFormat(brand?.currencyLocale ?? "en-US", {
            style: "decimal",
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
        }).format(value);
    }

    return (
        <main className="d-flex justify-content-start align-items-top">
            <Col>
                <Row className="mt-5">
                    <TemplateMetricsCard
                        visible={visibleMetrics.sns}
                        data={snsPerformanceData?.cardData}
                        dataMeta={{
                            'pt': {'pt_s': snsPerformanceData?.cardData?.pt_week_s ?? 'N/A', 'pt_e': snsPerformanceData?.cardData?.pt_week_e ?? 'N/A'},
                        }}
                        pageDataLoading={snsPerformanceData?.cardData && isLoading()}
                        textCardProps={{
                            headerTitle: (
                                <span>
                                    <h5 className="d-flex">4 Week Summary</h5>
                                    {snsPerformanceData?.cardData?.tf_week_s && (
                                        <span className="d-flex body-3">{snsPerformanceData?.cardData?.tf_week_s} - {snsPerformanceData?.cardData?.tf_week_e}</span>
                                    )}
                                </span>
                            ),
                            direction: "horizontal",
                            minHeight: "15em",
                        }}
                        metricsTemplate={[
                            {
                                name: 'Sales',
                                metric: 'tf_wk4_sales',
                                change_metric: 'wk4_sales_change_pct',
                                type: 'currency',
                                tooltip: 'Subscribe & Save revenue for the time frame',
                                visible: visibleMetrics.sns,
                            },
                            {
                                name: 'Units',
                                metric: 'tf_wk4_units',
                                change_metric: 'wk4_units_change_pct',
                                type: 'number',
                                tooltip: 'Subscribe & Save units shipped for the time frame',
                                visible: visibleMetrics.sns,
                            },
                            {
                                name: '+8WK',
                                metric: 'tf_wk8_units',
                                change_metric: 'wk8_units_change_pct',
                                type: 'number',
                                format: {
                                    previous: {
                                        decimal: true,
                                    }
                                },
                                tooltip: (
                                    <p>Estimated units to ship in the next 8 weeks, as of {snsPerformanceData?.cardData?.tf_week_e}</p>
                                ),
                                visible: visibleMetrics.sns,
                            },
                            {
                                name: 'Sub',
                                metric: 'tf_subscriptions',
                                change_metric: 'subscriptions_change',
                                type: 'number',
                                format: {
                                    previous: {
                                        show_change: true,
                                        display_arrow: true,
                                        decimal: false,
                                        type: 'number',
                                    }
                                },
                                tooltip: (
                                    // +x (# of new Subscription since the most recent report BEFORE the time frame) 
                                    // (mouseover: (New Subscription since $date)
                                    <div>
                                        <p>Total Subscription as of {snsPerformanceData?.cardData?.tf_week_e}</p>
                                        <p>{`${snsPerformanceData?.cardData?.previous_subscriptions_change} New Subscription since ${snsPerformanceData?.cardData?.pt_week_e}`}</p>
                                        <p className="mb-0"># of new Subscription since the most recent report BEFORE the time frame</p>
                                    </div>
                                ),
                                visible: visibleMetrics.sns,
                            },
                        ]}
                    />
                </Row>
                <Row className="mt-4">
                    <Col sm={4}>
                        <div style={{ height: "25em" }}>
                            <SNSPerformancePY
                                widgetID={'snsPerformanceRevenue'}
                                headerTitle="Shipped Revenue"
                                widgetData={[snsTimeframeData?.revenueChart, snsTimeframeData?.labels]}
                                widgetFormatter={DollarFormatter}
                                widgetDataLoading={isLoading()}
                            />
                        </div>
                    </Col>
                    <Col sm={4}>
                        <div style={{ height: "25em" }}>
                            <SNSPerformancePY
                                widgetID={'snsPerformanceUnits'}
                                headerTitle="Shipped Units"
                                widgetData={[snsTimeframeData?.unitsChart, snsTimeframeData?.labels]}
                                widgetFormatter={IntFormatter}
                                widgetDataLoading={isLoading()}
                            />
                        </div>
                    </Col>
                    <Col sm={4}>
                        <div style={{ height: "25em" }}>
                            <SNSPerformancePY
                                widgetID={'snsPerformanceSubscriptions'}
                                headerTitle="Subscription"
                                widgetData={[snsSubscriptionsData?.subscriptionsChart, snsSubscriptionsData?.labels]}
                                widgetFormatter={IntFormatter}
                                widgetDataLoading={isLoading()}
                            />
                        </div>
                    </Col>
                </Row>
                <Row className="my-4 pb-4">
                    <Col>
                        <SNSPerformanceTable
                            widgetData={snsPerformanceData?.dataTable}
                            widgetDataLoading={isLoading()}
                        />
                    </Col>
                </Row>
            </Col>
        </main>
    );
};

export default SNSPerformance;
