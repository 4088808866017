import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Button, Spinner } from 'react-bootstrap';

import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { createStaticRanges, DateRangePicker } from 'react-date-range';
import { format, isFuture, parse, subDays, subMonths, subYears } from 'date-fns';
import { enUS } from 'date-fns/locale';
import moment from 'moment';

import { HeaderContext } from '../../contexts/HeaderContext';
import { AuthContext } from '../../contexts/AuthContext';
import { faGear } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ModalFiscal } from './ModalFiscal';

const DateRangeSelect = (props) => {

  const { dataLoading } = useContext(HeaderContext);
  const { apiUser } = useContext(AuthContext);
  const [modal, setModal] = useState(false);

  const {
    id,
    startDate,
    endDate,
    onSelectDate,
  } = props;

  const formatCalDate = (date) => {
    return (
      date instanceof moment ?
        date.toDate() : moment(date).toDate()
    );
  };

  const [calDate, setCalDate] = useState([{
    startDate: formatCalDate(startDate),
    endDate: formatCalDate(endDate),
    key: 'selection',
  }]);

  const [calPreview, setCalPreview] = useState({
    startDate: formatCalDate(startDate),
    endDate: formatCalDate(endDate),
  });


  const staticRanges = useMemo(() => {
    let dateStart = parse(`${apiUser?.settings?.global?.['fiscal-year-start'] || 'January'}/01/${new Date().getFullYear()}`, 'MMMM/dd/yyyy', new Date());
    if (isFuture(dateStart)) {
      dateStart = subYears(dateStart, 1);
    }
    return createStaticRanges([
      {
        label: 'Month to Date',
        range: () => ({
          startDate: new Date().setDate(1),
          endDate: subDays(new Date(), 1),
        }),
      },
      {
        label: 'Last 30 Days',
        range: () => ({
          startDate: subDays(new Date(), 30),
          endDate: subDays(new Date(), 1),
        }),
      },
      {
        label: 'Last Month',
        range: () => ({
          startDate: subMonths(new Date(), 1).setDate(1),
          endDate: new Date().setDate(0),
        }),
      },
      {
        label: 'Last 3 Months',
        range: () => ({
          startDate: subMonths(new Date(), 4),
          endDate: subDays(new Date(), 1),
        }),
      },
      {
        label: 'Last 6 Months',
        range: () => ({
          startDate: subMonths(new Date(), 7),
          endDate: subDays(new Date(), 1),
        }),
      },
      {
        label: 'Last Year',
        range: () => ({
          startDate: new Date().setFullYear(new Date().getFullYear() - 1, 0, 1),
          endDate: new Date().setFullYear(new Date().getFullYear() - 1, 11, 31),
        }),
      },
      {
        label: 'Year to Date',
        range: () => ({
          startDate: new Date().setMonth(0, 1),
          endDate: subDays(new Date(), 1),
        }),
      },
      {
        label: <div className={'d-flex justify-content-between align-items-center'}>
          <span>Fiscal Year to Date ({format(dateStart, 'MMMM/yyyy')})</span>
          <Button variant={'outline-light'} size={'sm'} onClick={(e) => {
            setModal(true);
            e.stopPropagation();
          }}>
            <FontAwesomeIcon style={{ color: '#a9a9aa' }} icon={faGear} />
          </Button>
        </div>,
        range: () => ({
          startDate: dateStart,
          endDate: subDays(new Date(), 1),
        }),
      },
      {
        label: 'Account Lifetime',
        range: () => ({
          startDate: new Date(2015, 0, 1),
          endDate: subDays(new Date(), 1),
        }),
      },
    ]);
  }, [apiUser]);

  useEffect(() => {
    setCalDate([{
      startDate: formatCalDate(startDate),
      endDate: formatCalDate(endDate),
      key: 'selection',
    }]);

    setCalPreview({
      startDate: formatCalDate(startDate),
      endDate: formatCalDate(endDate),
    });

  }, [startDate, endDate]);

  const handleSelect = (ranges) => {
    setCalDate([{
      startDate: formatCalDate(ranges.selection.startDate),
      endDate: formatCalDate(ranges.selection.endDate),
      key: 'selection',
    }]);
  };

  /**
   * Fixes => Warning: Failed prop type: Invalid prop `preview.startDate` of type `number` supplied to `Calendar`, expected `object`.
   * @see DateRangePickerProps
   *  */
  const handlePreviewChange = (preview) => {
    if (preview?.startDate && preview?.endDate) {
      preview.startDate = formatCalDate(preview.startDate);
      preview.endDate = formatCalDate(preview.endDate);
    } else if (preview instanceof Date) {
      // Preview Start Selected when start/end date are same. Use calDate
      const previewStart = (
        calDate[0].startDate && calDate[0].endDate
        && calDate[0].startDate.getTime() === calDate[0].endDate.getTime()
      ) ? calDate[0].startDate : preview;

      const previewEnd = preview?.endDate ?? preview;

      preview.startDate = formatCalDate((previewStart.getTime() < previewEnd.getTime()) ? previewStart : previewEnd);
      preview.endDate = formatCalDate((previewStart.getTime() > previewEnd.getTime()) ? previewStart : previewEnd);
    }

    // console.log("handlePreviewChange", preview);

    setCalPreview(preview);
  };

  const clearFilter = () => {
    setCalDate([
      {
        startDate: null,
        endDate: new Date(''),
        key: 'selection',
      },
    ]);
  };

  const setFilter = () => {
    onSelectDate(calDate);
  };

  const applyButton = () => {
    return (
      <Button
        className="btn btn-info bg-accent-default btn-primary"
        onClick={() => setFilter()}
        disabled={dataLoading}
      >
        {dataLoading ? (
          <Spinner as="span" animation="grow" size="sm" role="status" aria-hidden="true"></Spinner>
        ) : (
          <span>Apply Time Range</span>
        )}
      </Button>
    );
  };

  // console.log("DateRangeSelect", id, calDate, calPreview);

  return (
    <div>
      <div className="btn-filter-container">
        <div className="filter-button-group">
          <button className="btn btn-info bg-accent-default btn-primary" onClick={clearFilter}>Clear Filter
          </button>
          {applyButton()}
        </div>
      </div>
      {modal && <ModalFiscal onClose={() => setModal(false)} />}
      <DateRangePicker
        onPreviewChange={handlePreviewChange}
        preview={calPreview}
        onChange={handleSelect}
        locale={enUS}
        months={2}
        direction="horizontal"
        maxDate={new Date()}
        ranges={calDate}
        // focusedRange={[1, 0]}
        staticRanges={staticRanges}
        inputRanges={[]}
        calendarFocus="backwards"
        showDateDisplay={false}
        preventSnapRefocus={true}
        // input={true}
      />
    </div>
  );
};

export default DateRangeSelect;
