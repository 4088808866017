import React, { useEffect, useState, useContext } from "react";
import { Row, Col, Form, InputGroup } from "react-bootstrap";
import moment from "moment";

import { productsXcampaign } from "../../api/ppc";

import { AuthContext } from "../../contexts/AuthContext";
import { BrandContext } from "../../contexts/BrandContext";
import { HeaderContext } from "../../contexts/HeaderContext";
import { ToastContext } from "../../contexts/ToastContext";

import Loading from "../../components/helper/Loading";
import { CurrencyFormatter, PercentFormatter } from "../../components/helper/IntlNumberFormatter";

import BootstrapTablesWrapper from "../../components/dataRender/BootstrapTablesWrapper";


const ProductsxCampaign = (props) => {
    //Grabs user data from AuthContext.js
    const { token } = useContext(AuthContext);
    const { brand } = useContext(BrandContext);
    const { toast } = useContext(ToastContext);
    const { setPageTitle, setPageTimeframe, setPageDisplayTimeFrameInterval, setPageMarketplace, selectedDefaultDates, currency, marketplace } = useContext(HeaderContext);

    const [dataLoading, setDataLoading] = useState(true);
    const [dollarOrPercent, setDollarOrPercent] = useState("$");
    const [data, setData] = useState([]);

    const linePercentColorFormatter = (value) => {
        return <PercentFormatter
                    key={0}
                    displayDecimal={true}
                    replaceEmpty={false}
                    colorText={false}
                    colorBackground={false}
                    showChange={false}
                    displayArrow={false}
                    value={value}
                />
    };
    const lineDollarFormatter = (value) => {
        return <CurrencyFormatter
            value={value}
            replaceEmpty={false}
            displayDecimal={false}
        />
    }

    const columns = [
        {
            dataField: "row",
            text: "#",
            type: "number",
            sort: false,
            hidden: true,
        },
        {
            dataField: "vpart",
            text: "Product",
            type: "string",
            sort: true,
            classes: "font-weight-bold",
        },
        {
            dataField: "asin",
            text: "ASIN",
            type: "string",
            sort: true,
            classes: "font-weight-bold",
        },
        {
            dataField: "short_title",
            text: "Title",
            type: "string",
            sort: true,
            classes: "font-weight-bold",
        },
        {
            text: "Performance Sales %",
            dataField: "SP Performance Sales %",
            sort: true,
            sortValue: (cell, row) => { return cell },
            type: "number",
            hidden: dollarOrPercent === "$" ? true : false,
            formatter: (cell, row, rowIndex) => {
                if (!cell) cell = 0;
                if (cell > row["SP Defensive Sales %"] && cell > row["SP Research Sales %"]) {
                    return <span className="text-primary">{linePercentColorFormatter(cell)}</span>;
                } else {
                    return linePercentColorFormatter(cell);
                }
            },
        },
        {
            text: "Performance Spend %",
            dataField: "SP Performance Spend %",
            sort: true,
            sortValue: (cell, row) => { return cell },
            type: "number",
            hidden: dollarOrPercent === "$" ? true : false,
            formatter: (cell, row, rowIndex) => {
                if (!cell) cell = 0;
                if (cell > row["SP Defensive Spend %"] && cell > row["SP Research Spend %"]) {
                    return <span className="text-primary">{linePercentColorFormatter(cell)}</span>;
                } else {
                    return linePercentColorFormatter(cell);
                }
            },
        },
        {
            text: "Defensive Sales %",
            dataField: "SP Defensive Sales %",
            sort: true,
            sortValue: (cell, row) => { return cell },
            type: "number",
            hidden: dollarOrPercent === "$" ? true : false,
            formatter: (cell, row, rowIndex) => {
                if (!cell) cell = 0;
                if (cell > row["SP Performance Sales %"] && cell > row["SP Research Sales %"]) {
                    return <span className="text-primary">{linePercentColorFormatter(cell)}</span>;
                } else {
                    return linePercentColorFormatter(cell);
                }
            },
        },
        {
            text: "Defensive Spend %",
            dataField: "SP Defensive Spend %",
            sort: true,
            sortValue: (cell, row) => { return cell },
            type: "number",
            hidden: dollarOrPercent === "$" ? true : false,
            formatter: (cell, row, rowIndex) => {
                if (!cell) cell = 0;
                if (cell > row["SP Performance Spend %"] && cell > row["SP Research Spend %"]) {
                    return <span className="text-primary">{linePercentColorFormatter(cell)}</span>;
                } else {
                    return linePercentColorFormatter(cell);
                }
            },
        },
        {
            text: "Research Sales %",
            dataField: "SP Research Sales %",
            sort: true,
            sortValue: (cell, row) => { return cell },
            type: "number",
            hidden: dollarOrPercent === "$" ? true : false,
            formatter: (cell, row, rowIndex) => {
                if (!cell) cell = 0;
                if (cell > row["SP Defensive Sales %"] && cell > row["SP Performance Sales %"]) {
                    return <span className="text-primary">{linePercentColorFormatter(cell)}</span>;
                } else {
                    return linePercentColorFormatter(cell);
                }
            },
        },
        {
            text: "Research Spend %",
            dataField: "SP Research Spend %",
            sort: true,
            sortValue: (cell, row) => { return cell },
            type: "number",
            hidden: dollarOrPercent === "$" ? true : false,
            formatter: (cell, row, rowIndex) => {
                if (!cell) cell = 0;
                if (cell > row["SP Defensive Spend %"] && cell > row["SP Performance Spend %"]) {
                    return <span className="text-primary">{linePercentColorFormatter(cell)}</span>;
                } else {
                    return linePercentColorFormatter(cell);
                }
            },
        },
        {
            text: "Performance Sales",
            dataField: "SP Performance Sales",
            sort: true,
            sortValue: (cell, row) => { return cell },
            type: "number",
            hidden: dollarOrPercent === "%" ? true : false,
            formatter: (cell, row, rowIndex) => {
                if (!cell) cell = 0;
                if (cell > row["SP Defensive Sales"] && cell > row["SP Research Sales"]) {
                    return <span className="text-primary">{lineDollarFormatter(cell)}</span>;
                } else {
                    return lineDollarFormatter(cell);
                }
            },
        },
        {
            text: "Performance Spend",
            dataField: "SP Performance Spend",
            sort: true,
            sortValue: (cell, row) => { return cell },
            type: "number",
            hidden: dollarOrPercent === "%" ? true : false,
            formatter: (cell, row, rowIndex) => {
                if (!cell) cell = 0;
                if (cell > row["SP Defensive Spend"] && cell > row["SP Research Spend"]) {
                    return <span className="text-primary">{lineDollarFormatter(cell)}</span>;
                } else {
                    return lineDollarFormatter(cell);
                }
            },
        },
        {
            text: "Defensive Sales",
            dataField: "SP Defensive Sales",
            sort: true,
            sortValue: (cell, row) => { return cell },
            type: "number",
            hidden: dollarOrPercent === "%" ? true : false,
            formatter: (cell, row, rowIndex) => {
                if (!cell) cell = 0;
                if (cell > row["SP Performance Sales"] && cell > row["SP Research Sales"]) {
                    return <span className="text-primary">{lineDollarFormatter(cell)}</span>;
                } else {
                    return lineDollarFormatter(cell);
                }
            },
        },
        {
            text: "Defensive Spend",
            dataField: "SP Defensive Spend",
            sort: true,
            sortValue: (cell, row) => { return cell },
            type: "number",
            hidden: dollarOrPercent === "%" ? true : false,
            formatter: (cell, row, rowIndex) => {
                if (!cell) cell = 0;
                if (cell > row["SP Performance Spend"] && cell > row["SP Research Spend"]) {
                    return <span className="text-primary">{lineDollarFormatter(cell)}</span>;
                } else {
                    return lineDollarFormatter(cell);
                }
            },
        },
        {
            text: "Research Sales",
            dataField: "SP Research Sales",
            sort: true,
            sortValue: (cell, row) => { return cell },
            type: "number",
            hidden: dollarOrPercent === "%" ? true : false,
            formatter: (cell, row, rowIndex) => {
                if (!cell) cell = 0;
                if (cell > row["SP Defensive Sales"] && cell > row["SP Performance Sales"]) {
                    return <span className="text-primary">{lineDollarFormatter(cell)}</span>;
                } else {
                    return lineDollarFormatter(cell);
                }
            },
        },
        {
            text: "Research Spend",
            dataField: "SP Research Spend",
            sort: true,
            sortValue: (cell, row) => { return cell },
            type: "number",
            hidden: dollarOrPercent === "%" ? true : false,
            formatter: (cell, row, rowIndex) => {
                if (!cell) cell = 0;
                if (cell > row["SP Defensive Spend"] && cell > row["SP Performance Spend"]) {
                    return <span className="text-primary">{lineDollarFormatter(cell)}</span>;
                } else {
                    return lineDollarFormatter(cell);
                }
            },
        },
    ];

    /**
     * useEffect with no second arguments = Run on every render
     *
     * Return statement is the cleanup function, runs when the component unmounts (Usually used to cleanup. Here it is used to clean up the page title)
     */
    useEffect(() => {
        setPageTitle("Products X Campaign");
        setPageMarketplace('Amazon');
        setPageTimeframe("snapshot");
        setPageDisplayTimeFrameInterval(false);
        return () => {
            setPageTitle(process.env.REACT_APP_TITLE);
        };
    });

    /**
     * useEffect with empty array as second argument = Run only once (on the first render)
     */
    useEffect(() => {
        const getData = async () => {
            setDataLoading(true);
            try {
                const res = await productsXcampaign(token, {
                    brandid: brand.brandId,
                    tf_s: moment(selectedDefaultDates.tp_s).format('YYYY-MM-DD'),
                    tf_e: moment(selectedDefaultDates.tp_e).format('YYYY-MM-DD'),
                    currency: currency,
                    marketplace: marketplace,
                });

                if (res.data.length === 0) {
                    toast("No data found");
                }
                
                setData(res.data);
                setDataLoading(false);
            } catch (err) {
                setDataLoading(false);
                toast(err.message);
            }
        };

        if (
            brand.brandId !== undefined &&
            selectedDefaultDates.timeframe !== undefined &&
            selectedDefaultDates.tf_s !== undefined &&
            selectedDefaultDates.tf_e !== undefined
        ) {
            getData();
        }
    }, [token, brand, selectedDefaultDates, currency, toast, marketplace]);

    const renderChart = () => {
        return (
            <BootstrapTablesWrapper
                data={data}
                keyField="row"
                hasPagination={false}
                hasFilter={true}
                defaultSorted={
                    dollarOrPercent === "%" ? [{
                        dataField: "SP Performance Sales %",
                        order: "desc",
                    }]
                    :
                    [{
                        dataField: "SP Performance Sales",
                        order: "desc",
                    }]
                }
                additionalButtons={
                    <InputGroup className="ml-2 mr-2" style={{ width: "5em" }}>
                        <Form.Control
                            as="select"
                            value={dollarOrPercent}
                            onChange={(e) => {
                                setDollarOrPercent(e.target.value);
                            }}
                        >
                            <option value="$">$</option>
                            <option value="%">%</option>
                        </Form.Control>
                    </InputGroup>
                }
                columns={columns}
            />
        );
    };

    return (
        <main>
            <div className="container-fluid">
                <Row className="mt-4">
                    <Col>
                        <div className="card h-100" /*style={{ overflow: "auto" }}*/>
                            <div className="card-header white bg-white bg-white">
                                <div className="d-flex flex-row align-items-center">
                                    <div>
                                        <p className="text-uppercase small mb-1" style={{ fontSize: "16px" }}>
                                            <strong>{"Products x Campaign"}</strong>
                                        </p>
                                        <p className="mb-0">
                                            {
                                                "Sponsored Product campaign sales at the product level broken out by the percentage that each campaign strategy drove."
                                            }
                                        </p>
                                    </div>
                                    <div className="ml-auto "></div>
                                </div>
                            </div>

                            <div className="card-body w-100 pt-0 pb-0">{dataLoading ? <Loading /> : renderChart()}</div>
                        </div>
                    </Col>
                </Row>
            </div>
        </main>
    );
};

export default ProductsxCampaign;
