import React, { useContext, useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import { ResponsiveContainer } from "recharts";
import Chart from "react-apexcharts";
import moment from "moment";

import { buyboxAsins } from "../../../api/reseller";

import { AuthContext } from "../../../contexts/AuthContext";
import { BrandContext } from "../../../contexts/BrandContext";
import { HeaderContext } from "../../../contexts/HeaderContext";
import { ToastContext } from "../../../contexts/ToastContext";

import { graph_blue_light } from "../../../components/helper/ColorFormatter";
import WidgetSpinner from "../../../components/helper/WidgetSpinner";
import { CurrencyFormatter, PercentFormatter } from "../../../components/helper/IntlNumberFormatter";
import ProductImageThumbnail from "../../../components/helper/productImageThumbnail";

import BootstrapTablesWrapper from "../../../components/dataRender/BootstrapTablesWrapper";

function BuyboxTable(props) {
    const { token } = useContext(AuthContext);
    const { selectedDefaultDates, dataLoading, currency, marketplace } = useContext(HeaderContext);

    const [data, setData] = useState({});
    const [widgetLoading, setWidgetLoading] = useState(true);

    const [widgetOptions, setWidgetOptions] = useState({
        headerTitle: props.widgetTitle ?? props.headerTitle ?? "Buybox Performance by Product",
        headerSubtitle: "",
        showConfigButton: true,
        snapshotTime: false,
        ...props,
    });

    const { brand } = useContext(BrandContext);
    const { toast } = useContext(ToastContext);

    useEffect(() => {
        const getData = async () => {
            setWidgetLoading(true);
            try {
                const res = await buyboxAsins(token, {
                    brandid: brand.brandId,
                    tf_s: widgetOptions.snapshotTime ? moment(selectedDefaultDates.tp_s).format("YYYY-MM-DD") : moment(selectedDefaultDates.tf_s).format("YYYY-MM-DD"),
                    tf_e: widgetOptions.snapshotTime ? moment(selectedDefaultDates.tp_e).format("YYYY-MM-DD") : moment(selectedDefaultDates.tf_e).format("YYYY-MM-DD"),
                    timeframe: selectedDefaultDates.timeframe,
                    currency: currency,
                    marketplace: marketplace,
                });

                setData(res.data);
                setWidgetLoading(false);

                if (res.data?.snapshot?.length === 0) {
                    toast("No data Buybox Performance by Product");
                }

            } catch (err) {
                setWidgetLoading(false);
                toast(err.message);
            }
        };

        if (!dataLoading) {
            getData();
        }
    }, [token, brand.brandId, setData, toast, dataLoading, selectedDefaultDates, currency, marketplace]);

    const renderSparkline = (asin) => {
        let dates = [];
        let seriesData = [];

        data.timeline[asin].forEach((ele) => {
            if (ele.expression) {
                dates.push(ele.db_date);
                seriesData.push(parseInt(ele.expression));
            }
        });

        let sparklineOptions = {
            chart: {
                type: "area",
                sparkline: {
                    enabled: true,
                },
                animations: {
                    enabled: false,
                },
                width: "100%",
            },
            fill: {
                opacity: 0.3,
            },
            xaxis: {
                categories: dates,
                type: "category",
                show: false,
            },
            colors: [graph_blue_light],
            stroke: {
                width: 2,
            },
        };

        let series = [
            {
                name: "Buybox",
                data: seriesData,
                type: "line",
                show: true,
            },
        ];
        return (
            <ResponsiveContainer>
                <Chart options={sparklineOptions} series={series} type="area" />
            </ResponsiveContainer>
        );
    };

    const asinColumns = [
        {
            text: "Sales Channel",
            dataField: "salesChannel",
            sort: true,
            hidden: true,
        },
        {
            text: "ASIN",
            dataField: "ASIN",
            sort: true,
            sortValue: (cell, row) => row["ASIN"],
            formatter: (cell, row, rowIndex) => {
                return (
                    <div className="d-flex flex-row flex-nowrap align-items-center">
                        <ProductImageThumbnail
                            imageSrc={row['Image']}
                            imageLink={`https://www.${row["salesChannel"]}/gp/offer-listing/${row["ASIN"]}`}
                            imageAlt={row["ASIN"]}
                        />
                        <div className="d-flex flex-column" style={{ maxWidth: "60em" }}>
                            <p style={{ maxWidth: "60em" }}>{row["Title"]}</p>
                            <div className="d-flex flex-row">
                                <a
                                    href={`https://www.${row["salesChannel"]}/gp/offer-listing/${cell}`}
                                    target={`_${cell}`}
                                    className="text-link"
                                >
                                    {cell}
                                </a>
                            </div>
                        </div>
                    </div>
                );
            },
        },
        {
            text: "Product Sales",
            dataField: "Product Sales",
            sort: true,
            sortValue: (cell, row) => parseFloat(row["Product Sales"]),
            formatter: (cell, row, rowIndex) => {
                if (!cell) cell = 0;
                return <CurrencyFormatter value={cell} />;
            },
        },
        {
            text: "Buybox Percentage",
            dataField: "Buybox Percentage",
            sort: true,
            sortValue: (cell, row) => parseFloat(row["Buybox Percentage"]),
            formatter: (cell, row, rowIndex) => {
                let parsed = parseFloat(cell).toFixed(2);

                if (parsed !== "NaN") {
                    let color = "text-enalytics-blue-light";

                    if (parsed >= 33.33 && parsed <= 66.66) {
                        color = "text-warning";
                    } else if (parsed <= 33.33) {
                        color = "text-danger";
                    }
                    return <span className={`${color}`}>{<PercentFormatter key={rowIndex}
                                                                           value={parsed / 100} />}</span>;
                } else {
                    return "-";
                }
            },
        },
        {
            text: "Sparkline",
            dataField: "row",
            sort: false,
            formatter: (cell, row, rowIndex) => {
                return <div style={{ height: "50px", minWidth: "300px" }}>{renderSparkline(row["ASIN"])}</div>;
            },
        },
    ];

    const renderTable = () => {
        return (
            <BootstrapTablesWrapper
                data={data.snapshot}
                keyField="row" // Just asin gives duplicate key error with sparkline
                tableOnly={true}
                hasPagination={true}
                sizePerPage={20}
                hasFilter={true}
                columns={asinColumns}
                defaultSorted={[
                    {
                        dataField: "Product Sales",
                        order: "desc",
                    },
                ]}
            />
        );
    };

    return (
        <Card className="h-100">
            <Card.Header className="bg-white">
                <div className="d-flex flex-row align-items-center">
                    <div>
                        <h5>{props.headerTitle}</h5>
                        {props.headerSubtitle !== "" ? (
                            <p className="body-1 text-secondary mb-0">{props.headerSubtitle}</p>
                        ) : (
                            ""
                        )}
                    </div>
                </div>
            </Card.Header>

            <Card.Body className="pb-0" style={{ minHeight: props.minHeight ?? "500px" }}>
                {widgetLoading ? (
                    <div className="w-100 h-100">
                        <WidgetSpinner />
                    </div>
                ) : (
                    <div className="w-100">{renderTable()}</div>
                )}
            </Card.Body>
        </Card>
    );
}

export default BuyboxTable;
