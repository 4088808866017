import React, { createContext, useState, useEffect } from 'react';
import moment from 'moment-timezone';
import localforage from 'localforage';

import { today, yesterday, YTD_s, MTD_s } from '../components/helper/DateVars';
import { timeframeIntervalOptions } from '../components/header/DateTimeframeIntervalSelect';

export const HeaderContext = createContext({});

const verifyDates = (dates, dateCheck = false) => {
  const requiredKeys = ['timeframe', 'tf_s', 'tf_e', 'tp_s', 'tp_e'];

  if (!dates) return false;

  for (let i = 0; i < requiredKeys.length; i++) {
    if (!(requiredKeys[i] in dates)) {
      return false;
    }
    if (dates[requiredKeys[i]] === null || dates[requiredKeys[i]] === undefined) {
      return false;
    }
  }

  if (dateCheck) {
    return (
      dates.tf_s <= dates.tf_e &&
      dates.tp_s <= dates.tp_e &&
      dates.tf_s <= today &&
      dates.tp_s <= today
    );
  }

  return true;
};

const validateTimeframe = (timeframe) => {
  const validate = timeframeIntervalOptions.filter((options) => options.value === timeframe);
  return validate?.length > 0;
};

const defaultTimeFrameInterval = 'mom';

const getDefaultDates = () => {
  return {
    tf_s: YTD_s, // YTD - Monthly = New Default
    tf_e: yesterday, // 1 day ago
    tp_s: MTD_s, // MTD
    tp_e: yesterday,
    timeframe: defaultTimeFrameInterval,
  };
};

const HeaderProvider = ({ children }) => {
  const [pageTitle, setPageTitle] = useState(process.env.REACT_APP_TITLE);

  const [timeFrameInterval, setTimeFrameInterval] = useState(defaultTimeFrameInterval); // Default
  const [displayTimeFrameInterval, setPageDisplayTimeFrameInterval] = useState(false); // Default

  const [currency, setCurrency] = useState('USD');

  const [marketplace, setMarketplace] = useState('All');
  const [pageMarketplace, setPageMarketplace] = useState(true);
  const [pageComparativeSelector, setPageComparativeSelector] = useState(false); // Default

  const [weekstart, setWeekstart] = useState('Sunday');

  const [timezone, setTimezone] = useState(moment.tz.guess());

  const [selectedDefaultDates, setSelectedDefaultDates] = useState({});

  const [selectedPreviousCustomDates, setSelectedPreviousCustomDates] = useState({
    pc_s: moment().format('YYYY-MM-DD'),
    pc_e: moment().format('YYYY-MM-DD'),
  });

  const [pageTimeframe, setPageTimeframe] = useState('timeframe');

  const [init, setInit] = useState(false);

  const [comparativeOptions, setComparativeOptions] = useState(() => {
    return [];
  });
  const [comparativeValue, setComparativeValue] = useState(() => {
    return { value: 'py', label: 'Previous Year' };
  });

  useEffect(() => {
    const init = async () => {
      let defaultCurrency = await localforage.getItem('fortress-last-selected-currency');

      let defaultMarketplace = await localforage.getItem('fortress-last-selected-marketplaces');

      let defaultWeekstart = await localforage.getItem('fortress-last-selected-weekstart');

      let timeframe = await localforage.getItem('fortress-last-selected-timeFrameInterval');

      let defaultDates = getDefaultDates();

      if (!defaultCurrency) {
        defaultCurrency = 'USD';
      }

      if (!defaultMarketplace) {
        defaultMarketplace = ['All'];
      } else if (typeof defaultMarketplace === 'string') {
        // Multi-Select
        defaultMarketplace = [defaultMarketplace];
      }

      if (!defaultWeekstart) {
        defaultWeekstart = 'Sunday';
      }

      if (validateTimeframe(timeframe)) {
        defaultDates.timeframe = timeframe;
      }

      setSelectedDefaultDates(defaultDates);
      setCurrency(defaultCurrency);
      setMarketplace(defaultMarketplace);
      setPageMarketplace(true);
      setTimeFrameInterval(timeframe);

      setInit(true);
    };
    init();
  }, []);

  useEffect(() => {
    if (init && currency) {
      localforage.setItem('fortress-last-selected-currency', currency);
    }
  }, [currency, init]);

  useEffect(() => {
    if (init && marketplace) {
      localforage.setItem('fortress-last-selected-marketplaces', marketplace);
    }
  }, [marketplace, init]);

  useEffect(() => {
    if (init && weekstart) {
      localforage.setItem('fortress-last-selected-weekstart', weekstart);
    }
  }, [weekstart, init]);

  useEffect(() => {
    if (init && timeFrameInterval) {
      localforage.setItem('fortress-last-selected-timeFrameInterval', timeFrameInterval);
    }
  }, [timeFrameInterval, init]);

  const setDates = (dates) => {
    if (verifyDates(dates)) {
      setSelectedDefaultDates(dates);
    }
  };

  const toMomentDateStr = (date) => {
    return moment(date).format('YYYY-MM-DD');
  };

  /**
   * 
   * @param {string} extension                File extension to be used
   * @param {?string} filename                Filename to be used, if not provided, pageTitle will be used
   * @param {?string} prepend                 Prefix to be used
   * @param {?string} suffix                  Suffix to be used, usually the source of the data (widget, report, etc)
   * @param {?boolean} useTimeFrameInterval   Whether to include the timeframe interval in the filename
   * 
   * Outputs: Normalized to lowercase & underscored, removes special characters
   * `{prefix}_{filename}_{suffix}_{timeframe}_{date}.csv`
   */
  const generateDownloadFilename = ({extension, filename = undefined, prepend = undefined, suffix = undefined, useTimeFrameInterval = true}) => {
    if (!filename) {
      filename = pageTitle;
    }
    
    let filename_date = moment().format('YYYYMMDD_HHmmss');
    if (pageTimeframe === 'snapshot') {
      filename_date = moment(selectedDefaultDates.tp_s).format('YYYYMMDD') + '_' + moment(selectedDefaultDates.tp_e).format('YYYYMMDD');
    }
    else {
      filename_date = moment(selectedDefaultDates.tf_s).format('YYYYMMDD') + '_' + moment(selectedDefaultDates.tf_e).format('YYYYMMDD');
    }

    let timeframe = '';
    if (useTimeFrameInterval && displayTimeFrameInterval) {
      timeframe = selectedDefaultDates.timeframe;
    }

    prepend = prepend ? prepend + '_' : '';
    suffix = suffix ? '_' + suffix : '';
    extension = extension.replace('.');

    filename = `${prepend}${filename}${suffix}_${timeframe}_${filename_date}`;

    return filename.toLowerCase().replace(/[^a-z0-9_]/g, '_') + '.' + extension;
  }

  return (
    <HeaderContext.Provider
      value={{
        init,
        pageTitle,
        setPageTitle,
        timeFrameInterval,
        setTimeFrameInterval,
        displayTimeFrameInterval,
        setPageDisplayTimeFrameInterval,
        pageMarketplace,
        setPageMarketplace,
        pageComparativeSelector,
        setPageComparativeSelector,
        currency,
        setCurrency,
        pageTimeframe,
        setPageTimeframe,
        selectedDefaultDates,
        selectedPreviousCustomDates,
        setSelectedPreviousCustomDates,
        setDates,
        getDefaultDates,
        verifyDates,
        marketplace,
        setMarketplace,
        timezone,
        setTimezone,
        weekstart,
        setWeekstart,
        comparativeOptions,
        setComparativeOptions,
        comparativeValue,
        setComparativeValue,

        // helpers
        toMomentDateStr,
        generateDownloadFilename,
      }}
    >
      {children}
    </HeaderContext.Provider>
  );
};

export default HeaderProvider;
