import React, { useEffect, useState, useContext } from "react";
import { Row, Col } from "react-bootstrap";
import moment from "moment";

import { AuthContext } from "../../contexts/AuthContext";
import { BrandContext } from "../../contexts/BrandContext";
import { HeaderContext } from "../../contexts/HeaderContext";

import SalesChannel from "../BrandReport/widgets/SalesChannel";
import SubscriberCount from "../BrandReport/widgets/SubscriberCount";
import TopSNS from "../BrandReport/widgets/TopSNS";

import Loading from "../../components/helper/Loading";
import DataError from "../../components/helper/DataError";
import useIsMountedRef from "../../components/helper/isMountedRef";
import ChartDescription from "../../components/helper/ChartDescription";
import BootstrapTablesWrapper from "../../components/dataRender/BootstrapTablesWrapper";

import { timelineOverview } from "../../api/brandData";
import TopProducts from "../BrandReport/widgets/TopProducts";

const BrandTimeline = (props) => {
    // console.log('BrandTimeline', props);

    const { token } = useContext(AuthContext);
    const { brand } = useContext(BrandContext);
    const { 
        setPageTitle,
        setPageTimeframe,
        setPageDisplayTimeFrameInterval,
        selectedDefaultDates,
        currency,
        marketplace,
    } = useContext(HeaderContext);

    const [pageDataLoading, setPageDataLoading] = useState(true);

    const [data, setData] = useState(props.data);
    const [[dataError, errorStatus, errorMessage, errorResponse], setDataError] = useState([false, "", "", ""]);
    const isMountedRef = useIsMountedRef();

    useEffect(() => {
        setPageTitle("Timeline Overview");
        setPageTimeframe("timeframe");
        setPageDisplayTimeFrameInterval(true);
        return () => {
            setPageTitle(process.env.REACT_APP_TITLE);
        };
    });

    useEffect(() => {
        const getData = async () => {
            setPageDataLoading(true);
            setDataError([false, "", "", ""]);
            try {
                const res = await timelineOverview(token, {
                    brandid: brand.brandId,
                    tf_s: moment(selectedDefaultDates.tf_s).format('YYYY-MM-DD'),
                    tf_e: moment(selectedDefaultDates.tf_e).format('YYYY-MM-DD'),
                    wow: selectedDefaultDates.timeframe,
                    currency: currency,
                    marketplace: marketplace,
                });

                if (isMountedRef.current) {
                    setPageDataLoading(false);
                    setData(res.data);
                }
                // console.log("BrandTimeline Data", res.data);
            } catch (err) {
                setPageDataLoading(false);
                setDataError([true, err.response?.status, err.response?.statusText, err.response?.data]);
            }
        };

        if (brand.brandId !== undefined && selectedDefaultDates.timeframe !== undefined) {
            getData();
        } else {
        }
    }, [token, brand, selectedDefaultDates, currency, marketplace, isMountedRef]);

    return pageDataLoading === true ? (
        <Loading></Loading>
    ) : dataError === true ? (
        <DataError showFull="true" status={errorStatus} message={errorMessage} response={errorResponse}></DataError>
    ) : (
        <main className="d-flex justify-content-start align-items-top">
            <Col>
                <Row className="mt-4">
                    <Col>
                        <div style={{ height: "34em" }}>
                            <SalesChannel
                                showConfigButton={false}
                                headerTitle="Sell-through Over Time by Sales Channel"
                                headerSubtitle="Sell-through over time by sales channel"
                                widgetData={data}
                                widgetDataLoading={isMountedRef.current && pageDataLoading}
                                widgetOptions={[
                                    "Total Sales",
                                    "Organic Sales",
                                    "Paid Sales",
                                    "SNS Sales",
                                    "Previous Year Total Sales",
                                ]}
                            />
                        </div>
                    </Col>
                </Row>
                <Row className="mt-4">
                    <Col>
                        {data?.dataTable !== undefined && (
                            <BootstrapTablesWrapper
                                data={data?.dataTable}
                                keyField="date"
                                description={
                                    <ChartDescription
                                        title={"Sell Through Over Time by Sales Channel"}
                                        description={"Raw data of sales in dollar over time broken out by sales type"}
                                    />
                                }
                                hasPagination={true}
                                hasFilter={true}
                                defaultSorted={[
                                    {
                                        dataField: "date",
                                        order: "desc",
                                    },
                                ]}
                                columns={[
                                    {
                                        dataField: "date",
                                        text: "Date",
                                        sort: true,
                                        type: "date",
                                        insertFilter: "textFilter",
                                    },
                                    {
                                        dataField: "total_sales",
                                        text: "Total Sales",
                                        type: "number",
                                        sort: true,
                                        formatter: "dollarFormatter",
                                    },
                                    {
                                        dataField: "py_date",
                                        text: "PY Date",
                                        sort: true,
                                        type: "date",
                                        insertFilter: "textFilter",
                                        hidden: true,
                                    },
                                    {
                                        dataField: "py_total_sales",
                                        text: "Previous Year",
                                        type: "number",
                                        sort: true,
                                        formatter: "dollarFormatter",
                                        hidden: true,
                                    },
                                    {
                                        dataField: "previous_year_change",
                                        text: "PY Change ($)",
                                        type: "number",
                                        sort: true,
                                        formatter: "tableColorDollarFormatter",
                                        hidden: true,
                                    },
                                    {
                                        dataField: "previous_year_change_pct",
                                        text: "PY Change (%)",
                                        type: "number",
                                        sort: true,
                                        formatter: "percentColorFormatter",
                                        // hidden: false,
                                    },
                                    {
                                        dataField: "sns_sales",
                                        text: "SNS Sales",
                                        type: "number",
                                        sort: true,
                                        formatter: "dollarFormatter",
                                    },
                                    {
                                        dataField: "py_sns_sales",
                                        text: "PY SNS Sales",
                                        type: "number",
                                        sort: true,
                                        formatter: "dollarFormatter",
                                        hidden: true,
                                    },
                                    {
                                        dataField: "previous_year_sns_change",
                                        text: "PY SNS Change ($)",
                                        type: "number",
                                        sort: true,
                                        formatter: "tableColorDollarFormatter",
                                        hidden: true,
                                    },
                                    {
                                        dataField: "previous_year_sns_change_pct",
                                        text: "PY SNS Change (%)",
                                        type: "number",
                                        sort: true,
                                        formatter: "percentColorFormatter",
                                    },
                                    {
                                        dataField: "organic_sales",
                                        text: "Organic Sales",
                                        type: "number",
                                        sort: true,
                                        formatter: "dollarFormatter",
                                    },
                                    {
                                        dataField: "py_organic_sales",
                                        text: "PY Organic Sales",
                                        type: "number",
                                        sort: true,
                                        formatter: "dollarFormatter",
                                        hidden: true,
                                    },
                                    {
                                        dataField: "previous_year_organic_change",
                                        text: "PY Organic Change ($)",
                                        type: "number",
                                        sort: true,
                                        formatter: "tableColorDollarFormatter",
                                        hidden: true,
                                    },
                                    {
                                        dataField: "previous_year_organic_change_pct",
                                        text: "PY Organic Change (%)",
                                        type: "number",
                                        sort: true,
                                        formatter: "percentColorFormatter",
                                    },
                                    {
                                        dataField: "paid_sales",
                                        text: "Paid Sales",
                                        type: "number",
                                        sort: true,
                                        formatter: "intlCurrencyFormatter",
                                    },
                                    {
                                        dataField: "py_paid_sales",
                                        text: "PY Paid Sales",
                                        type: "number",
                                        sort: true,
                                        formatter: "intlCurrencyFormatter",
                                        hidden: true,
                                    },
                                    {
                                        dataField: "previous_year_paid_change",
                                        text: "PY Paid Change ($)",
                                        type: "number",
                                        sort: true,
                                        formatter: "intlColorCurrencyFormatter",
                                        hidden: true,
                                    },
                                    {
                                        dataField: "previous_year_paid_change_pct",
                                        text: "PY Paid Change (%)",
                                        type: "number",
                                        sort: true,
                                        formatter: "percentColorFormatter",
                                    },
                                ]}
                            />
                        )}
                    </Col>
                </Row>
                <Row className="mt-4">
                    <Col sm={6}>
                        <div style={{ height: "34em" }}>
                            <TopProducts showConfigButton={false} />
                        </div>
                    </Col>

                    <Col sm={6}>
                        <div style={{ height: "34em" }}>
                            <TopSNS showConfigButton={false} headerTitle="Top SNS SKUs (Total Subscriptions)" />
                        </div>
                    </Col>
                </Row>
                <Row className="mt-4">
                    <Col sm={12}>
                        <div style={{ height: "34em" }}>
                            <SubscriberCount
                                showConfigButton={false}
                                headerTitle="Number of Subscriptions Over Time"
                                headerSubtitle="Subscriptions at the end of each month"
                            />
                        </div>
                    </Col>
                </Row>
            </Col>
        </main>
    );
};

export default BrandTimeline;
