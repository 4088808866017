import React, { useContext, useEffect, useState } from "react";
import Select, { components } from 'react-select';
import { AuthContext } from "../../contexts/AuthContext";
import { HeaderContext } from "../../contexts/HeaderContext";

const marketplaces = [
    {label: 'All', value: 'All', type: 'All'},

    {label: 'Amazon', value: 'Amazon', type: 'Amazon'},
    {label: 'Walmart', value: 'Walmart', type: 'Walmart'},
];

const marketplaceGroups = [
    { label: 'All', value: 'All', type: 'All' },
    { label: 'Amazon', value: 'Amazon', type: 'Amazon' },
    { label: 'Walmart', value: 'Walmart', type: 'Walmart' },
    { label: 'TikTok', value: 'TikTok', type: 'TikTok' },
    {
        label: "Amazon NA", value: "Amazon NA",
        options: [
            { label: 'USA', value: 'ATVPDKIKX0DER', type: 'Amazon', flag: '🇺🇸' },
            { label: 'Canada', value: 'A2EUQ1WTGCTBG2', type: 'Amazon', flag: '🇨🇦' },
            { label: 'Mexico', value: 'A1AM78C64UM0Y8', type: 'Amazon', flag: '🇲🇽' },
            { label: 'Brazil', value: 'A2Q3Y263D00KWC', type: 'Amazon', flag: '🇧🇷' },
        ]
    },
    {
        label: "Amazon EU", value: "Amazon EU",
        options: [
            { label: 'UK', value: 'A1F83G8C2ARO7P', type: 'Amazon', flag: '🇬🇧' },
            { label: 'Germany', value: 'A1PA6795UKMFR9', type: 'Amazon', flag: '🇩🇪' },
            { label: 'France', value: 'A13V1IB3VIYZZH', type: 'Amazon', flag: '🇫🇷' },
            { label: 'Spain', value: 'A1RKKUPIHCS9HS', type: 'Amazon', flag: '🇪🇸' },
            { label: 'Italy', value: 'APJ6JRA9NG5V4', type: 'Amazon', flag: '🇮🇹' },
            { label: 'Netherlands', value: 'A1805IZSGTT6HS', type: 'Amazon', flag: '🇳🇱' },
            { label: 'Sweden', value: 'A2NODRKZP88ZB9', type: 'Amazon', flag: '🇸🇪' },
            { label: 'Poland', value: 'A1C3SOZRARQ6R3', type: 'Amazon', flag: '🇵🇱' },
            { label: 'Belgium', value: 'AMEN7PMS3EDWL', type: 'Amazon', flag: '🇧🇪' },
            // { label: 'India', value: 'A21TJRUUN4KGV', type: 'Amazon', flag: '🇮🇳' },
            // { label: 'South Africa', value: 'AE08WJ6YKNBMC', type: 'Amazon', flag: '🇿🇦' }, // Not Yet
            // { label: 'United Arab Emirates (U.A.E.)', value: 'A2VIGQ35RCS4UG', type: 'Amazon', flag: '🇦🇪' }, // Not Yet
            // { label: 'Egypt', value: 'ARBP9OOSHTCHU', type: 'Amazon', flag: '🇪🇬' }, // Not Yet
            // { label: 'Saudi Arabia', value: 'A17E79C6D8DWNP', type: 'Amazon', flag: '🇸🇦' },
            // { label: 'Turkey', value: 'A33AVAJ2PDY3EV', type: 'Amazon', flag: '🇹🇷' },
        ]
    },
    {
        label: "Amazon FE", value: "Amazon FE",
        options: [
            { label: 'Japan', value: 'A1VC38T7YXB528', type: 'Amazon', flag: '🇯🇵' },
            { label: 'Australia', value: 'A39IBJ37TRP1C6', type: 'Amazon', flag: '🇦🇺' },
            { label: 'Singapore', value: 'A19VAU5U5O7RUS', type: 'Amazon', flag: '🇸🇬' },
        ]
    },
];

const marketplaceGroupsAmazon = [
    { label: 'All', value: 'All', type: 'All' },
    {
        label: "Amazon NA", value: "Amazon NA",
        options: [
            { label: 'USA', value: 'ATVPDKIKX0DER', type: 'Amazon', flag: '🇺🇸' },
            { label: 'Canada', value: 'A2EUQ1WTGCTBG2', type: 'Amazon', flag: '🇨🇦' },
            { label: 'Mexico', value: 'A1AM78C64UM0Y8', type: 'Amazon', flag: '🇲🇽' },
            { label: 'Brazil', value: 'A2Q3Y263D00KWC', type: 'Amazon', flag: '🇧🇷' },
        ]
    },
    {
        label: "Amazon EU", value: "Amazon EU",
        options: [
            { label: 'UK', value: 'A1F83G8C2ARO7P', type: 'Amazon', flag: '🇬🇧' },
            { label: 'Germany', value: 'A1PA6795UKMFR9', type: 'Amazon', flag: '🇩🇪' },
            { label: 'France', value: 'A13V1IB3VIYZZH', type: 'Amazon', flag: '🇫🇷' },
            { label: 'Spain', value: 'A1RKKUPIHCS9HS', type: 'Amazon', flag: '🇪🇸' },
            { label: 'Italy', value: 'APJ6JRA9NG5V4', type: 'Amazon', flag: '🇮🇹' },
            { label: 'Netherlands', value: 'A1805IZSGTT6HS', type: 'Amazon', flag: '🇳🇱' },
            { label: 'Sweden', value: 'A2NODRKZP88ZB9', type: 'Amazon', flag: '🇸🇪' },
            { label: 'Poland', value: 'A1C3SOZRARQ6R3', type: 'Amazon', flag: '🇵🇱' },
            { label: 'Belgium', value: 'AMEN7PMS3EDWL', type: 'Amazon', flag: '🇧🇪' },
            // { label: 'India', value: 'A21TJRUUN4KGV', type: 'Amazon', flag: '🇮🇳' },
            // { label: 'South Africa', value: 'AE08WJ6YKNBMC', type: 'Amazon', flag: '🇿🇦' }, // Not Yet
            // { label: 'United Arab Emirates (U.A.E.)', value: 'A2VIGQ35RCS4UG', type: 'Amazon', flag: '🇦🇪' }, // Not Yet
            // { label: 'Egypt', value: 'ARBP9OOSHTCHU', type: 'Amazon', flag: '🇪🇬' }, // Not Yet
            // { label: 'Saudi Arabia', value: 'A17E79C6D8DWNP', type: 'Amazon', flag: '🇸🇦' },
            // { label: 'Turkey', value: 'A33AVAJ2PDY3EV', type: 'Amazon', flag: '🇹🇷' },
        ]
    },
    {
        label: "Amazon FE", value: "Amazon FE",
        options: [
            { label: 'Japan', value: 'A1VC38T7YXB528', type: 'Amazon', flag: '🇯🇵' },
            { label: 'Australia', value: 'A39IBJ37TRP1C6', type: 'Amazon', flag: '🇦🇺' },
            { label: 'Singapore', value: 'A19VAU5U5O7RUS', type: 'Amazon', flag: '🇸🇬' },
        ]
    },
];

const MarketplaceFilter = (props) => {
    const {
        pageMarketplace,
    } = props;

    const isMultiValues = (value) => {
        return (typeof value === 'object' && value.length > 1);
    }

    const getSelectMarketplaceIdValues = (options) => {
        let selectedMarketplaceIds = options?.map((option) => option?.value);

        if (selectedMarketplaceIds?.length === 0) {
            return ['All'];
        }
        else if (selectedMarketplaceIds?.length > 1) {
            selectedMarketplaceIds = selectedMarketplaceIds.filter(e => e !== 'All');
        }

        return selectedMarketplaceIds;
    };

    const searchOptions = (options, marketplaceId) => {
        return options.find((option) => {
            if (option?.options !== undefined && option?.options !== null && option?.options?.length !== 0) {
                let found = option?.options?.filter((groupOption) => (groupOption?.value === marketplaceId));
                if (found?.length > 0) {
                    return found[0];
                }
            }
            else {
                return (option?.value === marketplaceId)
            }
        });
    }

    const getSelectedMarketplaceGroupValue = (options, valueArr) => {
        
        let selectedMarketplaceGroupOptions = [];

        for (const marketplaceId of valueArr) {

            let option = searchOptions(options, marketplaceId);

            if (option?.options?.length > 0) {
                option = searchOptions(option?.options, marketplaceId);
            }

            // if (marketplaceId === 'All') {
            //     selectedMarketplaceGroupOptions.push(option);
            //     break;
            // }

            if (typeof option === 'object' && option !== null && option !== undefined && option !== false) {
                selectedMarketplaceGroupOptions.push(option);
            }
        };

        return selectedMarketplaceGroupOptions;
    }

    const setMarketplaceVisibleMetrics = (value) => {
        if (Array.isArray(value) && value.length === 1 && value[0] === 'Walmart') {
            setVisibleMetrics({
                paid: false,
                sns: false,
                traffic: false,
                ntb: false,
            })
        }
        else {
            setVisibleMetrics(apiUser?.defaultVisibility)
        }
    }

    const updateMarketplaceFromList = (marketplaceList) => {
        if (marketplaceList !== undefined && marketplaceList !== null && marketplaceList !== '') {

            setSelectedMarketplace(
                getSelectedMarketplaceGroupValue(selectOptions, marketplaceList)
            );
            setMarketplaceVisibleMetrics(marketplaceList);
            setMarketplace(marketplaceList)
        }
        else {
            marketplaceList = ['All'];
            setSelectedMarketplace(
                getSelectedMarketplaceGroupValue(selectOptions, marketplaceList)
            );
            setMarketplaceVisibleMetrics(marketplaceList);
            setMarketplace(marketplaceList)
        }
    }

    const { apiUser, setVisibleMetrics } = useContext(AuthContext);
    const { marketplace, setMarketplace } = useContext(HeaderContext);
    const [selectOptions, setSelectOptions] = useState(marketplaceGroups);
    const [selectedMarketplace, setSelectedMarketplace] = useState(
        getSelectedMarketplaceGroupValue(selectOptions, marketplace) ?? selectOptions[0]
    );

    useEffect(() => {
        setMarketplaceVisibleMetrics(marketplace)
    }, [])

    useEffect(() => {
        if (pageMarketplace === true) {
            setSelectOptions(marketplaceGroups)
        }
        else if (pageMarketplace === false || pageMarketplace === 'All') {
            setSelectOptions([marketplaceGroups[0]]);
        }
        else {
            if (pageMarketplace === 'Amazon') {
                const removeWalmart = marketplace.filter(e => e !== 'Walmart');
                updateMarketplaceFromList(removeWalmart);
                setSelectOptions(marketplaceGroupsAmazon);
            }
            else {
                setSelectOptions(marketplaceGroups);
            }
        }
    }, [pageMarketplace])

    const onMarketplaceChange = (option) => {
        // console.log('onMarketplaceChange', option);

        // if the newest value is 'All', then remove all other values
        let newestValue = option?.[option?.length - 1];
        if (newestValue?.value === 'All') {
            option = [newestValue];
        }
        
        let newValue = getSelectMarketplaceIdValues(option);
        // console.log('newValue', newValue)

        updateMarketplaceFromList(newValue)

    };

    const ValueContainer = ({
        children,
        ...props
    }) => {
        let [values, input] = children;

        let text = 'All';
        if (Array.isArray(values)) {
            if (values.length === 0) {
                text = 'All';
            }
            else if (values.length === 1) {
                text = values[0]?.props?.data?.label ?? '';
            }
            else {
                // const plural = values.length === 1 ? "" : "s";
                text = `${values.length} selected`;
            }
        }

        return (
            <div style={{
                maxHeight: '2.5em', 
                overflowY: 'hidden',
                textWrap: 'nowrap',
                display: 'block',
                flex: 'none',
            }}>
                <components.ValueContainer 
                    {...props}
                >
                    <span className="px-1">{text}</span>
                </components.ValueContainer>
            </div>
        );
    };

    return (
        <div>
            <Select
                className="text-dark w-100 rounded-0 select-border-0"
                placeholder=""
                value={selectedMarketplace}
                options={selectOptions}
                name="marketplaceSelect"
                getOptionLabel={(option) => `${option.flag ?? ''} ${option.label}`}
                getOptionValue={(option) => option.value}
                onChange={(option) => onMarketplaceChange(option)}
                isSearchable={false}
                // closeMenuOnSelect={false}
                isMulti={true}
                isClearable={true}
                hideSelectedOptions={false}
                styles={{
                    control: styles => (
                        { ...styles, border: 0, maxHeight: '2.5em', overflowY: 'hidden', flexWrap: 'nowrap' }
                    ),
                }}
                components={{
                    ValueContainer 
                }}
                noOptionsMessage={(inputValue) => "No Marketpalce Available"}
            />
        </div>
    );
};

export default MarketplaceFilter;
