import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faImage } from "@fortawesome/free-solid-svg-icons";

const ProductImageThumbnail = (props) => {
    const { 
        imageSrc,
        imageLink,
        imageAlt,
        height = "4em",
        width = "4em",
        wrapperClassName = "img-thumbnail rounded mr-2 p-0",
        wrapperStyle = {},
        className = "",
        style = {},
        useBackgroundImage = true,
    } = props;

    const imageContainer = (
        <img
            className={className}
            style={style}
            alt={imageAlt}
            src={imageSrc}
        ></img>
    )

    const bgImageContainer = (
        <div
            className={className}
            style={{
                width: width,
                height: height,
                alignContent: 'center',
                justifyContent: 'center',

                backgroundImage: imageSrc ? `url(${imageSrc})` : undefined,
                backgroundSize: 'contain',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                ...style
            }}
        > 
            {imageSrc ? undefined : <FontAwesomeIcon className="h-25 w-100" icon={faImage}/>}
        </div>
    )

    const displayImage = useBackgroundImage ? bgImageContainer : imageContainer;

    return (
        <div className={wrapperClassName} style={wrapperStyle}>
            {imageLink ? (
                <a href={imageLink} target="_blank" rel="noopener noreferrer">
                    {displayImage}
                </a>
            ) : displayImage}
        </div>
    );
};

export default ProductImageThumbnail;