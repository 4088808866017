import React, { useEffect, useState, useContext } from "react";
import { Row, Col, Card, Button, Tabs, Tab, Accordion } from "react-bootstrap";

import { BrandContext } from "../../contexts/BrandContext";
import { HeaderContext } from "../../contexts/HeaderContext";

import WidgetSpinner from "../../components/helper/WidgetSpinner";

import { metric_property, metrics } from "./metricsVars";

export const defaultQuantitativeMetricCards = ["Total Sales", "Organic Sales", "SNS Sales", "Paid Sales"];

const categories = [
    "Global",
    "Organic",
    "SNS",
    "Paid",
    "SPSB",
    "SP",
    "SP Performance",
    "SP Defensive",
    "SP Research",
    "SB",
    "SD",
    "DSP",
];

const QuantitativeMetricCards = (props) => {

    const { brand } = useContext(BrandContext);
    const { currency } = useContext(HeaderContext);

    const {
        selectedMetrics,
        enabledDataLabels,
        brandAggregatedData,
        dataLoading,
    } = props;

    const [activeMetricTab, setActiveMetricTab] = useState("Global");

    const toggleChartMetric = (metric, element = undefined) => {
        const mIndex = selectedMetrics.indexOf(metric);
        const lIndex = enabledDataLabels.indexOf(metric);
        if (mIndex === -1) {
            // Not found, add
            selectedMetrics.push(metric);
            enabledDataLabels.push(metric);

            props.onChange({
                metrics: selectedMetrics,
                dataLabels: enabledDataLabels,
            });
        } else {
            // Found, remove
            selectedMetrics.splice(mIndex, 1);
            enabledDataLabels.splice(lIndex, 1);

            props.onChange({
                metrics: selectedMetrics,
                dataLabels: enabledDataLabels,
            });
        }
    };

    const toggleChartDataLabels = (metric, element = undefined) => {
        const mIndex = selectedMetrics.indexOf(metric);
        const lIndex = enabledDataLabels.indexOf(metric);
        if (lIndex === -1 && mIndex === -1) {
            // Not found anywhere, add both
            selectedMetrics.push(metric);
            enabledDataLabels.push(metric);
            props.onChange({
                metrics: selectedMetrics,
                dataLabels: enabledDataLabels,
            });
        }
        if (lIndex === -1 && mIndex !== -1) {
            // Not found as datalabel, add
            enabledDataLabels.push(metric);
            
            props.onChange({
                metrics: selectedMetrics,
                dataLabels: enabledDataLabels,
            });
        } else {
            // Found, remove
            enabledDataLabels.splice(lIndex, 1);

            props.onChange({
                metrics: selectedMetrics,
                dataLabels: enabledDataLabels,
            });
        }
    };

    const formatMetricValue = (metric, value) => {
        const percentFormatter = new Intl.NumberFormat(brand?.currencyLocale ?? "en-US", {
            style: "percent",
            minimumFractionDigits: 2,
        });
        const dollarFormatter = new Intl.NumberFormat(brand?.currencyLocale ?? "en-US", {
            style: "currency",
            currency: currency,
        });
        const currencyFormatter = new Intl.NumberFormat(brand?.currencyLocale ?? "en-US", {
            style: "currency",
            currency: currency,
        });
        const wholeNumberFormatter = new Intl.NumberFormat(brand?.currencyLocale ?? "en-US", {
            style: "decimal",
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
        });
        const numberFormatter = new Intl.NumberFormat(brand?.currencyLocale ?? "en-US", {
            style: "decimal",
            minimumFractionDigits: 0,
            maximumFractionDigits: 2,
        });

        if (metric_property[metric]?.formatter === "dollarFormatter") {
            return dollarFormatter.format(value);
        } else if (metric_property[metric]?.formatter === "intlCurrencyFormatter") {
            return currencyFormatter.format(value);
        } else if (metric_property[metric]?.formatter === "percentFormatter") {
            return percentFormatter.format(value);
        } else if (metric_property[metric]?.formatter === "numberFormatter") {
            return numberFormatter.format(value);
        } else if (metric_property[metric]?.formatter === "ratioFormatter") {
            return `${numberFormatter.format(value)}x`;
        } else if (metric_property[metric]?.formatter === "intFormatter") {
            return wholeNumberFormatter.format(value);
        }

        return "";
    };

    const getMetricButton = (name, background = undefined) => {
        const is_enabled = selectedMetrics.indexOf(name) !== -1;
        const is_labelenabled = enabledDataLabels.indexOf(name) !== -1;

        return (
            brandAggregatedData[name] && (
                <Col className="my-2">
                    <Card
                        className={`${background === undefined ? (is_enabled ? "bg-gray" : "bg-white") : background}`}
                    >
                        <Card.Body className="p-1 text-center border-bottom" onClick={(e) => toggleChartMetric(name, e)}>
                            <strong>{name}</strong>
                            <p className="mb-0">{formatMetricValue(name, brandAggregatedData[name])}</p>
                        </Card.Body>
                        <Button
                            variant={is_labelenabled ? "dark" : "default"}
                            className="btn-sm"
                            onClick={(e) => toggleChartDataLabels(name, e)}
                        >
                            Toggle Datalabels
                        </Button>
                    </Card>
                </Col>
            )
        );
    };

    return (
        <Accordion style={{borderBottom: '1px solid rgba(0, 0, 0, 0.125)'}}>
            <Card>
                <Card.Header className="bg-white p-0">
                    <Accordion.Toggle as={"h5"} variant="link" className="blue-600 mb-0 p-3" eventKey="0">
                        Show Filters
                    </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="0">
                    <Card.Body>
                        <div className="d-flex justify-content-center w-100">
                            {dataLoading ? (
                                <WidgetSpinner />
                            ) : (
                                brandAggregatedData && (
                                    <div>
                                        <Row className="d-flex justify-content-center mb-3">
                                            {selectedMetrics?.length > 0 &&
                                                selectedMetrics.map((metric_prop, index) => {
                                                    return (
                                                        <div key={index}>{getMetricButton(metric_prop)}</div>
                                                    );
                                                })}
                                        </Row>
                                        <Row className="d-flex justify-content-center">
                                            <Col className="border-top">
                                                <Tabs
                                                    className="quantitative-tab-group justify-content-center mt-3"
                                                    activeKey={activeMetricTab}
                                                    onSelect={(k) => setActiveMetricTab(k)}
                                                >
                                                    {categories &&
                                                        categories.map((category, index) => {
                                                            return (
                                                                <Tab eventKey={category} title={category} key={index}>
                                                                    <Row className="pt-4 d-flex justify-content-center border-top">
                                                                        {metrics.map((metric, ii) => {
                                                                            if (
                                                                                metric_property[metric].category ===
                                                                                category
                                                                            ) {
                                                                                return (
                                                                                    <div key={ii}>
                                                                                        {getMetricButton(metric)}
                                                                                    </div>
                                                                                );
                                                                            }
                                                                        })}
                                                                    </Row>
                                                                </Tab>
                                                            );
                                                        })}
                                                </Tabs>
                                            </Col>
                                        </Row>
                                    </div>
                                )
                            )}
                        </div>
                    </Card.Body>
                </Accordion.Collapse>
            </Card>
        </Accordion>
    );
};

export default QuantitativeMetricCards;
