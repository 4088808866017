import React, { useContext, useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import moment from "moment";

import { timeline } from "../../../api/ppc";

import { AuthContext } from "../../../contexts/AuthContext";
import { BrandContext } from "../../../contexts/BrandContext";
import { HeaderContext } from "../../../contexts/HeaderContext";
import { ToastContext } from "../../../contexts/ToastContext";

import WidgetSpinner from "../../../components/helper/WidgetSpinner";

import BootstrapTablesWrapper from "../../../components/dataRender/BootstrapTablesWrapper";

import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { CSVExport } from "react-bootstrap-table2-toolkit";
import filterFactory /* numberFilter */ from "react-bootstrap-table2-filter";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
// import {
//     numberFormatter,
//     wholeNumberFormatter,
//     dollarFormatter,
//     percentFormatter,
// } from "../../../components/helper/NumberFormatters";

import { CurrencyFormatter, PercentFormatter, NumberFormatter } from "../../../components/helper/IntlNumberFormatter";

const calculateChangePerc = (currentValue, previousValue) => {
    if (!isNaN(previousValue) && !isNaN(currentValue)) {
        if (previousValue > 0) {
            return (currentValue - previousValue) / previousValue;
        } else {
            return 1.0;
        }
    }
    return null;
};

function TimelineTable(props) {
    const { ExportCSVButton } = CSVExport;
    const { token } = useContext(AuthContext);
    const { selectedDefaultDates, currency, marketplace } = useContext(HeaderContext);

    const [data, setData] = useState({});
    // const [overviewData, setOverviewData] = useState([]);
    const [widgetLoading, setWidgetLoading] = useState(true);

    const [widgetOptions, setWidgetOptions] = useState({
        widgetTitle: "PPC Overview",
        showConfigButton: true,
        ...props,
    });

    const { brand } = useContext(BrandContext);
    const { toast } = useContext(ToastContext);

    useEffect(() => {
        const getData = async () => {
            setWidgetLoading(true);
            try {
                const res = await timeline(token, {
                    brandid: brand.brandId,
                    tf_s: moment(selectedDefaultDates.tp_s).format('YYYY-MM-DD'),
                    tf_e: moment(selectedDefaultDates.tp_e).format('YYYY-MM-DD'),
                    wow: selectedDefaultDates.timeframe,
                    currency: currency,
                    marketplace: marketplace,
                });

                setData(res.data[0]);

                setWidgetLoading(false);
            } catch (err) {
                setWidgetLoading(false);
                toast(err.message);
            }
        };

        getData();
    }, [token, brand.brandId, setData, toast, selectedDefaultDates, currency]);

    const getRoasFormatter = (value) => {
        return <NumberFormatter value={value} displayDecimal={true} replaceEmpty={true} showExtra={"x"} />;
    };

    const getPercentFormatter = (value) => {
        return <PercentFormatter value={value} replaceEmpty={true} />;
    };

    const getWholeNumberFormatter = (value) => {
        return <NumberFormatter value={value} displayDecimal={false} replaceEmpty={true} />;
    };

    const getCurrencyFormatter = (value) => {
        return <CurrencyFormatter value={value} displayDecimal={true} replaceEmpty={true} />;
    };

    const getMetricFormats = (metricName, currentData, previousData, absoluteDiff = true) => {
        let currentMetric = currentData;
        let previousMetric = previousData;

        metricName = metricName.toLowerCase();

        if (metricName.includes("roas")) {
            let diffIsPositive = currentMetric - previousMetric >= 0 ? true : false;

            let diff = getRoasFormatter(currentMetric - previousMetric);
            if (absoluteDiff) {
                diff = getRoasFormatter(Math.abs(currentMetric - previousMetric));
            }

            let amountDiff = diff;

            let current = getRoasFormatter(currentMetric);
            let previous = getRoasFormatter(previousMetric);
            return {
                diffIsPositive: diffIsPositive,
                diff: diff,
                current: current,
                previous: previous,
                amountDiff: amountDiff,
            };
        } else if (
            metricName.includes("tacos") ||
            metricName.includes("buybox") ||
            metricName.includes("cnv") ||
            metricName.includes("acos") ||
            metricName.includes("ctr") ||
            metricName.includes("cvr")
        ) {
            let diffIsPositive = currentMetric - previousMetric >= 0 ? true : false;
            let diff = getPercentFormatter(currentMetric - previousMetric);
            if (absoluteDiff) {
                diff = getPercentFormatter(Math.abs(currentMetric - previousMetric));
            }
            let amountDiff = diff;
            let current = getPercentFormatter(currentMetric);
            let previous = getPercentFormatter(previousMetric);
            return {
                diffIsPositive: diffIsPositive,
                diff: diff,
                current: current,
                previous: previous,
                amountDiff: amountDiff,
            };
        } else if (metricName.includes("sales") || metricName.includes("spend")) {
            let diffIsPositive = currentMetric - previousMetric >= 0 ? true : false;

            let diffNumber = (currentMetric - previousMetric) / previousMetric;
            if (previousMetric === 0) {
                if (currentMetric === 0) {
                    diffNumber = 0;
                } else {
                    diffNumber = 1;
                }
            }
            let diff = getPercentFormatter(diffNumber);
            if (absoluteDiff) {
                diff = getPercentFormatter(Math.abs(diffNumber));
            }

            let amountDiff = currentMetric - previousMetric;
            amountDiff = getCurrencyFormatter(amountDiff);

            let current = getCurrencyFormatter(currentMetric);
            let previous = getCurrencyFormatter(previousMetric);
            return {
                diffIsPositive: diffIsPositive,
                diff: diff,
                current: current,
                previous: previous,
                amountDiff: amountDiff,
            };
        } else {
            let diffIsPositive = currentMetric - previousMetric >= 0 ? true : false;

            let diffNumber = (currentMetric - previousMetric) / previousMetric;
            if (previousMetric === 0) {
                if (currentMetric === 0) {
                    diffNumber = 0;
                } else {
                    diffNumber = 1;
                }
            }
            let diff = getPercentFormatter(diffNumber);
            if (absoluteDiff) {
                diff = getPercentFormatter(Math.abs(diffNumber));
            }

            let amountDiff = currentMetric - previousMetric;
            amountDiff = getWholeNumberFormatter(amountDiff);

            let current = getWholeNumberFormatter(currentMetric);
            let previous = getWholeNumberFormatter(previousMetric);
            return {
                diffIsPositive: diffIsPositive,
                diff: diff,
                current: current,
                previous: previous,
                amountDiff: amountDiff,
            };
        }
    };

    const renderChart = () => {
        let columns = [
            {
                dataField: "date",
                text: "Date",
                sort: true,
                headerStyle: { width: "200px" },
            },
        ];

        if (data?.timeline !== undefined && data?.timeline[0] !== undefined) {
            Object.keys(data.timeline[0]).forEach((key) => {
                if (['year', 'quarter', 'month', 'day', 'minWeekDate', 'Min BuyBox Date', 'Max BuyBox Date'].includes(key)) {
                    return;
                }

                if (key !== "date") {
                    let col = {
                        dataField: key,
                        text: key,
                        sort: true,
                        headerStyle: { width: "200px" },

                        formatter: (cell, row, rowIndex) => {
                            if (cell) return getMetricFormats(key, cell, null, false)?.current ?? cell;
                        },
                    };
                    columns.push(col);
                }
            });
        }

        // console.log(data.timeline, columns);
        
        //getMetricFormats
        return (
            <BootstrapTablesWrapper
                data={data.timeline}
                keyField="date"
                columns={columns}
                tableOnly={true}
                columnModal={true}
                hasPagination={true}
                hasFilter={true}
            />
        )

        return (
            <ToolkitProvider bootstrap4 keyField="date" data={data.timeline} columns={columns} exportCSV>
                {(props) => (
                    <div>
                        <ExportCSVButton className="btn btn-dark m-0 ml-3 mb-2 bg-accent-default" {...props.csvProps}>
                            <FontAwesomeIcon icon={faDownload} /> Export CSV
                        </ExportCSVButton>
                        <div className="w-100" style={{ overflow: "auto" }}>
                            <BootstrapTable
                                {...props.baseProps}
                                pagination={paginationFactory({
                                    sizePerPage: 12,
                                    alwaysShowAllBtns: true,
                                })}
                                filter={filterFactory()}
                                hover
                                bordered
                            />
                        </div>
                    </div>
                )}
            </ToolkitProvider>
        );
    };

    return (
        <div className="card h-100" /*style={{ overflow: "auto" }}*/>
            <div className="card-header white bg-white">
                <div className="d-flex flex-row align-items-center">
                    <div className="pr-2 ml-4 m-0 ">
                        <h5 className="mb-2 mt-2">{widgetOptions.widgetTitle}</h5>
                    </div>

                    <div className="ml-auto ">
                        {widgetOptions.showConfigButton === true ? (
                            <button
                                type="button"
                                className="btn btn-primary btn-sm "
                                onClick={() => {
                                    //TODO: Add Configuration Modal
                                }}
                            >
                                Config
                            </button>
                        ) : null}
                    </div>
                </div>
            </div>

            <div className="card-body  pt-2 pb-2">
                <Row>
                    <Col sm={12}>{widgetLoading ? <WidgetSpinner /> : renderChart()}</Col>
                </Row>
            </div>
        </div>
    );
}

export default TimelineTable;
