import moment from 'moment';

// Get the current date and time
export const clientDateTime = moment();

// Get "today"
export const today = clientDateTime.clone().startOf('day');
export const yesterday = today.clone().subtract(1, 'days'); // Subtract one day to get "yesterday"
export const p2d = today.clone().subtract(2, 'days'); // 2 day ago
export const p7d = today.clone().subtract(7, 'days'); // 7 day ago
export const p30d = today.clone().subtract(30, 'days'); // 30 day ago
export const p90d = today.clone().subtract(90, 'days'); // 365 day ago
export const p365d = today.clone().subtract(365, 'days'); // 365 day ago

export const MTD_s = yesterday.clone().startOf('month'); // Get the start of the month

export const YTD_s = yesterday.clone().startOf('year'); // Get the start of the year

export const YTD_e = yesterday.clone().endOf('year'); // This Year End

export const last_YTD_s = yesterday.clone().subtract(1, 'years').startOf('year'); // Last Year Start
export const last_YTD_e = yesterday.clone().subtract(1, 'years').endOf('year'); // Last Year End

export const last_MTD_s = yesterday.clone().subtract(1, 'months').startOf('month'); // Last Month Start
export const last_MTD_e = yesterday.clone().subtract(1, 'months').endOf('month'); // Last Month End

const quarter = Math.floor(yesterday.month() / 3);
export const this_Q_s = yesterday.clone().month(quarter * 3).startOf('month'); // Start of This Quarter

export const last_Q_s = yesterday.clone().month((quarter * 3) - 3).startOf('month'); // Start of Last Quarter
export const last_Q_e = yesterday.clone().month((quarter * 3)).subtract(1, 'months').endOf('month'); // End of Last Quarter

export const getLastSaturday = (init = yesterday) => {
    return init.clone().day(6); // 6 is Saturday
};

export const getLastSunday = (init = yesterday) => {
    return init.clone().day(0); // 0 is Sunday
};

export const getLastMonday = (init = yesterday) => {
    return init.clone().day(1); // 1 is Monday
};

export const formatMonthDateStr = (date_str) => {
    if (date_str === '' || date_str === undefined) { return ''; }
    const date = moment(date_str);
    return date.format('YYYY MMMM');
}

export const formatDateStr = (date_str) => {
    if (date_str === '' || date_str === undefined) { return ''; }
    const date = moment(date_str);
    return date.format('YYYY MM DD');
}

export const prettyPrintDate = (date_str, timeframe) => {
    if (date_str === '' || date_str === undefined) { return ''; }
    
    const date = moment(date_str);

    switch (timeframe) {
        default:
        case 'daily':
        case 'wow':
        case 'quarter':
            return date.format('YYYY MMMM DD');
            break;
        case 'mom':
            return date.format('YYYY MMMM');
            break;
        case 'annual':
            return date.format('YYYY');
            break;
    }
}