import React, { useEffect, useState, useContext } from "react";
import { Row, Col } from "react-bootstrap";

import { HeaderContext } from "../../contexts/HeaderContext";

import AutoAcos from "../BrandReport/widgets/AutoAcos";

import "./chartStyle.css";

const AutoAcosPage = (props) => {
    //Grabs user data from AuthContext.js

    const { setPageTitle, setPageTimeframe, setPageDisplayTimeFrameInterval } = useContext(HeaderContext);

    /**
     * useEffect with no second arguments = Run on every render
     *
     * Return statement is the cleanup function, runs when the component unmounts (Usually used to cleanup. Here it is used to clean up the page title)
     */
    useEffect(() => {
        setPageTitle("PPC - Timeline");
        setPageTimeframe("timeframe");
        setPageDisplayTimeFrameInterval(false);
        return () => {
            setPageTitle(process.env.REACT_APP_TITLE);
        };
    });

    return (
        <main className="d-flex justify-content-start align-items-top">
            <Col>
                <Row className="mt-5">
                    <Col sm={12} style={{ height: "34em" }}>
                        <AutoAcos showConfigButton={false} widgetTitle="Test Widget" />
                    </Col>
                </Row>
            </Col>
        </main>
    );
};

export default AutoAcosPage;
