import React, { useContext, useState, useEffect } from "react";
import { Row } from "react-bootstrap";
import { ResponsiveContainer } from "recharts";
import localforage from "localforage";
import Chart from "react-apexcharts";
import Select from "react-select";
import moment from "moment";

import _ from "lodash";

import { AuthContext } from "../../../contexts/AuthContext";
import { BrandContext } from "../../../contexts/BrandContext";
import { HeaderContext } from "../../../contexts/HeaderContext";
import { ToastContext } from "../../../contexts/ToastContext";

import WidgetSpinner from "../../helper/WidgetSpinner";
import useIsMountedRef from "../../helper/isMountedRef";

import { formatValue } from "../../helper/NumberFormatters";
import { defaultGraphLabelStyle } from "../../helper/ColorFormatter";
import { getOption, filterObjectKeys, getArrayObjectValues } from "../../helper/ArrayObjFunctions";

import { performanceMetrics } from "../../../api/brandData";
import { metric_property } from "../../../pages/Quantitative/metricsVars";

const chartMetricsOptions = [
    // (default selection for primaryMetric dropdown)
    { value: 'total_sales', label: 'Total Sales'},
    // (default selection for secondaryMetric dropdown)
    { value: 'organic_sales', label: 'Organic Sales'},

    { value: 'paid_sales', label: 'Paid Sales'},
    { value: 'sns_sales', label: 'SNS Sales'},
    { value: 'new_to_brand_customers', label: 'New To Brand Customers'},
    { value: 'repeat_sales', label: 'Repeat Sales'},
];

const chartMetrics = chartMetricsOptions.map((metric) => metric.label);
const performanceChartMetricsProperty = filterObjectKeys(metric_property, chartMetrics);

const ChartMetricFilter = (props) => {

    return (
        <Select
            className="text-dark w-100 rounded-1 select-border-1"
            placeholder="Select Metric"
            value={props.metric}
            options={chartMetricsOptions}
            name="performanceMetricsChartSelect"
            getOptionLabel={(option) => `${option.label}`}
            getOptionValue={(option) => option.value}
            onChange={(option) => props.onChange(option)}
            isMulti={false}
            allowSelectAll={false}
            isSearchable={false}
            isLoading={false}
            noOptionsMessage={(inputValue) => "No Metrics Available"}
        />
    );
};

function PerformanceChart(props) {
    // console.log('PerformanceChart', props);

    const [chartOptions, setChartOptions] = useState({});
    const [chartSeries, setChartSeries] = useState([]);

    const {
        brand,
        currency,
        primaryMetricOption,
        secondaryMetricOption,
        chartData,
        chartLabels,
    } = props;

    useEffect(() => {

        try {

            const primaryColor = '#8BA3E5'; // Blue color
            const secondaryColor = '#C3D400'; // Neon color

            const primaryMetric = primaryMetricOption.value;
            const secondaryMetric = secondaryMetricOption.value;

            const primaryMetricLabel = chartData[primaryMetric]?.label ?? primaryMetricOption.label;
            const primaryMetricLabelPY = chartData[`py_${primaryMetric}`]?.label ?? `Previous Year ${primaryMetricLabel}`;
            const secondaryMetricLabel = chartData[secondaryMetric]?.label ?? secondaryMetricOption.label;
            const secondaryMetricLabelPY = chartData[`py_${secondaryMetric}`]?.label ?? `Previous Year ${secondaryMetricLabel}`;

            const new_series = [
                {
                    name: primaryMetricLabel,
                    data: chartData[primaryMetric].data,
                    type: "area",
                    color: primaryColor,
                    hidden: false,
                    metricProperties: performanceChartMetricsProperty[primaryMetricLabel],
                    strokeDash: 0,
                },
                {
                    name: secondaryMetricLabel,
                    data: chartData[secondaryMetric].data,
                    type: "area",
                    color: secondaryColor,
                    hidden: false,
                    metricProperties: performanceChartMetricsProperty[secondaryMetricLabel],
                    strokeDash: 0,
                },
                {
                    name: primaryMetricLabelPY,
                    data: chartData[`py_${primaryMetric}`].data,
                    type: "line",
                    color: primaryColor,
                    hidden: (primaryMetricLabelPY === "Previous Year Total Sales" ? false : true),
                    metricProperties: performanceChartMetricsProperty[primaryMetricLabel],
                    strokeDash: 5,
                },
                {
                    name: secondaryMetricLabelPY,
                    data: chartData[`py_${secondaryMetric}`].data,
                    type: "line",
                    color: secondaryColor,
                    hidden: (secondaryMetricLabelPY === "Previous Year Total Sales" ? false : true),
                    metricProperties: performanceChartMetricsProperty[secondaryMetricLabel],
                    strokeDash: 5,
                },
            ];

            // const seriesIndexOrder = getArrayObjectValues(new_series, 'metricPropertyKey');
    
            const baseAxis = {
                show: true,
                title: { text: undefined, style: defaultGraphLabelStyle },
                min: 0,
                labels: {
                    show: true,
                    style: defaultGraphLabelStyle,
                },
                crosshairs: { show: false },
                tooltip: { enabled: false },
            };
    
            const activeAxis = [
                _.merge(_.cloneDeep(baseAxis), {
                    seriesName: [primaryMetricLabel, primaryMetricLabelPY],
                    title: { text: primaryMetricLabel },
                    opposite: false,
                    labels: {
                        formatter: (value) => {
                            return formatValue(value, performanceChartMetricsProperty[primaryMetricLabel]?.axis, brand, currency);
                        },
                    },
                }),
                _.merge(_.cloneDeep(baseAxis), {
                    seriesName: [secondaryMetricLabel, secondaryMetricLabelPY],
                    title: { text: secondaryMetricLabel },
                    opposite: true,
                    labels: {
                        formatter: (value) => {
                            return formatValue(value, performanceChartMetricsProperty[secondaryMetricLabel]?.axis, brand, currency);
                        },
                    },
                }),
            ];
    
            const new_chartOptions = {
                chart: {
                    id: "performanceMetricsChartLine",
                    toolbar: {
                        show: false,
                    },
                    zoom: {
                        enabled: false,
                    },
                    dropShadow: {
                        enabled: false,
                    },
                    animations: {
                        enabled: false,
                    },
                    height: "100%",
                    type: "area",
                },
                legend: { 
                    show: true, 
                    position: "bottom", 
                    horizontalAlign: "center", 
                    floating: false, 
                    offsetY: 0, 
                    offsetX: 0,
                },
                fill: {
                    type: ["gradient", "gradient", "solid", "solid"],
                    gradient: {
                        shade: 'light',
                        type: "vertical",
                        shadeIntensity: 0.5,
                        opacityFrom: 0.7,
                        opacityTo: 0.2,
                        stops: [0, 90, 100]
                    }
                },
                dataLabels: { enabled: false },
                stroke: {
                    show: true,
                    curve: "smooth",
                    lineCap: "butt",
                    width: Array(new_series.length).fill(3),
                    dashArray: getArrayObjectValues(new_series, 'strokeDash'),
                },
                grid: {
                    show: true,
                    borderColor: "#eaecec",
                    xaxis: { lines: { show: true } },
                    yaxis: { lines: { show: true } },
                },
                markers: {
                    size: Array(new_series.length).fill(5),
                    strokeWidth: Array(new_series.length).fill(0),
                    strokeOpacity: 1,
                    fillOpacity: 1,
                    hover: { sizeOffset: 1 },
                },
                tooltip: {
                    shared: true,
                    followCursor: true,
                    y: {
                        formatter: function (val, w) {
                            try {
                                return formatValue(val, new_series[w.seriesIndex].metricProperties.formatter, brand, currency);
                            } catch (error) {
                                // console.log(error);
                            }

                            return formatValue(val, 'numberFormatter', brand, currency);
                        },
                    },
                },
                xaxis: {
                    categories: chartLabels ?? [],
                    type: "category",
                    labels: {
                        style: defaultGraphLabelStyle,
                    },
                    tickPlacement: "on",
                },
                yaxis: activeAxis,
            };
    
            setChartSeries(new_series);
            setChartOptions(new_chartOptions);

        } catch (error) {
            // console.log(error);
        }

    }, [chartData, primaryMetricOption, secondaryMetricOption, chartLabels, brand, currency]);

    return (
        <ResponsiveContainer>
            <Chart options={chartOptions} series={chartSeries} type="area" />
        </ResponsiveContainer>
    );
}

function PerformanceMetricsChart(props) {
    // console.log('PerformanceMetricsChart', props);

    const primaryOptionForageKey = "fortress-last-selected-performanceMetricsChart-primary";
    const secondaryOptionForageKey = "fortress-last-selected-performanceMetricsChart-secondary";

    const {
        // showConfigButton = false,
        headerTitle = "Sales Performance",
        headerSubtitle = "",
        // widgetOptions,
        widgetDataLoading = true,
        widgetData,
    } = props;

    const { token } = useContext(AuthContext);
    const { toast } = useContext(ToastContext);
    const { brand } = useContext(BrandContext);
    const { selectedDefaultDates, currency, marketplace } = useContext(HeaderContext);

    const [chartData, setChartData] = useState();
    const [widgetLoading, setWidgetLoading] = useState(widgetDataLoading);
    const [widgetInitialized, setWidgetInitialized] = useState(false);

    const [primaryMetricOption, setPrimaryMetricOption] = useState(chartMetricsOptions[0]);
    const [secondaryMetricOption, setSecondaryMetricOption] = useState(chartMetricsOptions[1]);

    const isMountedRef = useIsMountedRef();
    
    const getForageInitialValues = async (forageKey) => {
        const forageOptionValue = await localforage.getItem(forageKey);
        return getOption(chartMetricsOptions, forageOptionValue);
    }

    useEffect(() => {
        const getForageValues = async () => {
            const primaryMetricOptionForage = await getForageInitialValues(primaryOptionForageKey);
            setPrimaryMetricOption(primaryMetricOptionForage ?? chartMetricsOptions[0]);

            const secondaryMetricOptionForage = await getForageInitialValues(secondaryOptionForageKey);
            setSecondaryMetricOption(secondaryMetricOptionForage ?? chartMetricsOptions[1]);

            setWidgetInitialized(true);
        };

        const getData = async () => {
            if (widgetData) {
                setChartData(widgetData);
            } else {
                try {
                    setWidgetLoading(true);

                    const res = await performanceMetrics(token, {
                        brandid: brand.brandId,
                        tf_s: moment(selectedDefaultDates.tf_s).format('YYYY-MM-DD'),
                        tf_e: moment(selectedDefaultDates.tf_e).format('YYYY-MM-DD'),
                        wow: selectedDefaultDates.timeframe,
                        currency: currency,
                        marketplace: marketplace,
                    });

                    // console.log('performanceMetrics', res.data);
                    if (isMountedRef.current) {
                        setChartData(res?.data?.lineChart);
                    }
                } catch (err) {
                    toast(err.message);
                }
            }
        };

        if (isMountedRef.current) {
            setWidgetLoading(true);
            widgetInitialized ? getData() : getForageValues();
            setWidgetLoading(false);
        }
    }, [
        // Context
        token,
        brand,
        currency,
        marketplace,
        toast,
        selectedDefaultDates,
        
        // Props/Widget
        widgetInitialized,
        widgetData,
        isMountedRef,
    ]);
    
    const onChangePrimaryMetricOption = (option) => {
        if (option.value) {
            localforage.setItem(primaryOptionForageKey, option.value);
            setPrimaryMetricOption(option);
        }
    }
    
    const onChangeSecondaryMetricOption = (option) => {
        if (option.value) {
            localforage.setItem(secondaryOptionForageKey, option.value);
            setSecondaryMetricOption(option);
        }
    }

    try {
        return (
            <div  style={{ height: "34em" }}>
                <div className="card h-100">
                    <div className="card-header bg-white">
                        <div className="d-flex flex-row align-items-center">
                            <div>
                                <h5 className="mb-0">{headerTitle}</h5>
                                {headerSubtitle !== "" ? <p className="body-1 text-secondary mb-0 mt-2">{headerSubtitle}</p> : ""}
                            </div>
                        </div>
                    </div>
    
                    { widgetLoading ? <WidgetSpinner /> : 
                        <div className="card-body w-100 mb-4"> 
                            <div className="d-flex flex-row gap-2 justify-content-between">
                                <div className="d-flex w-50">
                                    <ChartMetricFilter 
                                        metric={primaryMetricOption}
                                        disableOption={secondaryMetricOption}
                                        onChange={(option) => onChangePrimaryMetricOption(option)}
                                    ></ChartMetricFilter>
                                </div>
                                <div className="d-flex w-50">
                                    <ChartMetricFilter 
                                        metric={secondaryMetricOption}
                                        disableOption={primaryMetricOption}
                                        onChange={(option) => onChangeSecondaryMetricOption(option)}
                                    ></ChartMetricFilter>
                                </div>
                            </div>
    
                            <Row style={{ height: "100%" }}>
                                {chartData && (
                                    <PerformanceChart
                                        brand={brand}
                                        currency={currency}
                                        primaryMetricOption={primaryMetricOption}
                                        secondaryMetricOption={secondaryMetricOption}
                                        chartData={chartData.data}
                                        chartLabels={chartData.labels}
                                    />
                                )}
                            </Row>
                        </div>
                    }
                </div>
            </div>
        );
    } catch (error) {
        // console.log(error);
    }
}

export default PerformanceMetricsChart;
