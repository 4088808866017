import React from 'react';

const NotFound = ({ history }) => {
  return (
    <div className="main-display flex-grow-1">
      <div style={{ height: '100vh' }} className="d-flex justify-content-center align-items-center">
        <div style={{ width: 300 }}>
          <h1 className="text-center"> 404 Not Found </h1>
        </div>
      </div>
    </div>
  );
};

export default NotFound;
