import React, { createContext, useState, useContext, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { Container, Button } from 'react-bootstrap';
import { useAuth0 } from '@auth0/auth0-react';

import { logging } from '../api/user';

import { AuthContext } from './AuthContext';
import { ToastContext } from './ToastContext';

// export const AuthContext = createContext({});

const ErrorContext = createContext();

export function ErrorBoundary({ children }) {
  const { token, checkMaintenanceMode } = useContext(AuthContext);
  const { toast } = useContext(ToastContext);

  const [error, setError] = useState(null);

  const { loginWithRedirect } = useAuth0();

  // Report error to API when it changes
  useEffect(() => {
    let isMounted = true; // add a flag to check if the component is still mounted

    const logError = async () => {
      checkMaintenanceMode(error, /*with_redirect=*/ true);

      if (error !== undefined && error !== null && error?.message !== undefined) {
        // Log the error to an API endpoint
        try {
          const log = {
            version: process.env.REACT_APP_VERSION,
            message: error.message,
            stack: error?.stack ?? [],
          };

          // console.log(log);

          const res = await logging(token ?? null, log);

          // console.log(res);
        } catch (e) {
          console.error('Error reporting error', e);
        }
      }
    };

    logError();

    return () => {
      // cleanup function
      isMounted = false;
    };
  }, [error]);

  const reportError = (error) => {
    setError(error);
  };

  if (error) {
    // Render a fallback UI
    return (
      <Container
        fluid
        className="bg-white d-flex flex-row justify-content-between m-0 p-0"
        style={{ height: '100vh', overflow: 'hidden' }}
      >
        <Container
          fluid
          className="p-0 h-100 w-100"
          style={{ maxHeight: '100%', overflow: 'auto' }}
        >
          <div
            className="d-flex align-items-center flex-column justify-content-center"
            style={{ height: '100vh', position: 'relative', top: '-60px' }}
          >
            <div className="text-center">
              <div>
                <h2>Error: Something went wrong.</h2>
                <p>We're working on fixing this issue. Please try again later.</p>
                <Button
                  className="btn btn-lg btn-primary btn-block"
                  variant="primary"
                  type=""
                  onClick={() => loginWithRedirect()}
                >
                  Go Back
                </Button>
              </div>
            </div>
          </div>
        </Container>
      </Container>
    );
  }

  return <ErrorContext.Provider value={reportError}>{children}</ErrorContext.Provider>;
}

export function useError() {
  const context = useContext(ErrorContext);

  if (context === undefined) {
    throw new Error('useError must be used within a ErrorBoundary');
  }

  return context;
}
