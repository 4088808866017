import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { AuthContext } from '../../contexts/AuthContext';
import { updateUser } from '../../api/user';
import { addMonths, format, parse } from 'date-fns';
import { Button, Modal } from 'react-bootstrap';
import Select from 'react-select';

const options = [
  { value: 'January', label: 'January' },
  { value: 'February', label: 'February' },
  { value: 'March', label: 'March' },
  { value: 'April', label: 'April' },
  { value: 'May', label: 'May' },
  { value: 'June', label: 'June' },
  { value: 'July', label: 'July' },
  { value: 'August', label: 'August' },
  { value: 'September', label: 'September' },
  { value: 'October', label: 'October' },
  { value: 'November', label: 'November' },
  { value: 'December', label: 'December' },
];

export const ModalFiscal = ({ onClose }) => {

  const { token, apiUser, setAPIUser } = useContext(AuthContext);

  const [selectedMonth, setSelectedMonth] = useState(options[0]);
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (apiUser?.settings?.global?.['fiscal-year-start'])
      setSelectedMonth(options.find((op) => op.value === apiUser.settings.global['fiscal-year-start']) || options[0]);
  }, [apiUser, setSelectedMonth]);

  const updateMonth = useCallback(async () => {
    setIsLoading(true);
    await updateUser(token, {
      setting: 'fiscal-year-start',
      value: selectedMonth.value,
    });
    setAPIUser({ ...apiUser,
      settings: {
        ...apiUser.settings,
        global: { ...apiUser.settings.global, 'fiscal-year-start': selectedMonth.value },
      },
    });
    setIsLoading(false);
    onClose();
  }, [token, updateUser, selectedMonth, setIsLoading, onClose, setAPIUser, apiUser]);

  const dateRange = useMemo(() => {
    const dateStart = parse(`${selectedMonth?.value}/01/${selectedYear}`, 'MMMM/dd/yyyy', new Date());
    const dateEnd = addMonths(dateStart, 11);
    return `FY${format(dateStart, 'yyyy')} - ${format(dateEnd, 'yyyy')} (${format(dateStart, 'MMMM')} - ${format(dateEnd, 'MMMM')})`;
  }, [selectedMonth, selectedYear]);

  return <Modal show onHide={onClose}>
    <Modal.Header closeButton>
      <Modal.Title className="body-1-bold">Fiscal Year Settings</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <h6>Current Fiscal year</h6>
          <div className="input-group mb-3">
            <div className="input-group-prepend">
              <button className="btn btn-light" type="button" onClick={() => setSelectedYear(selectedYear - 1)}>-
              </button>
            </div>
            <input type="text" className="form-control" value={dateRange}
                   aria-describedby="basic-addon1" disabled />
            <div className="input-group-append">
              <button className="btn btn-light" type="button" onClick={() => setSelectedYear(selectedYear + 1)}>+
              </button>
            </div>
          </div>
        </div>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <h6>Fiscal Year Start Month</h6>
          <Select options={options} value={selectedMonth} onChange={setSelectedMonth} />
        </div>
      </div>

    </Modal.Body>
    <Modal.Footer>
      <Button disabled={isLoading} variant="secondary" onClick={onClose}>
        <div className={'d-flex justify-content-between align-items-center gap-2'}>
          {isLoading && <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>}
          Close
        </div>
      </Button>
      <Button disabled={isLoading} variant="info" onClick={updateMonth}>
        <div className={'d-flex justify-content-between align-items-center gap-2'}>
          {isLoading && <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>}
          Save Changes
        </div>
      </Button>
    </Modal.Footer>
  </Modal>;
};