import fortress from './fortress';

export async function overview(token, params) {
  return fortress.get('ppc/overview', {
    headers: { Authorization: `Bearer ${token}` },
    params: params,
  });
}

export async function timeline(token, params) {
  return fortress.get('ppc/timeline', {
    headers: { Authorization: `Bearer ${token}` },
    params: params,
  });
}

export async function productsXcampaign(token, params) {
  return fortress.get('ppc/productsxcampaign', {
    headers: { Authorization: `Bearer ${token}` },
    params: params,
  });
}

export async function keywordanalysis(token, params) {
  return fortress.get('ppc/keywordanalysis', {
    headers: { Authorization: `Bearer ${token}` },
    params: params,
  });
}

export async function customerJourney(token, params) {
  return fortress.get('ppc/customerJourney', {
    headers: { Authorization: `Bearer ${token}` },
    params: params,
  });
}

export async function topPerformingKeywords(token, params) {
  return fortress.get('ppc/topPerformingKeywords', {
    headers: { Authorization: `Bearer ${token}` },
    params: params,
  });
}

export async function topProductsXKeywords(token, params) {
  return fortress.get('ppc/topProductsXKeywords', {
    headers: { Authorization: `Bearer ${token}` },
    params: params,
  });
}
