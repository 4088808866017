import React, { useCallback, useContext, useEffect, useState } from 'react';
import _ from 'lodash';

import { HeaderContext } from '../../contexts/HeaderContext';

import BootstrapTablesWrapper from '../../components/dataRender/BootstrapTablesWrapper';

import WidgetSpinner from '../../components/helper/WidgetSpinner';
import ProductImageThumbnail from '../../components/helper/productImageThumbnail';
import { prettyPrintDate } from '../../components/helper/DateVars.js';

import { getQuantitativeDataTableCols } from './metricsVars';

const initialVpartColumns = [
  {
    // Combine Vpart + Short Title + Image
    dataField: 'vpart',
    text: 'vPart',
    sort: true,
    type: 'string',
    filter: 'textFilter',
    filterValue: (cell, row) =>
      row['vpart_alt'] !== null && row['vpart_alt'] !== undefined && row['vpart_alt'] !== ''
        ? row['vpart_alt']
        : row['vpart'],
    headerStyle: (colum, colIndex) => {
      return { minWidth: '350px' };
    },
    formatter: (cell, row, rowIndex) => {
      return (
        <div className="d-flex flex-row flex-nowrap align-items-center">
          <ProductImageThumbnail
            imageSrc={row['image']}
            imageLink={row['asin'].length > 0 ? row['asin'][0]['asinLink'] : ''}
            imageAlt={
              row['vpart_alt'] !== null && row['vpart_alt'] !== undefined && row['vpart_alt'] !== ''
                ? row['vpart_alt']
                : row['vpart']
            }
          />
          <div className="d-flex flex-column w-100 ml-2">
            <p className="mb-0">{row['short_title']}</p>
            <div className="d-flex flex-row justify-content-between text-sm">
              <div className="mr-2">
                <p className="mb-0">
                  {row['vpart_alt'] !== null &&
                  row['vpart_alt'] !== undefined &&
                  row['vpart_alt'] !== ''
                    ? row['vpart_alt']
                    : row['vpart']}
                </p>
              </div>
              <div>
                {row['asin'] !== undefined && row['asin'].length > 0
                  ? row['asin'].map((value) => {
                      return (
                        <span className="mr-2" key={value['asin']}>
                          <a href={value['asinLink']} target={`_${value['asin']}`}>
                            {value['asin']}
                          </a>
                        </span>
                      );
                    })
                  : ''}
              </div>
              {/* <p>{row['sku'] !== undefined ? row['sku']?.split(',').join(', ') : row['sku']}</p> */}
            </div>
          </div>
        </div>
      );
    },
    csvFormatter: (cell, row, rowIndex) => {
      return (
        '="' +
        (row['vpart_alt'] !== null && row['vpart_alt'] !== undefined && row['vpart_alt'] !== ''
          ? row['vpart_alt']
          : row['vpart']) +
        '"'
      );
    },
  },
  {
    dataField: 'upc',
    text: 'UPC',
    sort: true,
    hidden: true,
    type: 'string',
    filter: 'textFilter',
    csvFormatter: (cell, row, rowIndex) => {
      return `="${row['upc']}"`;
    },
  },
  {
    dataField: 'asin',
    text: 'ASIN',
    type: 'string',
    sort: true,
    hidden: true,
    filter: 'textFilter',
    sortValue: (cell, row) => cell['asin'] ?? '',
    filterValue: (cell, row, rowIndex) => {
      return cell?.map((obj) => obj?.asin).join(', ');
    },
    formatter: (cell, row, rowIndex) => {
      return (
        <div>
          {cell !== undefined && cell.length > 0
            ? cell.map((value) => {
                return (
                  <span className="mr-2" key={value['id']}>
                    <a href={value['asinLink']} target={`_${value['asin']}`}>
                      {value['asin']}
                    </a>
                  </span>
                );
              })
            : ''}
        </div>
      );
    },
    csvFormatter: (cell, row, rowIndex) => {
      return cell?.map((obj) => obj?.asin).join(', ');
    },
  },
  {
    dataField: 'short_title',
    text: 'Short Title',
    sort: true,
    hidden: true,
    type: 'string',
    filter: 'textFilter',
    headerStyle: { whiteSpace: 'normal', minWidth: '150px' },
  },
  {
    dataField: 'msrp',
    text: 'SRP',
    sort: false,
    hidden: false,
    type: 'number',
    formatter: 'dollarFormatter',
  },
];

const defaultQuantitativePivotColumns = [
  'Total Sales', // Sales
  'Average Price', // ASP
  'Total Units', // Units
  'Buybox Percentage', // Average BBX
  'Total Sessions', // Sessions
  'Total CNV (Orders)', // CVR
  'Paid Spend', // SP AD Spend
  'QoH', // Inventory
];

const QuantitativePivotTable = (props) => {
  // console.log('QuantitativePivotTable', props);

  const { selectedDefaultDates, generateDownloadFilename } = useContext(HeaderContext);
  const [vpartPivotData, setVpartPivotData] = useState();

  const {
    prePivotData: data,
    pivotValues,
    pivotColumns = props?.pivotColumns ?? defaultQuantitativePivotColumns,
  } = props;

  const getVpartPivotByDate = useCallback(() => {
    let pivot_data = [];
    let pivot_columns = [];

    // console.log('starting::getVpartPivotByDate ', data, pivotValues, metrics);

    // Create Columns
    initialVpartColumns.forEach((column) => {
      pivot_columns.push({
        dataField: column.dataField,
        text: column.text,
        type: column?.type,
        hidden: column?.hidden,
        headerStyle: column?.headerStyle,
        formatter: column?.formatter,
        csvFormatter: column?.csvFormatter,

        // The following don't work / play nice with header groups

        // sort: column?.sort,
        // filter: column?.filter,
      });
    });

    pivotValues.forEach((date_str) => {
      pivotColumns.forEach((metric) => {
        let metricColumn = getQuantitativeDataTableCols([metric])[0];

        const display_date_str = prettyPrintDate(date_str, selectedDefaultDates.timeframe);

        metricColumn['dataField'] = `${date_str}_${metric}`;
        metricColumn['text'] = metric;
        metricColumn['data_metric'] = metric;
        metricColumn['data_date'] = date_str;
        metricColumn['headerAttrs'] = {
          'data-group': date_str,
          'data-group-name': display_date_str,
        };
        metricColumn['csvText'] = `${display_date_str} ${metric}`;

        pivot_columns.push(metricColumn);
      });
    });

    let pivot = [];
    data.forEach((row) => {
      // First Time Setup Empty Pivot
      if (!pivot[row.vpart]) {
        pivot[row.vpart] = {};

        pivotValues.forEach((date) => {
          pivot[row.vpart][date] = {};

          pivotColumns.forEach((metric) => {
            pivot[row.vpart][date][metric] = null;
          });
        });
      }

      pivot[row.vpart][row.date] = _.merge(pivot[row.vpart][row.date], row);
    });

    const vpartKeys = Object.keys(pivot);
    vpartKeys.forEach((vpart) => {
      const vpart_data = pivot[vpart];

      // get first key with data (vpart is not null)
      const first_date = Object.keys(vpart_data).find((key) => {
        return vpart_data[key]?.vpart && vpart_data[key]?.vpart !== null;
      });

      if (!vpart_data[first_date]?.vpart) {
        return;
      }

      let pivotRow = {
        vpart: vpart_data[first_date].vpart,
        vpart_alt: vpart_data[first_date].vpart_alt,
        upc: vpart_data[first_date]?.upc ?? '',
        msrp: vpart_data[first_date].msrp ?? '',
        asin: vpart_data[first_date]?.asin ?? '',
        short_title: vpart_data[first_date]?.short_title ?? '',
        image: vpart_data[first_date]?.image ?? '',
      };

      pivot_columns.forEach((column) => {
        if (pivotRow[column]) {
          // Already exists, skip
          return;
        }

        if (column?.data_date && column?.data_metric) {
          const date = column.data_date;
          const metric = column.data_metric;
          pivotRow[`${date}_${metric}`] = vpart_data[date][metric];
        }
      });

      pivot_data.push(pivotRow);
    });

    // console.log(pivot, pivot_columns, pivot_data);

    setVpartPivotData({
      data: pivot_data,
      columns: pivot_columns,
    });
  }, [data, pivotColumns, pivotValues, selectedDefaultDates.timeframe]);

  useEffect(() => {
    setVpartPivotData();

    if (data && pivotValues) {
      getVpartPivotByDate();
    }
  }, [data, pivotValues, pivotColumns, getVpartPivotByDate]);

  return (
    <div>
      {props.loading ? (
        <WidgetSpinner />
      ) : (
        <BootstrapTablesWrapper
          keyField="vpart"
          data={vpartPivotData?.data ?? []}
          columns={vpartPivotData?.columns ?? initialVpartColumns}
          columnModal={'none'}
          hasPagination={true}
          hasFilter={true}
          headerNowrap={true}
          rowNowrap={false}
          groupHeaders={true} // Enable group headers explicitly
          exportCSV={{
            fileName: generateDownloadFilename({ extension: 'csv', suffix: 'vparts_pivot' }),
          }}
        />
      )}
    </div>
  );
};

export default QuantitativePivotTable;
