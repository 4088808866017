import React, { useContext, useState, useEffect } from "react";
import { ResponsiveContainer } from "recharts";
import Chart from "react-apexcharts";
import ApexCharts from "apexcharts";
import moment from "moment";

import { AuthContext } from "../../../contexts/AuthContext";
import { BrandContext } from "../../../contexts/BrandContext";
import { HeaderContext } from "../../../contexts/HeaderContext";
import { ToastContext } from "../../../contexts/ToastContext";

import WidgetSpinner from "../../../components/helper/WidgetSpinner";
import useIsMountedRef from "../../../components/helper/isMountedRef";

import { timelineOverview } from "../../../api/brandData";

const chartFont = {
    colors: "gray",
    fontSize: "12px",
    cssClass: "apexcharts-xaxis-label",
};

function SalesChannelChart(props) {
    // console.log('SalesChannelChart', props);

    const [chartOptions, setChartOptions] = useState({});
    const [series, setSeries] = useState([]);

    const isMountedRef = useIsMountedRef();

    const {
        brand,
        currency,
        chartData,
        widgetOptions,
        setMounted,
    } = props;

    const cleanCurrencyFormatter = new Intl.NumberFormat(brand?.currencyLocale ?? "en-US", {
        style: "currency",
        currency: currency,
        maximumFractionDigits: 2,
        minimumFractionDigits: 0,
    });

    const getCleanCurrencyFormatter = (value) => {
        return cleanCurrencyFormatter.format(value);
    };

    useEffect(() => {
        if (isMountedRef.current) {
            setMounted(true);
        }
    }, [isMountedRef])

    useEffect(() => {

        if (chartData?.lineChart !== undefined) {
    
            // Default widgetOptions is only total sales and previous year total sales
            let widgetColors = [
                // graph_green, // enalytics-green
                // graph_green_dark, // front-row-green
                '#84344e','#E3FF00','#313132','#9d555e'
            ];
            let new_series = [
                {
                    name: chartData?.lineChart.data[0].label,
                    data: chartData?.lineChart.data[0].data,
                    type: "line",
                },
                {
                    name: chartData?.lineChart.data[4].label,
                    data: chartData?.lineChart.data[4].data,
                    type: "line",
                },
            ];
            let dataLabelSeries = [0, 1];
            let markerSize = [1, 1];
            let dashArray = [0, 5];
    
            if (widgetOptions.length > 2) {
                // Google charts made these colors a bit transparent 0878ff, fdcd10. So those are the values of that
                widgetColors = [
                    // graph_green, // enalytics-green
                    // graph_gray, // enalytics-gray
                    // graph_blue_dark, // enalytics-blue-dark
                    // graph_green_dark, // front-row-green
                    '#84344e','#E3FF00','#313132','#9d555e'
                ];
                new_series = [
                    {
                        // Current
                        name: chartData?.lineChart.data[0].label,
                        data: chartData?.lineChart.data[0].data,
                        type: "line",
                    },
                    {
                        // Organic
                        name: chartData?.lineChart.data[1].label,
                        data: chartData?.lineChart.data[1].data,
                        type: "line",
                    },
                    {
                        // Paid
                        name: chartData?.lineChart.data[2].label,
                        data: chartData?.lineChart.data[2].data,
                        type: "line",
                    },
                    {
                        // Previous Year
                        name: chartData?.lineChart.data[4].label,
                        data: chartData?.lineChart.data[4].data,
                        type: "line",
                    },
                ];
                dataLabelSeries = [];
                markerSize = [1, 1, 1, 1];
                dashArray = [0, 0, 0, 5];
            }
    
            let new_chartOptions = {
                chart: {
                    id: "salesChannelLine",
                    toolbar: {
                        show: false,
                    },
                    zoom: {
                        enabled: false,
                    },
                    dropShadow: {
                        enabled: false,
                    },
                    animations: {
                        enabled: false,
                    },
                    height: "100%",
                    type: "line",
                },
                legend: { show: true, position: "bottom", horizontalAlign: "center", floating: false, offsetY: 0, offsetX: 0 },
                colors: widgetColors,
                dataLabels: {
                    enabled: false,
                    enabledOnSeries: dataLabelSeries,
                    style: {
                        fontSize: "13px",
                        fontFamily: "Nunito",
                    },
                    formatter: function (value, { seriesIndex, dataPointIndex, w }) {
                        let inDollars = true;
                        // If we have all the labels, paid needs to be in locale currency, everything else in dollars
                        if (widgetOptions.length === 5 && seriesIndex === 2) {
                            // seriesIndex 2 is paid
                            inDollars = false;
                        }
                        return value !== null ? getCleanCurrencyFormatter(value.toFixed(0)) : "";
                    },
                    background: {
                        enabled: true,
                    },
                    offsetY: -10,
                },
                stroke: {
                    show: true,
                    curve: "smooth",
                    lineCap: "butt",
                    colors: widgetColors,
                    width: [3, 3, 3, 3],
                    dashArray: dashArray
                },
                grid: {
                    show: true,
                    borderColor: "#eaecec",
                    xaxis: { lines: { show: true } },
                    yaxis: { lines: { show: true } },
                    strokeDashArray: [5, 0],
                },
                markers: {
                    size: markerSize,
                    colors: widgetColors,
                    strokeColors: widgetColors,
                    strokeWidth: [5, 5, 5, 5],
                    strokeOpacity: 1,
                    fillOpacity: 1,
                    hover: { sizeOffset: 1 },
                },
                tooltip: {
                    shared: true,
                    followCursor: true,
                    y: {
                        formatter: function (val, w) {
                            let inDollars = true;
                            // If we have all the labels, paid needs to be in locale currency, everything else in dollars
                            if (widgetOptions.length === 5 && w.seriesIndex === 2) {
                                // seriesIndex 2 is paid
                                inDollars = false;
                            }
                            return getCleanCurrencyFormatter(val);
                        },
                    },
                },
    
                xaxis: {
                    categories: chartData?.lineChart.labels,
                    type: "category",
                    labels: {
                        style: chartFont,
                    },
                    tickPlacement: "between",
                },
                yaxis: {
                    title: {
                        text: "",
                    },
                    min: 0,
                    labels: {
                        formatter: (val) => getCleanCurrencyFormatter(val),
                        style: chartFont,
                    },
                },
            };

            setSeries(new_series);
            setChartOptions(new_chartOptions);
            setMounted(true);
        }

    }, [props.chartData])

    return (
        <ResponsiveContainer>
            {/* <button onClick={ApexCharts.exec('salesChannelLine', 'toggleSeries', 'Previous Year Total Sales')}>Previous Year Total Sales</button> */}
            <Chart options={chartOptions} series={series} type="line" />
        </ResponsiveContainer>
    );
}

function SalesChannel(props) {
    // console.log('SalesChannel', props);

    const { token } = useContext(AuthContext);
    const { selectedDefaultDates, currency, marketplace } = useContext(HeaderContext);
    const { brand } = useContext(BrandContext);
    const { toast } = useContext(ToastContext);

    const [timelineData, setTimelineData] = useState(props.widgetData ?? null);
    const [widgetLoading, setWidgetLoading] = useState((props.widgetData !== null && props.widgetData !== undefined) ? false : (props.widgetDataLoading ?? true));
    const [chartMounted, setChartMounted] = useState(false);

    const isMountedRef = useIsMountedRef();

    const {
        showConfigButton = false,
        headerTitle = "Sales Channel",
        headerSubtitle = "",
        widgetOptions = ["Total Sales", "Previous Year Total Sales"],
    } = props;

    useEffect(() => {
        if (chartMounted) {
            ApexCharts.exec("salesChannelLine", "hideSeries", "Organic Sales");
            ApexCharts.exec("salesChannelLine", "hideSeries", "Paid Sales");
        }
    }, [chartMounted]);

    useEffect(() => {
        const getData = async () => {
            setWidgetLoading(true);

            if (props.widgetData !== undefined && props.widgetData !== null
                && timelineData !== undefined && timelineData !== null
            ) {
                if (isMountedRef.current) {
                    // console.log('getData WidgetData', isMountedRef.current, props.widgetData, timelineData);
                    setWidgetLoading(false);
                    setTimelineData(props.widgetData);
                }
            } else {
                try {
                    // console.log('getData API', isMountedRef.current, props.widgetData, timelineData);

                    const res = await timelineOverview(token, {
                        brandid: brand.brandId,
                        tf_s: moment(selectedDefaultDates.tf_s).format('YYYY-MM-DD'),
                        tf_e: moment(selectedDefaultDates.tf_e).format('YYYY-MM-DD'),
                        wow: selectedDefaultDates.timeframe,
                        currency: currency,
                        marketplace: marketplace,
                    });

                    // console.log(res.data);
                    if (isMountedRef.current) {
                        setWidgetLoading(false);
                        setTimelineData(res.data);
                    }


                } catch (err) {
                    setWidgetLoading(false);
                    toast(err.message);
                }
            }
        };

        if (isMountedRef.current) {
            getData()
        }
    }, [
        // Context
        token,
        toast,
        brand,
        currency,
        marketplace,
        selectedDefaultDates,
        
        // Props/Widget
        isMountedRef,
        timelineData,
        props.widgetData,
    ]);

    return (
        <div className="card h-100" /*style={{ overflow: "auto" }}*/>
            <div className="card-header bg-white">
                <div className="d-flex flex-row align-items-center">
                    <div>
                        <h5 className="mb-0">{headerTitle}</h5>
                        {headerSubtitle !== "" ? <p className="body-1 text-secondary mb-0 mt-2">{headerSubtitle}</p> : ""}
                    </div>
                    <div className="ml-auto ">
                        {showConfigButton === true ? (
                            <button
                                type="button"
                                className="btn btn-primary btn-sm "
                                onClick={() => {
                                    //TODO: Add Configuration Modal
                                }}
                            >
                                Config
                            </button>
                        ) : null}
                    </div>
                </div>
            </div>

            <div className="card-body w-100">{
                    widgetLoading ? 
                    <WidgetSpinner />
                    :
                    timelineData && (
                        <SalesChannelChart
                            brand={brand}
                            currency={currency}
                            chartData={timelineData}
                            widgetOptions={widgetOptions}
                            setMounted={(value) => setChartMounted(value)}
                        />
                    )
                }
            </div>
        </div>
    );
}

export default SalesChannel;
