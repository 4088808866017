import React, { useEffect, useState, useContext } from "react";
import { Row, Col } from "react-bootstrap";
import TemplateMetricsCard from "../../components/dataRender/dashboardCards/TemplateMetricsCard";
import {HeaderContext} from "../../contexts/HeaderContext";
import {AuthContext} from "../../contexts/AuthContext";
import {BrandContext} from "../../contexts/BrandContext";
import BootstrapTablesWrapper from "../../components/dataRender/BootstrapTablesWrapper";
import SpendPie from "./charts/SpendPie";
import {IntFormatter} from "../../components/helper/NumberFormatters";
import fortress from "../../api/fortress";
import moment from "moment";
import WidgetSpinner from "../../components/helper/WidgetSpinner";

const SponsoredAds = (props) => {
    const { token } = useContext(AuthContext);
    const { brand } = useContext(BrandContext);
    const { selectedDefaultDates,currency,marketplace } = useContext(HeaderContext);

    const top5Data =[
        {
            target: "Nike Running Shoes",
            impressions: "125,00",
            clicks: "5,600",
            orders: "315",
            cvr: "7.1%",
            roas: "3.2x",
        }
    ];

    const [tableLoading, setTableLoading] = useState(true);

    const pieColors = ["#c2d0f2","#8ea0d5","#c3d404","#9dac00","#a2566c","#84344e"];

    const [activeChartPill,setActiveChartPill] = useState("spend")
    const [activeTopFivePill,setActiveTopFivePill] = useState("branded")
    const [targetTable, setTargetTable] = useState({
        columns: [],
        data: [],
    })

    useEffect(() => {
        async function fetchData() {
            try {
                const pieResponse = await fortress.get("paid-media/fetch-target-pie", {
                    headers: { Authorization: `Bearer ${token}` },
                    params: {
                        brand_id: brand.brandId,
                        currency: currency,
                        start_date: moment(selectedDefaultDates.tf_s).format('YYYY-MM-DD'),
                        end_date: moment(selectedDefaultDates.tf_e).format('YYYY-MM-DD'),
                    },
                });
                console.log(pieResponse.data);

                const metricsResponse = await fortress.get("paid-media/fetch-target-metrics", {
                    headers: { Authorization: `Bearer ${token}` },
                    params: {
                        brand_id: brand.brandId,
                        currency: currency,
                        start_date: moment(selectedDefaultDates.tf_s).format('YYYY-MM-DD'),
                        end_date: moment(selectedDefaultDates.tf_e).format('YYYY-MM-DD'),
                    },
                });
                console.log(metricsResponse.data);

                const tableResponse = await fortress.get("paid-media/fetch-target-table", {
                    headers: { Authorization: `Bearer ${token}` },
                    params: {
                        brand_id: brand.brandId,
                        currency: currency,
                        start_date: moment(selectedDefaultDates.tf_s).format('YYYY-MM-DD'),
                        end_date: moment(selectedDefaultDates.tf_e).format('YYYY-MM-DD'),
                    },
                });
                setTargetTable({
                    columns: tableResponse.data.columns,
                    data: tableResponse.data.rows,
                });
                setTableLoading(false);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        }
        fetchData();
    },[token, brand, marketplace, selectedDefaultDates, currency]);



return (
    <div className="p-3">
        {/*Sponsored Ads Targeting*/}
        <Row className="mt-4 mb-3">
            <Col>
                <div className="pill-list-horizontal">
                    <span className={activeChartPill === "spend" ? 'active-pill' : ''}
                          onClick={() => {setActiveChartPill("spend")}}>
                        Spend
                    </span>
                    <span className={activeChartPill === "sales" ? 'active-pill' : ''}
                          onClick={() => {setActiveChartPill("sales")}}>
                        Sales
                    </span>
                </div>
            </Col>
        </Row>
        <Row >
            <Col style={{height: "34em"}}>
                <SpendPie
                    widgetID={"targetingPie"}
                    headerTitle="Sponsored Ads Targeting"
                    widgetData={[{
                        backgroundColor: pieColors,
                        labels: ["Keyword", "ASIN Target", "Category", "Audience"],
                        data: [10, 10, 20, 10]
                    }]}
                    widgetFormatter={IntFormatter}
                    // widgetDataLoading={pieTacticLoading}
                />
            </Col>
        </Row>

        {/*Summarized Data*/}
        <Row style={{'borderBottom': "1px solid rgba(0, 0, 0, .125)"}}>
            <Col>
                <h5>Summarized Data</h5>
            </Col>
        </Row>
        <Row className="mt-4 mb-3">
            <Col>
                <div className="metric-card-sm">
                    <span className="text-dark text-h4 d-block">#Keywords Harvested</span>
                    <span className="text-dark text-h2 d-block">15</span>
                </div>
            </Col>
            <Col>
                <div className="metric-card-sm">
                    <span className="text-dark text-h4 d-block">#Converting Keywords</span>
                    <span className="text-dark text-h2 d-block">15</span>
                </div>
            </Col>
            <Col>
                <div className="metric-card-sm">
                    <span className="text-dark text-h4 d-block">#Newly Converting</span>
                    <span className="text-dark text-h2 d-block">15</span>
                </div>
            </Col>
        </Row>

        {/*Top 5*/}
        <Row className="mt-4 pb-3" style={{'borderBottom': "1px solid rgba(0, 0, 0, .125)"}}>
            <Col>
                <div className="pill-list-horizontal mt-4">
                    <span className={activeTopFivePill === "branded" ? 'active-pill' : ''}
                          onClick={() => {
                              setActiveTopFivePill("branded")
                          }}>
                        Branded
                    </span>
                    <span className={activeTopFivePill === "non_branded" ? 'active-pill' : ''}
                          onClick={() => {
                              setActiveTopFivePill("non_branded")
                          }}>
                        Non-Branded
                    </span>
                </div>
            </Col>
        </Row>
        <Row className="mt-4">
            <Col>
                <div className="metric-card-sm">
                    <span className="text-dark text-h4">Top 5 by Order Volume</span>
                    <ul className="mt-3">

                    </ul>
                </div>
            </Col>
            <Col>
                <div className="metric-card-sm">
                <span className="text-dark text-h4">Top 5 by Order Growth</span>
                    <ul className="mt-3">

                    </ul>
                </div>
            </Col>
            <Col>
                <div className="metric-card-sm">
                    <span className="text-dark text-h4">Top 5 by ROAS</span>
                    <ul className="mt-3">

                    </ul>
                </div>
            </Col>
        </Row>

        {/*Converting Targets Table*/}
        <Row className="mt-4 mb-3">
            <Col>
            <div className="top-five-container mt-4">
                    <Row>
                        <Col className="col-md-4">
                            <h4>Converting Targets</h4>
                        </Col>
                        <Col className="col-md-4 offset-4">
                            {/*<div className="pill-list-horizontal">*/}
                            {/*    <span className={activeTopFivePill === "order_volume" ? 'active-pill' : ''}*/}
                            {/*          onClick={() => {setActiveTopFivePill("order_volume")}}>*/}
                            {/*        Order Volume*/}
                            {/*    </span>*/}
                            {/*    <span className={activeTopFivePill === "order_growth" ? 'active-pill' : ''} onClick={() => {*/}
                            {/*        setActiveTopFivePill("order_growth")}}>*/}
                            {/*        Order Growth*/}
                            {/*    </span>*/}
                            {/*    <span className={activeTopFivePill === "roas" ? 'active-pill' : ''}*/}
                            {/*          onClick={() => {setActiveTopFivePill("roas")}}>*/}
                            {/*        ROAS*/}
                            {/*    </span>*/}
                            {/*</div>*/}
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            {tableLoading ? <WidgetSpinner /> : (
                                <BootstrapTablesWrapper
                                    data={targetTable.data}
                                    columns={targetTable.columns}
                                    columnModal="none"
                                    keyField="target"
                                    hasPagination={true}
                                    hasFilter={false}
                                    hasSearch={true}
                                    showButtons={true}
                                />
                            )}
                        </Col>
                    </Row>
                </div>
            </Col>
        </Row>

    </div>
)
}

export default SponsoredAds;