import React, { useEffect, useContext } from "react";
import { Row, Col } from "react-bootstrap";

import { HeaderContext } from "../../contexts/HeaderContext";

import BrandToplineSalesTable from "../../components/dataRender/dashboardTables/BrandToplineSalesTable";
import ProductLevelSalesTable from "../../components/dataRender/dashboardTables/ProductLevelSalesTable";

const ProductPerformance = (props) => {
    const { setPageTitle, setPageTimeframe, setPageDisplayTimeFrameInterval } = useContext(HeaderContext);

    useEffect(() => {
        setPageTitle("Product Performance");
        setPageTimeframe("snapshot");
        setPageDisplayTimeFrameInterval(false);
        return () => {
            setPageTitle(process.env.REACT_APP_TITLE);
        };
    });

    return (
        <main className="d-flex justify-content-start align-items-top">
            <Col>
                <Row className="mt-5">
                    <Col>
                        <BrandToplineSalesTable />
                    </Col>
                </Row>
                <Row className="mt-4">
                    <Col sm={12}>
                        <ProductLevelSalesTable />
                    </Col>
                </Row>
            </Col>
        </main>
    );
};

export default ProductPerformance;
