import React, { useState, useEffect, useContext, useRef } from "react";
import { Col } from "react-bootstrap";
import Select from "react-select";

import { HeaderContext } from "../../contexts/HeaderContext";
import DateRangeSelect from "../header/DateRangeSelect";

const defaultPageComparativeOptions = [
    { label: 'Previous Year', value: 'py' },
    { label: 'Previous Month', value: 'pmsd' },
    { label: 'Previous Days', value: 'pd' },
    { label: 'Custom Date', value: 'pc' },
]

const ComparativeSelect = (props) => {
    const {
        toMomentDateStr,

        comparativeOptions,
        comparativeValue,
        setComparativeValue,
        // setComparativeOptions,

        // selectedPreviousCustomDates,
        setSelectedPreviousCustomDates,
    } = useContext(HeaderContext);

    const divRef = useRef(null);

    // const [comparativeValue, setComparativeValue] = useState(props.value);
    const [showCustomComparativeSelector, setShowCustomComparativeSelector] = useState("hide");

    const hideCustomCompare = () => {
        setShowCustomComparativeSelector("hide")
    }

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (divRef.current && !divRef.current.contains(event.target)) {
                hideCustomCompare();
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [hideCustomCompare]);

    const onComparativeChange = (option) =>{
        if(option.value === 'pc') {
            //show date picker
            setShowCustomComparativeSelector("show");
        }

        setComparativeValue(option);
    }

    const selectPreviousDates = (dates) => {

        const seletedDate = dates[0];

        if(seletedDate && seletedDate?.startDate) {
            const newDate = {
                pc_s: toMomentDateStr(seletedDate.startDate),
                pc_e: toMomentDateStr(seletedDate.endDate),
            };

            setSelectedPreviousCustomDates(newDate);
            hideCustomCompare();
        }
    }

    return (
        <Col className="px-0 text-nowrap">
            <Col className="px-0">
                <span className="body-1 text-enalytics-neon align-self-center">
                    Comparative Time Range
                </span>
            </Col>
            <Col className="px-0">
                <Select
                    className="text-dark w-100 rounded-0 select-border-0"
                    placeholder="Select Date to Compare"
                    value={comparativeValue}
                    options={comparativeOptions}
                    onChange={(option) => onComparativeChange(option)}
                    name="compareSelect"
                    isMulti={false}
                    allowSelectAll={false}
                    isSearchable={false}
                    isLoading={false}
                />
            </Col>
            <div className={`dropdown-menu hide dropdown-menu-right ` + showCustomComparativeSelector} ref={divRef}>
                <div className="w-100 p-2">
                    <DateRangeSelect onSelectDate={selectPreviousDates} onChange={() =>{console.log("changed")}} />
                </div>
            </div>
        </Col>
    )
};

export default ComparativeSelect;
