import React, { useContext, useState, useEffect } from "react";
import { Card, Row, Col, Form } from "react-bootstrap";
import Chart from "react-apexcharts";
import moment from "moment";

import { productPerformance } from "../../../api/brandData";

import { AuthContext } from "../../../contexts/AuthContext";
import { BrandContext } from "../../../contexts/BrandContext";
import { HeaderContext } from "../../../contexts/HeaderContext";
import { ToastContext } from "../../../contexts/ToastContext";

import {
    graph_green,
    graph_blue_light,
    graph_gray_dark,
} from "../../helper/ColorFormatter";
import WidgetSpinner from "../../helper/WidgetSpinner";
import ProductImageThumbnail from "../../helper/productImageThumbnail";

import {lineDollarFormatter} from "../../helper/NumberFormatters";

import BootstrapTablesWrapper from "../BootstrapTablesWrapper";

const renderSparkline = (data, label) => {
    let dates = [];
    let seriesData = [];
    
    let color = graph_blue_light;
    if (label === "Sales") {
        color = graph_green;
    }
    else if (label === "Units") {
        color = graph_gray_dark;
    }

    if (data !== undefined && data !== null && data.length > 0) {
        data.forEach((ele) => {
            dates.push(ele.date);
            seriesData.push(parseFloat(ele.value));
        });
    }

    let sparklineOptions = {
        chart: {
            type: "area",
            sparkline: {
                enabled: true,
            },
        },
        fill: {
            opacity: 0.3,
        },
        xaxis: {
            categories: dates,
            type: "category",
            show: false,
        },
        colors: [color],
        stroke: {
            width: 2,
        },
    };

    let series = [
        {
            name: label,
            data: seriesData,
            type: "line",
            show: true,
        },
    ];

    return <Chart options={sparklineOptions} series={series} type="area" height={55} />;
};

const defaultSorted = [
    {
        dataField: "total_sales",
        order: "desc",
    },
];

const ProductLevelSalesTable = (props) => {
    const { token } = useContext(AuthContext);
    const { selectedDefaultDates, currency, marketplace } = useContext(HeaderContext);

    const [responseData, setResponseData] = useState();
    const [widgetLoading, setWidgetLoading] = useState(true);
    const { brand } = useContext(BrandContext);
    const { toast } = useContext(ToastContext);

    const [showDiscontinued, setShowDiscontinued] = useState(true);
    const [tableData, setTableData] = useState();

    const {
        showConfigButton = false,
        headerTitle = "Product Level Sales",
        headerSubtitle = "Shows sales metrics in dollars and units and by channel at product level.  30 day sales rate, buy box percentage, and current QoH at end columns",
        widgetData = null,
        widgetDataLoading = null,
    } = props;

    useEffect(() => {
        const getData = async () => {
            if (widgetData !== null) {
                setWidgetLoading(true);
                setResponseData(widgetData);
                setWidgetLoading(false);
            } else {
                setWidgetLoading(true);
                try {
                    // Use snapshot / time period data
                    const res = await productPerformance(token, {
                        brandid: brand.brandId,
                        tf_s: moment(selectedDefaultDates.tp_s).format('YYYY-MM-DD'),
                        tf_e: moment(selectedDefaultDates.tp_e).format('YYYY-MM-DD'),
                        wow: selectedDefaultDates.timeframe,
                        currency: currency,
                        marketplace: marketplace,
                    });

                    setResponseData(res.data);
                    setTableData(res.data?.tableData);

                    // console.log(res.data);
                    setWidgetLoading(false);
                } catch (err) {
                    setWidgetLoading(false);
                    toast(err.message);
                }
            }
        };

        getData();
    }, [
        // Context
        token,
        brand.brandId,
        toast,
        currency,
        marketplace,
        selectedDefaultDates,

        // Props
        widgetData,
        widgetDataLoading,
    ]);

    const printArrayCSV = (row, key, child = undefined) => {
        if (child) {
            return row[key]?.map(obj => obj[child] ?? '').join(', ');
        } else {
            return row[key]?.map(value => value ?? '').join(', ');
        }
    }

    const tableColumns = [
        /*0*/ { dataField: "index", text: "#", type: "number", hidden: true },
        /*1*/ {
            dataField: "salesChannel",
            text: "Sales Channel",
            type: "string",
            sort: true,
            hidden: true,
        },
        /*2*/ {
            dataField: "vpart",
            text: "Vpart",
            type: "string",
            sort: true,
            hidden: true,
            csvFormatter: (cell, row, rowIndex) => {
                return '="'+(
                    (row['vpart_alt'] !== null && row['vpart_alt'] !== undefined && row['vpart_alt'] !== '' ? row['vpart_alt'] : row['vpart'])
                )+'"';
            }
        },
        /*3*/ {
            dataField: "upc",
            text: "UPC",
            type: "string",
            sort: true,
            hidden: true,
            filterValue: (cell, row) => cell ?? '',
            csvFormatter: (cell, row, rowIndex) => {
                return (
                    `="${row['upc']}"`
                );
            }
        },
        /*4*/{
            dataField: "asin", 
            text: "ASIN", 
            type: "string", 
            sort: true, 
            hidden: true,
            sortValue: (cell, row) => (cell['asin'] ?? ''),
            formatter: (cell, row, rowIndex) => printArrayCSV(row, 'asin', 'asin'),
            filterValue: (cell, row) => printArrayCSV(row, 'asin', 'asin'),
            csvFormatter: (cell, row, rowIndex) => printArrayCSV(row, 'asin', 'asin')
        },
        /*5*/{   // Combine Vpart + Short Title + Image
            dataField: "short_title",
            text: "Short Title / Vpart",
            sort: true,
            type: "string",
            filter: "textFilter",
            headerFormatter: (column, index, { sortElement, filterElement }) => {
                return (
                    <div className="d-flex flex-column w-100">
                        <Row className="mb-2">
                            <Col>
                                <p className="mb-0">Products Vparts {sortElement}</p>
                            </Col>
                            <Col>
                                <div className="d-flex align-items-center">
                                    <label className="mb-0 mr-1" style={{whiteSpace:'nowrap'}}>Include Discontinued Items</label>
                                    <Form.Check 
                                        id={`include-discontinued-toggle-switch`}
                                        type="switch"
                                        disabled={false}
                                        checked={showDiscontinued}
                                        onChange={(e) => handleDiscontinuedToggle(e.target.checked)}
                                    />
                                </div>
                            </Col>
                        </Row>
                        {filterElement}
                    </div>
                );
            },
            headerStyle: (colum, colIndex) => {
                return { minWidth: '350px' };
            },
            sortValue: (cell, row) => (row['vpart_alt'] !== null && row['vpart_alt'] !== undefined && row['vpart_alt'] !== '' ? row['vpart_alt'] : row['vpart']), // we use type name to sort.
            filterValue: (cell, row) => `${row['short_title']} ${row['vpart_alt']} ${row['vpart']} ${row['sku']}`,
            formatter: (cell, row, rowIndex) => {
                return (
                    <div className="d-flex flex-row flex-nowrap align-items-center">
                        <ProductImageThumbnail
                            imageSrc={row["image"]}
                            imageLink={(row['asin'].length > 0 ? row['asin'][0]['asinLink'] : '')}
                            imageAlt={(row['vpart_alt'] !== null && row['vpart_alt'] !== undefined && row['vpart_alt'] !== '' ? row['vpart_alt'] : row['vpart'])}
                        />
                        <div className="d-flex flex-column w-100 ml-2">
                            <p className="mb-0">{row['short_title']}</p>
                            <div className="d-flex flex-row justify-content-between text-sm">
                                <div className="mr-2">
                                    <p className="mb-0">{(row['vpart_alt'] !== null && row['vpart_alt'] !== undefined && row['vpart_alt'] !== '' ? row['vpart_alt'] : row['vpart'])}</p>
                                    {row['is_discontinued'] === 1 ? 
                                        <p className="mb-0" style={{color:"rgba(23, 42, 90, 0.97)"}}>Discontinued</p>
                                    : '' }
                                </div>
                                <div>
                                    {  row['asin'] !== undefined && row['asin'].length > 0 ? (row['asin'].map((value) => {
                                        return (    
                                            <span className="mr-2" key={value['asin']}>
                                                <a href={value['asinLink']} target={`_${value['asin']}`}>
                                                    {value['asin']}
                                                </a>
                                            </span>
                                        )
                                        
                                    })) : ''}
                                </div>
                                {/* <p>{row['sku'] !== undefined ? row['sku']?.split(',').join(', ') : row['sku']}</p> */}
                            </div>
                        </div>
                    </div>
                );
            }
        },
        /*6*/ {
            dataField: "total_sales",
            text: "Total Sales",
            type: "number",
            sort: true,
            formatter: "dollarFormatter",
        },
        /*7*/ {
            dataField: "pt_total_sales",
            text: "Previous Days",
            type: "number",
            sort: true,
            formatter: "dollarFormatter",
        },
        /*8*/ {
            dataField: "previous_sales_change",
            text: "Sales Change ($)",
            type: "number",
            sort: true,
            formatter: "tableColorDollarFormatter",
        },
        /*9*/ {
            dataField: "previous_sales_change_pct",
            text: "Sales Change (%)",
            type: "number",
            sort: true,
            formatter: "percentColorFormatter",
        },
        /*10*/ {
            dataField: "py_total_sales",
            text: "Previous Year",
            type: "number",
            sort: true,
            hidden: false,
            formatter: "dollarFormatter",
        },
        /*11*/ {
            dataField: "previous_year_change",
            text: "PY Change ($)",
            type: "number",
            sort: true,
            hidden: false,
            formatter: "tableColorDollarFormatter",
        },
        /*12*/ {
            dataField: "previous_year_change_pct",
            text: "PY Change (%)",
            type: "number",
            sort: true,
            hidden: false,
            formatter: "percentColorFormatter",
        },
        /*13*/ {
            dataField: "total_units",
            text: "Total Units",
            type: "number",
            sort: true,
            formatter: "intFormatter",
        },
        /*14*/ {
            dataField: "sns_units",
            text: "SNS Units",
            type: "number",
            sort: true,
            formatter: "intFormatter",
        },
        /*15*/ {
            dataField: "organic_units",
            text: "Organic Units",
            type: "number",
            sort: true,
            formatter: "intFormatter",
        },
        /*16*/ {
            dataField: "paid_units",
            text: "Paid Units",
            type: "number",
            sort: true,
            formatter: "intFormatter",
        },
        /*17*/ {
            dataField: "30d_sales",
            text: "30 Day Sales",
            type: "number",
            sort: true,
            formatter: "dollarFormatter",
            headerClasses: "bg-gray-light",
            classes: "bg-gray-light",
        },
        /*18*/ {
            dataField: "30d_bbx_percent",
            text: "30D Avg BBX",
            type: "number",
            sort: true,
            formatter: "percentBBXColorFormatter",
            headerClasses: "bg-gray-light",
            classes: "bg-gray-light",
        },
        /*19*/ {
            dataField: "sparkline_sales",
            text: "Sales Sparkline",
            sort: false,
            formatter: (cell, row, rowIndex) => {
                return <div>{renderSparkline(cell, "Sales")}</div>;
            },
            headerStyle: (colum, colIndex) => {
                return { minWidth: "150px" };
            },
            csvFormatter: (cell, row, rowIndex) => {
                return '';
            }
        },
        /*20*/ {
            dataField: "sparkline_units",
            text: "Units Sparkline",
            sort: false,
            formatter: (cell, row, rowIndex) => {
                return <div>{renderSparkline(cell, "Units")}</div>;
            },
            headerStyle: (colum, colIndex) => {
                return { minWidth: "150px" };
            },
            csvFormatter: (cell, row, rowIndex) => {
                return '';
            }
        },
        /*21*/{
            dataField: 'days_oh', 
            text: 'Days On Hand', 
            type: 'number', 
            sort: true,
            formatter: "intFormatter",
            headerClasses: 'bg-gray-light',
            classes: 'bg-gray-light',
        },
        /*22*/{
            dataField: 'weeks_oh', 
            text: 'Weeks On Hand', 
            type: 'number', 
            sort: true,
            formatter: "intFormatter",
            headerClasses: 'bg-gray-light',
            classes: 'bg-gray-light',
        },
        /*23*/{
            dataField: 'lw_units', 
            text: 'Last Week Units', 
            type: 'number', 
            sort: true,
            formatter: "intFormatter",
            headerClasses: 'bg-gray-light',
            classes: 'bg-gray-light',
        },
        /*24*/{
            dataField: '30d_units', 
            text: 'Last 30 Day Units', 
            type: 'number', 
            sort: true,
            formatter: "intFormatter",
            headerClasses: 'bg-gray-light',
            classes: 'bg-gray-light',
        },
        /*25*/{
            dataField: '90d_units', 
            text: 'Last 90 Day Units', 
            type: 'number', 
            sort: true,
            formatter: "intFormatter",
            headerClasses: 'bg-gray-light',
            classes: 'bg-gray-light',
        },
        /*26*/{
            dataField: 'fba_sku',
            text: 'FBA SKU',
            type: 'string',
            sort: true,
            hidden: true,
            headerClasses: 'bg-gray-light',
            classes: 'bg-gray-light',
            sortValue: (cell, row) => (cell['fba_sku'] ?? ''),
            formatter: (cell, row, rowIndex) => printArrayCSV(row, 'fba_sku'),
            filterValue: (cell, row) => printArrayCSV(row, 'fba_sku'),
            csvFormatter: (cell, row, rowIndex) => printArrayCSV(row, 'fba_sku'),
        },
        /*27*/{
            dataField: 'fbm_sku',
            text: 'FBM SKU',
            type: 'string',
            sort: true,
            hidden: true,
            headerClasses: 'bg-gray-light',
            classes: 'bg-gray-light',
            sortValue: (cell, row) => (cell['fbm_sku'] ?? ''),
            formatter: (cell, row, rowIndex) => printArrayCSV(row, 'fbm_sku'),
            filterValue: (cell, row) => printArrayCSV(row, 'fbm_sku'),
            csvFormatter: (cell, row, rowIndex) => printArrayCSV(row, 'fbm_sku')
        },
        /*28*/{
            dataField: 'fba_bbx_percent',
            text: 'FBA BuyBox %',
            type: 'number',
            sort: true,
            hidden: true,
            formatter: "percentColorFormatter",
            headerClasses: 'bg-gray-light',
            classes: 'bg-gray-light',
        },
        /*29*/{
            dataField: 'fbm_bbx_percent',
            text: 'FBM BuyBox %',
            type: 'number',
            sort: true,
            hidden: true,
            formatter: "percentColorFormatter",
            headerClasses: 'bg-gray-light',
            classes: 'bg-gray-light',
        },
    ];

    const handleDiscontinuedToggle = (value) => {
        // console.log('handleDiscontinuedToggle', value);

        if (responseData?.tableData.length > 0) {
            // let tempDataTable = responseData.tableData;
            // Off. = Exclude discontinued
            if (value === false) {
                let tempDataTable = responseData.tableData.filter((row) => row.is_discontinued === 0);
                console.log('newData', tempDataTable);
                setTableData(tempDataTable);
            }
            else {
                // On. = Include Discontinued = Reset data
                setTableData(responseData.tableData);
            }
        }
        
        setShowDiscontinued(value);
    }

    const rowStyle = (row, rowIndex) => {
        return row['is_discontinued'] === 1 ? {backgroundColor: '#E1E3F4'} : {};
    };

    const renderTable = () => {
        let newCols = tableColumns;
        if (responseData?.info !== undefined) {
            if (responseData.info?.pt !== undefined) {
                let ptTimeframeInfo = responseData.info.pt;
                let pt_sales = lineDollarFormatter(ptTimeframeInfo.pt_sales, currency, brand.currencyLocale);
                // Previous Timeframe Columns

                // Previous Days
                newCols[7].text = `Previous ${ptTimeframeInfo.diff} Days`;
                newCols[7].headerAttrs = {
                    title: `${ptTimeframeInfo.pt_s} - ${ptTimeframeInfo.pt_e}: ${pt_sales}`,
                };
                newCols[8].headerAttrs = {
                    title: `${ptTimeframeInfo.pt_s} - ${ptTimeframeInfo.pt_e}: ${pt_sales}`,
                };
                newCols[9].headerAttrs = {
                    title: `${ptTimeframeInfo.pt_s} - ${ptTimeframeInfo.pt_e}: ${pt_sales}`,
                };

                // Previous Year
                let pyTimeframeInfo = responseData.info.py;
                newCols[10].headerAttrs = { title: `${pyTimeframeInfo.py_s} - ${pyTimeframeInfo.py_e}`, };
                newCols[11].headerAttrs = { title: `${pyTimeframeInfo.py_s} - ${pyTimeframeInfo.py_e}`, };
                newCols[12].headerAttrs = { title: `${pyTimeframeInfo.py_s} - ${pyTimeframeInfo.py_e}`, };
            }
            if (responseData.info?.pm !== undefined) {
                let pmTimeframeInfo = responseData.info.pm;
                // 30 Day Columns
                newCols[17].headerAttrs = { title: `${pmTimeframeInfo.pm_s} - ${pmTimeframeInfo.pm_e}` };
                newCols[18].headerAttrs = { title: `${pmTimeframeInfo.pm_s} - ${pmTimeframeInfo.pm_e}` };
            }

            if (responseData.info?.lw !== undefined) {
                let lwTimeframeInfo = responseData.info.lw;
                // Last Week
                newCols[23].headerAttrs = { title: `${lwTimeframeInfo.lw_s} - ${lwTimeframeInfo.lw_e}` }
            }

            // newCols[18].headerAttrs = { title: 'QoH = Fulfillable + Amazon Inbound + Amazon Reserved' }

            // newCols[24].headerAttrs = { title: 'QoH Inbound = QoH + In Transit + On PO' }
            newCols[24].headerAttrs = { title: 'Last 30 Days, not including today' }
            newCols[25].headerAttrs = { title: 'Last 90 Days, not including today' }
            // console.log(responseData.info, newCols);
        }

        return (
            <BootstrapTablesWrapper
                data={tableData}
                keyField="index"
                columns={newCols}
                defaultSorted={defaultSorted}
                tableOnly={true}
                columnModal={true}
                hasPagination={true}
                hasFilter={true}
                headerNowrap={false}
                rowNowrap={true}
                wrapperClasses={'min-border-table'}
            />
        );
    };

    return (
        <Card className="h-100">
            <Card.Header className="bg-white">
                <div className="d-flex flex-row align-items-center">
                    <div>
                        <h5>{headerTitle}</h5>
                        {headerSubtitle !== "" ? <p className="body-1 text-secondary mb-0">{headerSubtitle}</p> : ""}
                    </div>
                </div>
            </Card.Header>

            <Card.Body className="pb-0" style={{ minHeight: props.minHeight ?? "500px" }}>
                {widgetLoading ? (
                    <div className="w-100 h-100">
                        <WidgetSpinner />
                    </div>
                ) : (
                    <div className="w-100">{renderTable()}</div>
                )}
            </Card.Body>
        </Card>
    );
};

export default ProductLevelSalesTable;
