import React, { useEffect, useState, useContext } from "react";
import { Row, Col, Tabs, Tab } from "react-bootstrap";
import {HeaderContext} from "../../contexts/HeaderContext";
import PaidMedia from "./PaidMedia";
import SponsoredAds from "./SponsoredAds";
import ProductPerformance from "./ProductPerformance";
import DSPPerformance from "./DSPPerformance";

const Dashboard = (props) => {

    const { pageTitle, setPageTitle, setPageTimeframe, setComparativeOptions, setPageMarketplace, currency } = useContext(HeaderContext);
    const [activeTabTable, setActiveTabTable] = useState("summary");

    useEffect(() => {
        setPageTitle("Paid Media Dashboard");
        setPageMarketplace('Amazon');
        setPageTimeframe("timeframe");
        setComparativeOptions([
            { label: 'Previous Year', value: 'py' },
            { label: 'Previous Month', value: 'pmsd' },
            { label: 'Previous Days', value: 'pd' },
            { label: 'Custom Date', value: 'pc' },
        ])
        return () => {
            setPageTitle(process.env.REACT_APP_TITLE);
        };
    },[pageTitle]);




return (
    <div className="p-3">
        <Row className="mt-4">
            <Tabs
                activeKey={activeTabTable}
                onSelect={(k) => setActiveTabTable(k)}
                className="border-bottom mb-3"
                unmountOnExit={false}
            >
                <Tab eventKey={'summary'} title={'Paid Media Summary'}>
                    <PaidMedia />
                </Tab>

                <Tab eventKey={'sponsored'} title={'Sponsored Ads Targeting'}>
                    <SponsoredAds />
                </Tab>
                <Tab eventKey={'product'} title={'Product Performance'}>
                    <ProductPerformance />
                </Tab>
                <Tab eventKey={'dsp'} title={'DSP Performance'}>
                    <DSPPerformance />
                </Tab>
            </Tabs>
        </Row>
    </div>
)
}

export default Dashboard;