import React, { useContext } from 'react';

import { Route, Redirect } from 'react-router-dom';
import { AuthContext } from '../contexts/AuthContext';

const AuthUserInternalManagerRoute = ({ component: Component, noRedirect = false, ...rest }) => {
  const { apiUser, loading: isAuthLoading } = useContext(AuthContext);

  return !isAuthLoading && apiUser ? (
    apiUser.organizationid === 401 && (apiUser.role === 'Admin' || apiUser.role === 'Manager') ? (
      <Route {...rest} />
    ) : null
  ) : null;
};

export default AuthUserInternalManagerRoute;
