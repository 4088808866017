import fortress from './fortress';

export async function buyboxOverview(token, params) {
  return fortress.get('/resellers/buybox/overview', {
    headers: { Authorization: `Bearer ${token}` },
    params: params,
  });
}

export async function buyboxAsins(token, params) {
  return fortress.get('/resellers/buybox/asins', {
    headers: { Authorization: `Bearer ${token}` },
    params: params,
  });
}
