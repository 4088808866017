import React, { useEffect, useState, useContext } from 'react';
import { useAuth0 } from '@auth0/auth0-react';

import { AuthContext } from './../contexts/AuthContext';

import { heartbeat } from '../api/service';

const MaintenanceMode = ({ history }) => {
  const { checkMaintenanceMode } = useContext(AuthContext);
  const { loginWithRedirect } = useAuth0();

  const timer = 120;

  const [counter, setCounter] = useState(60);
  const [redirectSeconds, setRedirectSeconds] = useState(timer);

  useEffect(() => {
    const checkHeartbeat = async () => {
      try {
        const res = await heartbeat();

        console.log('heartbeat', res);

        if (checkMaintenanceMode(res)) {
          setRedirectSeconds(timer);
        } else {
          loginWithRedirect();
        }
      } catch (err) {
        if (checkMaintenanceMode(err)) {
          setRedirectSeconds(timer);
        }
      }
    };

    if (redirectSeconds === 0) {
      checkHeartbeat();
    }

    redirectSeconds > 0 &&
      setTimeout(() => setCounter(setRedirectSeconds(redirectSeconds - 1)), 1000);
  }, [redirectSeconds]);

  return (
    <div>
      <div
        style={{ height: '100vh' }}
        className="d-flex justify-content-center align-items-center bg-burgundy text-white"
      >
        <div className="text-center">
          <h1 className="text-center"> 503 Maintenance Mode </h1>
          <p className="mb-0">
            {' '}
            We are sorry for the inconvenience, the service is currently in Maintenance Mode. Please
            check back shortly.
          </p>
          <p className="mb-0 text-body-bold"> Checking Status in: {redirectSeconds} Seconds</p>
        </div>
      </div>
    </div>
  );
};

export default MaintenanceMode;
