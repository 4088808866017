import React, { useEffect, useState, useContext } from "react";
import { Row, Col, Card, Tabs, Tab } from "react-bootstrap";
import moment from "moment";

import { AuthContext } from "../../contexts/AuthContext";
import { BrandContext } from "../../contexts/BrandContext";
import { HeaderContext } from "../../contexts/HeaderContext";
import { ToastContext } from "../../contexts/ToastContext";

import { keywordanalysis } from "../../api/ppc";

import BootstrapTablesWrapper from "../../components/dataRender/BootstrapTablesWrapper";
import ChartDescription from "../../components/helper/ChartDescription";
import Loading from "../../components/helper/Loading";
import useIsMountedRef from "../../components/helper/isMountedRef";
import ProductImageThumbnail from "../../components/helper/productImageThumbnail";
import { formatValue } from "../../components/helper/NumberFormatters";

import { ScatterChart, Scatter, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from "recharts";

const KeywordAnalysis = (props) => {
    //Grabs user data from AuthContext.js
    const { token } = useContext(AuthContext);
    const { brand } = useContext(BrandContext);
    const { toast } = useContext(ToastContext);
    const { setPageTitle, setPageTimeframe, setPageDisplayTimeFrameInterval, setPageMarketplace, selectedDefaultDates, currency, marketplace } = useContext(HeaderContext);

    const defaultPurposeData = {
        Performance: undefined,
        Defensive: undefined,
        Research: undefined,
    }
    const [purpose, setPurpose] = useState("Performance");

    const [responseData, setResponseData] = useState();
    const [purposeData, setPurposeData] = useState(defaultPurposeData);

    const [dataLoading, setDataLoading] = useState(true);

    const isMountedRef = useIsMountedRef();

    const columns = [
        {
            text: "Product",
            dataField: "vpart",
            headerStyle: (colum, colIndex) => {
                return { width: "270px" };
            },
            formatter: (cell, row, rowIndex) => {
                let asinLink = row["asinLink"] ?? `https://www.amazon.com/dp/${row["asin"]}`;

                let image = (
                    <ProductImageThumbnail
                        imageSrc={row['smallImage']}
                        imageLink={asinLink}
                        imageAlt={(row['vpart_alt'] !== null && row['vpart_alt'] !== undefined && row['vpart_alt'] !== '' ? row['vpart_alt'] : row['vpart'])}
                    />
                );
                return (
                    <div className="d-flex flex-row flex-nowrap align-items-center">
                        {row["smallImage"] ? image : ""}
                        <div className="d-flex flex-column w-100 ml-2">
                            <a href={asinLink} target={`_${row["asin"]}`}>
                                <p className="mb-0">{row["canonical_adGroup"]}</p>
                            </a>
                            <div className="d-flex flex-row justify-content-between text-sm">
                                <p className="mb-0">{(row['vpart_alt'] !== null && row['vpart_alt'] !== undefined && row['vpart_alt'] !== '' ? row['vpart_alt'] : row['vpart'])}</p>
                                <p>
                                    <a href={asinLink} target={`_${row["asin"]}`}>
                                        {row["asin"]}
                                    </a>
                                </p>
                                {/* <p>{row['sku'] !== undefined ? row['sku']?.split(',').join(', ') : row['sku']}</p> */}
                            </div>
                        </div>
                    </div>
                );
            },
            csvFormatter: (cell, row, rowIndex) => {
                return (
                    (row['vpart_alt'] !== null && row['vpart_alt'] !== undefined && row['vpart_alt'] !== '' ? row['vpart_alt'] : row['vpart'])
                );
            }
        },
        {
            text: "Keyword",
            dataField: "keywordText",

            sort: true,
            classes: "font-weight-bold",
        },
        {
            text: "Match Type",
            dataField: "matchType",
            classes: "font-weight-bold",
            sort: true,
        },
        {
            text: "Impressions",
            dataField: "Impressions",
            sort: true,
            sortValue: (cell, row) => parseInt(cell),
            formatter: "intFormatter",
        },
        {
            text: "Clicks",
            dataField: "Clicks",
            sort: true,
            sortValue: (cell, row) => parseInt(cell),
            formatter: "intFormatter",
        },
        {
            text: "Spend",
            dataField: "Spend",
            sort: true,
            sortValue: (cell, row) => parseFloat(cell),
            formatter: "intlCurrencyFormatter",
        },
        {
            text: "Sales",
            dataField: "Sales",
            sort: true,
            sortValue: (cell, row) => parseFloat(cell),
            formatter: "intlCurrencyFormatter",
        },
        {
            text: "RoAS",
            dataField: "RoAS",
            sort: true,
            sortValue: (cell, row) => parseFloat(cell),
            formatter: "ratioFormatter",
        },
    ]

    /**
     * useEffect with no second arguments = Run on every render
     *
     * Return statement is the cleanup function, runs when the component unmounts (Usually used to cleanup. Here it is used to clean up the page title)
     */
    useEffect(() => {
        setPageTitle("Keyword Analysis");
        setPageMarketplace('Amazon');
        setPageTimeframe("snapshot");
        setPageDisplayTimeFrameInterval(false);
        return () => {
            setPageTitle(process.env.REACT_APP_TITLE);
        };
    });

    /**
     * useEffect with empty array as second argument = Run only once (on the first render)
     */
    useEffect(() => {
        // Reset the purpose data when the request (besides purpose) changes
        setPurposeData(defaultPurposeData);
    }, [token, brand, selectedDefaultDates, currency, marketplace]);

    useEffect(() => {
        const getData = async () => {
            try {
                const res = await keywordanalysis(token, {
                    brandid: brand.brandId,
                    tf_s: moment(selectedDefaultDates.tp_s).format('YYYY-MM-DD'),
                    tf_e: moment(selectedDefaultDates.tp_e).format('YYYY-MM-DD'),
                    purpose: purpose,
                    currency: currency,
                    marketplace: marketplace,
                });

                let floats = ["Clicks", "Impressions", "RoAS", "Sales", "Spend"];

                //Convert strings to numbers, otherwise recharts min max axis parses are not accurate
                res.data.map((val) => {
                    Object.keys(val).forEach((key) => {
                        if (key in floats) {
                            val[key] = parseFloat(val[key]);
                        }
                    });
                    return val;
                });
                
                if (isMountedRef.current) {
                    setResponseData(res.data);
                }
            } catch (err) {
                setDataLoading(false);
                toast(err.message);
            }
        };


        if (isMountedRef.current) {
            setDataLoading(true);
            if (!purposeData[purpose]) {
                getData();
            }
            else {
                setDataLoading(false);
            }
        }
    }, [
        // Context
        token,
        toast,
        brand,
        currency,
        marketplace,
        selectedDefaultDates,

        // Props/Widget
        purposeData,
        purpose,
    ]);

    useEffect(() => {
        let tempPurposeData = purposeData;
        tempPurposeData[purpose] = responseData ?? [];

        setPurposeData(tempPurposeData);
        setDataLoading(false);
    }, [responseData]);

    const CustomTooltip = ({ active, payload, label }) => {
        if (active) {
            return (
                <div className="card">
                    <h5 className="card-header text-primary">
                        {payload[0].payload.keywordText} - {payload[0].payload.matchType}
                    </h5>
                    <div className="card-body">
                        <h5 className="card-title" style={{ fontWeight: "normal" }}>
                            {payload[0].name}: {formatValue(payload[0].value, 'intlCurrencyFormatter', brand, currency)}
                        </h5>
                        <h5 className="card-title" style={{ fontWeight: "normal" }}>
                            {payload[1].name}: {formatValue(payload[1].value, 'intlCurrencyFormatter', brand, currency)}
                        </h5>
                        <h5 className="card-title" style={{ fontWeight: "normal" }}>
                            RoAS: {(payload[1].value / payload[0].value).toFixed(2)}x
                        </h5>
                    </div>
                </div>
            );
        }

        return null;
    };

    const renderDataTable = (tableData) => {
        return (
            <BootstrapTablesWrapper
                keyField="keywordId"
                data={tableData ?? []}
                columns={columns}
                tableOnly={false}
                description={
                    <ChartDescription
                        title={"Keyword Performance"}
                        description={"Tracks the efficiency of converting keywords during the time period and their in depth metrics."}
                    />
                }
                hasSearch={true}
                hasFilter={true}
                hasPagination={true}
                sizePerPage={20}
                defaultSorted={[
                    {
                        dataField: "Sales",
                        order: "desc",
                    },
                ]}
                // search={{
                //     searchFormatted: true,
                // }}
            />
        );
    };

    const renderChart = (chartData) => {
        return (
            <Card>
                <Card.Header className="white bg-white">
                    <div className="d-flex flex-row align-items-center">
                        <div>
                            <p className="text-uppercase small mb-1" style={{ fontSize: "16px" }}>
                                <strong>{"Keyword Performance"}</strong>
                            </p>
                        </div>
                    </div>
                </Card.Header>
                <Card.Body>
                    <Row className="flex-shrink-1 flex-grow-1" style={{ height: "800px", minWidth: "800px", width: "100%" }}>
                        <ResponsiveContainer>
                            <ScatterChart width={400} height={400} margin={{ top: 20, right: 20, bottom: 20, left: 20 }}>
                                <XAxis
                                    type="number"
                                    dataKey={"Spend"}
                                    name="Spend"
                                    label={{ value: "Spend", position: "insideBottom", offset: -2 }}
                                    tickCount={10}
                                />
                                <YAxis
                                    type="number"
                                    dataKey={"Sales"}
                                    name="Sales"
                                    label={{ value: "Sales", angle: -90, position: "insideLeft" }}
                                    tickCount={10}
                                />
                                {/*<ZAxis dataKey={"z"} range={[60, 400]} name="score" unit="km" />*/}
                                <CartesianGrid />
                                <Tooltip content={<CustomTooltip />} />
                                <Legend />

                                <Scatter name="Keywords by Spend and Sales" data={chartData} fill="#0878FF"></Scatter>
                            </ScatterChart>
                        </ResponsiveContainer>
                    </Row>
                </Card.Body>
            </Card>
        );
    };

    return (
        <main className="d-flex justify-content-start align-items-top">
            <Col>
                <Row className={"mt-5"}>
                    <Col>
                        <Tabs activeKey={purpose} onSelect={(k) => setPurpose(k)}>
                            <Tab eventKey={"Performance"} title={"Performance"}>
                                <div className="mt-4">
                                    {dataLoading ? <Loading /> : renderDataTable(purposeData.Performance)}
                                </div>
                                <div className="border-top pt-4">
                                    {dataLoading ? <Loading /> : renderChart(purposeData.Performance)}
                                </div>
                            </Tab>
                            <Tab eventKey={"Defensive"} title={"Defensive"}>
                                <div className="mt-4">
                                    {dataLoading ? <Loading /> : renderDataTable(purposeData.Defensive)}
                                </div>
                                <div className="border-top pt-4">
                                    {dataLoading ? <Loading /> : renderChart(purposeData.Defensive)}
                                </div>
                            </Tab>
                            <Tab eventKey={"Research"} title={"Research"}>
                                <div className="mt-4">
                                    {dataLoading ? <Loading /> : renderDataTable(purposeData.Research)}
                                </div>
                                <div className="border-top pt-4">
                                    {dataLoading ? <Loading /> : renderChart(purposeData.Research)}
                                </div>
                            </Tab>
                        </Tabs>
                    </Col>
                </Row>
            </Col>
        </main>
    );
};

export default KeywordAnalysis;
