import React, { useContext, useState, useEffect } from "react";
import { ResponsiveContainer } from "recharts";
import Chart from "react-apexcharts";

import WidgetSpinner from "../../../components/helper/WidgetSpinner";

function BundlePie(props) {
    // console.log('BundlePie', props);
    
    const {
        widgetID,
        headerTitle = "Bundle Percent of Sales",
        headerSubtitle,
        widgetDataLoading = true,
        widgetData,
        widgetFormatter,
    } = props;

    const [widgetLoading, setWidgetLoading] = useState(widgetDataLoading);

    useEffect(() => {
        setWidgetLoading(widgetDataLoading && widgetData);
    }, [widgetDataLoading, widgetData]);

    const renderChart = () => {
        const widgetSeries = widgetData?.data ?? [];
        const widgetColors = widgetData?.backgroundColor ?? [];
        const widgetLabels = widgetData?.labels ?? [];
        
        // console.log('BundlePie::renderChart', {widgetSeries, widgetColors, widgetLabels});
        
        const chartOptions = {
            chart: {
                id: widgetID,
                type: "donut",
            },
            legend: { 
                show: true, 
                position: "bottom", 
                horizontalAlign: "center", 
                floating: false, 
                offsetY: 0, 
                offsetX: 0 
            },
            colors: widgetColors,
            labels: widgetLabels,
            dataLabels: {
                enabled: false,
            },
            plotOptions: {
                pie: {
                    donut: {
                        size: "65%",
                        background: "#fff",
                    },
                }
            },
            tooltip: {
                shared: true,
                followCursor: true,
                custom: function({series, seriesIndex, dataPointIndex, w}) {
                    const seriesLabel = widgetLabels[seriesIndex] ?? w?.config?.labels[seriesIndex];
                    const seriesColor = widgetColors[seriesIndex] ?? w?.globals?.colors[seriesIndex] ?? 'rgba(0, 0, 0, 0.8)';

                    if (seriesLabel) {
                        return `
                            <div class="p-1 text-white text-wrap" style="background-color: ${seriesColor};">
                                <span>${seriesLabel}: </span>
                                <span>${widgetFormatter(series[seriesIndex])}</span>
                            </div>
                        `;
                    }

                    return '';
                },
            },
        };

        if (widgetLabels.length > 0
            && widgetSeries.length > 0
        ) {
            return (
                <ResponsiveContainer>
                    <Chart options={chartOptions} series={widgetSeries} type="donut" />
                </ResponsiveContainer>
            )
        }

        return (
            <span className="d-block h-100 w-100 align-content-center text-center text-bold bg-gray-light">No Data</span>
        );
    };

    return (
        <div className="card h-100 border-0" /*style={{ overflow: "auto" }}*/>
            <div className="card-header bg-white">
                <div className="d-flex flex-row align-items-center">
                    <div>
                        <h5>{headerTitle}</h5>
                        {headerSubtitle && (<p className="body-1 text-secondary mb-0">{headerSubtitle}</p>)}
                    </div>
                </div>
            </div>

            <div className="card-body w-100 h-100">{widgetLoading ? <WidgetSpinner /> : renderChart()}</div>
        </div>
    );
}

export default BundlePie;
