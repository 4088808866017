import React, { useContext, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import moment from "moment";

import { AuthContext } from "../../contexts/AuthContext";
import { useError } from "../../contexts/ErrorContext";
import { BrandContext } from "../../contexts/BrandContext";
import { HeaderContext } from "../../contexts/HeaderContext";
import { ToastContext } from "../../contexts/ToastContext";

import Loading from "../../components/helper/Loading";
import useIsMountedRef from "../../components/helper/isMountedRef";
import ChartDescription from "../../components/helper/ChartDescription";

import BootstrapTablesWrapper from "../../components/dataRender/BootstrapTablesWrapper";
import TemplateMetricsCard from "../../components/dataRender/dashboardCards/TemplateMetricsCard";

import PerformanceMetricsChart from "../../components/dataRender/dashboardCharts/PerformanceMetricsChart";
import TopProducts from "../BrandReport/widgets/TopProducts";

import { 
    summaryMetrics,
    performanceMetrics,
    summarySNS 
} from "../../api/brandData";

const BrandSummary = (props) => {
    // console.log("BrandSummary", props);

    const { token, visibleMetrics } = useContext(AuthContext);
    const { brand } = useContext(BrandContext);
    const { toast } = useContext(ToastContext);
    const { 
        setPageTitle,
        pageTitle, 
        setPageTimeframe,
        setPageDisplayTimeFrameInterval,
        setPageComparativeSelector,
        setComparativeOptions,
        currency,
        marketplace,
        selectedDefaultDates,
        selectedPreviousCustomDates,
        comparativeValue,
    } = useContext(HeaderContext);
    const reportError = useError();

    const [pageDataLoading, setPageDataLoading] = useState(true);
    const isMountedRef = useIsMountedRef();

    const [summaryMetricsData, setSummaryMetricsData] = useState();
    const [summaryMetricsInfo, setSummaryMetricsInfo] = useState();
    const [performanceChartData, setPerformanceChartData] = useState();
    const [summarySNSData, setSummarySNSData] = useState();

    useEffect(() => {
        setPageTitle("Brand Summary");
        setPageTimeframe("timeframe");
        setPageDisplayTimeFrameInterval(true);
        setComparativeOptions([
            { label: 'Previous Year', value: 'py' },
            { label: 'Previous Month', value: 'pmsd' },
            { label: 'Previous Days', value: 'pd' },
            { label: 'Custom Date', value: 'pc' },
        ])
        setPageComparativeSelector(true);
        return () => {
            setPageTitle(process.env.REACT_APP_TITLE);
        };
    }, [pageTitle]);

    useEffect(() => {
        const getSummaryMetricsData = async () => {
            try {
                // Use snapshot / time period data
                const res = await summaryMetrics(token, {
                    brandid: brand.brandId,
                    tf_s: moment(selectedDefaultDates.tf_s).format("YYYY-MM-DD"),
                    tf_e: moment(selectedDefaultDates.tf_e).format("YYYY-MM-DD"),
                    pc_s: selectedPreviousCustomDates.pc_s,
                    pc_e: selectedPreviousCustomDates.pc_e,
                    wow: selectedDefaultDates.timeframe,
                    marketplace: marketplace,
                    currency: currency,
                });

                if (isMountedRef.current) {
                    setPageDataLoading(false);
                    setSummaryMetricsData(res?.data?.data);
                    setSummaryMetricsInfo(res?.data?.info);
                }

                // console.log('getSummaryMetricsData', res?.data?.data);
            } catch (err) {
                toast(err.message);
                reportError(err);
            }
        };

        const getPerformanceChartData = async () => {
            try {
                const res = await performanceMetrics(token, {
                    brandid: brand.brandId,
                    tf_s: moment(selectedDefaultDates.tf_s).format('YYYY-MM-DD'),
                    tf_e: moment(selectedDefaultDates.tf_e).format('YYYY-MM-DD'),
                    wow: selectedDefaultDates.timeframe,
                    currency: currency,
                    marketplace: marketplace,
                });

                // console.log('getPerformanceChartData', res.data);
                if (isMountedRef.current) {
                    setPageDataLoading(false);
                    setPerformanceChartData(res?.data);
                }
            } catch (err) {
                toast(err.message);
                // reportError(err);
            }
        };

        const getSNSData = async () => {
            try {
                // Use Only BrandId, get latest
                const res = await summarySNS(token, {
                    brandid: brand.brandId,
                    tf_s: moment(selectedDefaultDates.tf_s).format("YYYY-MM-DD"),
                    tf_e: moment(selectedDefaultDates.tf_e).format("YYYY-MM-DD"),
                    wow: "daily",
                    marketplace: marketplace,
                    currency: currency,
                });

                if (isMountedRef.current) {
                    // setPageDataLoading(false);
                    setSummarySNSData(res?.data);
                }


                // console.log('getSNSData', res?.data);
            } catch (err) {
                toast(err.message);
                reportError(err);
            }
        };

        if (
            brand.brandId !== undefined &&
            selectedDefaultDates.tf_s !== undefined &&
            selectedDefaultDates.tf_e !== undefined &&
            selectedPreviousCustomDates !== undefined
        ) {
            setPageDataLoading(true);

            getSummaryMetricsData();
            getPerformanceChartData();
            getSNSData();
        }
    }, [token, brand, marketplace, selectedDefaultDates, selectedPreviousCustomDates, currency, isMountedRef, toast, reportError]);

    const templateMetricsCardDefault = {
        data: summaryMetricsData,
        dataMeta: summaryMetricsInfo,
        vsPrevious: comparativeValue.value,
        pageDataLoading: (isMountedRef.current && pageDataLoading && summaryMetricsData),
    }

    try {
        return pageDataLoading === true ? (
            <Loading></Loading>
        ) : (
            <main className="d-flex justify-content-start align-items-top">
                <Col>
                    <Row className="mt-5">
                        <Col lg={3}>
                            <Row>
                                <TemplateMetricsCard
                                    {...templateMetricsCardDefault}
                                    textCardProps={{
                                        headerTitle: "Sales Overview",
                                        direction: "vertical",
                                        variant: "info",
                                        minHeight: "auto",
                                    }}
                                    metricsTemplate={[
                                        {
                                            name: "Total Sales",
                                            metric: "total_sales",
                                            type: "currency",
                                            change_metric: "change_pct",
                                        },
                                        {
                                            name: "Paid (PPC)",
                                            metric: "paid_sales",
                                            change_metric: "paid_change_pct",
                                            type: "currency",
                                            tooltip: "Sales from PPC (Pay Per Click) Ad Campaigns",
                                            visible: visibleMetrics.paid,
                                        },
                                        {
                                            name: "SNS",
                                            metric: "sns_sales",
                                            type: "currency",
                                            tooltip: "Sales from Subscribe & Save",
                                            visible: visibleMetrics.sns,
                                        },
                                        {
                                            name: "Organic",
                                            metric: "organic_sales",
                                            change_metric: "organic_change_pct",
                                            type: "currency",
                                            tooltip: "Sales from organic search rankings",
                                            visible: visibleMetrics.paid,
                                        },
                                    ]}
                                />
                            </Row>
                        </Col>

                        <Col lg={9}>
                            <PerformanceMetricsChart
                                headerTitle={"Sales Performance"}
                                widgetData={performanceChartData?.lineChart}
                                widgetDataLoading={(isMountedRef.current && pageDataLoading)}
                            />
                        </Col>

                    </Row>

                    <Row className="d-flex flex-row">
                        <TemplateMetricsCard
                            visible={false}
                            {...templateMetricsCardDefault}
                            textCardProps={{
                                headerTitle: "PPC Sponsored Summary",
                                direction: "horizontal",
                                minHeight: "auto",
                            }}
                            metricsTemplate={[
                                {
                                    name: "Sales",
                                    metric: "paid_sales",
                                    change_metric: "paid_change_pct",
                                    type: "currency",
                                    tooltip: "Sales attributed to PPC advertising for the time frame",
                                    visible: visibleMetrics.paid,
                                },
                                {
                                    name: "Spend",
                                    metric: "paid_spend",
                                    type: "currency",
                                    tooltip: "Paid PPC spend for the time frame",
                                    visible: visibleMetrics.paid,
                                },
                                {
                                    name: "ROAS",
                                    metric: "roas",
                                    type: "ratio",
                                    tooltip: "PPC Return on Ad Spend = Paid Sales / Spend",
                                    visible: visibleMetrics.paid,
                                },
                                {
                                    name: "TACOS",
                                    metric: "tacos",
                                    type: "number_percent",
                                    tooltip: "PPC Total Advertising Cost of Sale = Paid Spend / Total Sales",
                                    visible: visibleMetrics.paid,
                                },
                            ]}
                        />

                        <TemplateMetricsCard
                            {...templateMetricsCardDefault}
                            width={"50%"}
                            textCardProps={{
                                headerTitle: "Order Details",
                                minHeight: "15em",
                            }}
                            metricsTemplate={[
                                {
                                    name: "Orders",
                                    metric: "total_orders",
                                    change_metric: "orders_change_pct",
                                    type: "number",
                                    tooltip: "Total Orders",
                                },
                                {
                                    name: "New to Brand",
                                    metric: "ntb",
                                    type: "number",
                                    tooltip: "FBA New to Brand Orders",
                                    visible: visibleMetrics.ntb,
                                },
                                {
                                    name: "Units",
                                    metric: "total_units",
                                    change_metric: "units_change_pct",
                                    type: "number",
                                    tooltip: "Total Units Sold",
                                },
                                {
                                    name: "Average Order Value",
                                    metric: "aov",
                                    type: "currency",
                                    format: {
                                        decimal: true,
                                        previous: {
                                            decimal: true,
                                        },
                                    },
                                    tooltip: "Average Order Value = Total Sales / Total Orders",
                                },
                            ]}
                        />

                        <TemplateMetricsCard
                            {...templateMetricsCardDefault}
                            width={"50%"}
                            textCardProps={{
                                headerTitle: "Traffic",
                                minHeight: "15em",
                            }}
                            metricsTemplate={[
                                {
                                    name: "Page Views",
                                    metric: "page_views",
                                    type: "number",
                                    tooltip: `
                                        "Hits in your Amazon.com presence for the selected time period as long
                                        as you had a valid offer" (whether you had the Buy Box or not);
                                        May be calculated as run rate: 
                                        ${summaryMetricsInfo?.bbx?.diff} of ${summaryMetricsInfo?.pt?.diff} days used.
                                    `,
                                    visible: visibleMetrics.traffic,
                                },
                                {
                                    name: "Sessions",
                                    metric: "sessions",
                                    type: "number",
                                    tooltip: `
                                        "Sessions are visits to your Amazon.com pages by a user. All activity within
                                        a 24-hour period is considered a session.";
                                        May be calculated as run rate:
                                        ${summaryMetricsInfo?.bbx?.diff} of ${summaryMetricsInfo?.pt?.diff} days used.
                                    `,
                                    visible: visibleMetrics.traffic,
                                },
                                {
                                    name: "BBX %",
                                    metric: "bbx",
                                    change_metric: "bbx_change",
                                    type: "percent",
                                    format: {
                                        previous: {
                                            decimal: true,
                                        },
                                    },
                                    tooltip: `
                                        "This is the percentage of page views where the buy box (the add to shopping
                                        cart link) appeared on the page for customers to add your product to their
                                        cart";
                                        Recent days may have limited data:
                                        ${summaryMetricsInfo?.bbx?.min} to ${summaryMetricsInfo?.bbx?.max} shown.
                                    `,
                                    visible: visibleMetrics.traffic,
                                },
                                {
                                    name: "CNV %",
                                    metric: "cnv",
                                    change_metric: "cnv_change",
                                    type: "percent",
                                    format: {
                                        previous: {
                                            decimal: true,
                                        },
                                        decimal: true,
                                    },
                                    tooltip: "Orders / Sessions",
                                    visible: visibleMetrics.traffic,
                                },
                            ]}
                        />

                        <TemplateMetricsCard
                            {...templateMetricsCardDefault}
                            width={"50%"}
                            textCardProps={{
                                headerTitle: "PPC Sponsored Traffic",
                                minHeight: "15em",
                            }}
                            metricsTemplate={[
                                {
                                    name: "Impressions",
                                    metric: "paid_impr",
                                    type: "number",
                                    tooltip: "PPC Advertising impressions for the time frame",
                                    visible: visibleMetrics.paid,
                                },
                                {
                                    name: "Clicks",
                                    metric: "paid_clicks",
                                    type: "number",
                                    format: {
                                        previous: {
                                            show_change: false,
                                            display_arrow: false,
                                            extra: " CTR",
                                            decimal: true,
                                            type: "percent",
                                            value: summaryMetricsData?.paid_ctr,
                                        },
                                    },
                                    tooltip: (
                                        <div>
                                            <p>Clicks on ads during the time frame</p>
                                            <p className="mb-0">Clickthrough Rate (Clicks / Impressions)</p>
                                        </div>
                                    ),
                                    visible: visibleMetrics.paid,
                                },
                                {
                                    name: "Orders",
                                    metric: "paid_orders",
                                    format: {
                                        previous: {
                                            show_change: false,
                                            display_arrow: false,
                                            extra: " CNV",
                                            decimal: true,
                                            type: "percent",
                                            value: summaryMetricsData?.paid_cnv,
                                        },
                                    },
                                    type: "number",
                                    tooltip: (
                                        <div>
                                            <p>Orders attributed to advertising during the time frame</p>
                                            <p className="mb-0">Conversion Rate (Orders / Clicks)</p>
                                        </div>
                                    ),
                                    visible: visibleMetrics.paid,
                                },
                                {
                                    name: "Units",
                                    metric: "paid_units",
                                    type: "number",
                                    tooltip: "Units sold attributed to advertising during the time frame",
                                    visible: visibleMetrics.paid,
                                },
                            ]}
                        />

                        <TemplateMetricsCard
                            visible={visibleMetrics.sns}
                            {...templateMetricsCardDefault}
                            width={"50%"}
                            textCardProps={{
                                headerTitle: "Subscribe & Save",
                                minHeight: "15em",
                            }}
                            metricsTemplate={[
                                {
                                    name: "Sales",
                                    metric: "sns_sales",
                                    type: "currency",
                                    tooltip: "Subscribe & Save revenue for the time frame",
                                    visible: visibleMetrics.sns,
                                },
                                {
                                    name: "Units",
                                    metric: "sns_units",
                                    type: "number",
                                    tooltip: "Subscribe & Save units shipped for the time frame",
                                    visible: visibleMetrics.sns,
                                },
                                {
                                    row_data: summarySNSData,
                                    name: "+8 Week Estimates",
                                    metric: "wk8_units",
                                    change_metric: (<div></div>),
                                    type: "number",
                                    tooltip: (
                                        <div>
                                            <p>
                                                Estimated units to ship in the next 8 weeks, as
                                                of {summarySNSData?.latest_date ?? "N/A"}
                                            </p>
                                        </div>
                                    ),
                                    visible: visibleMetrics.sns,
                                },
                                {
                                    row_data: summarySNSData,
                                    name: "Total Subscriptions",
                                    metric: "subscriptions",
                                    type: "number",
                                    format: {
                                        previous: {
                                            show_change: true,
                                            display_arrow: false,
                                            decimal: false,
                                            type: "number",
                                            value: summarySNSData?.previous_subscriptions_change,
                                        },
                                    },
                                    tooltip: (
                                        // +x (# of new Subscriptions since the most recent report BEFORE the time frame)
                                        // (mouseover: (New Subscriptions since $date)
                                        <div>
                                            <p>Total Subscriptions as of {summarySNSData?.latest_date ?? "N/A"}</p>
                                            <p>{`${summarySNSData?.previous_subscriptions_change} New Subscriptions since ${
                                                summarySNSData?.previous_date ?? "N/A"
                                            }`}</p>
                                            <p className="mb-0">
                                                # of new Subscriptions since the most recent report BEFORE the time
                                                frame
                                            </p>
                                        </div>
                                    ),
                                    visible: visibleMetrics.sns,
                                },
                            ]}
                        />
                    </Row>

                    <Row className="mt-4">
                        <Col sm={12}>
                            <div style={{ height: "34em" }}>
                                <TopProducts showConfigButton={false} />
                            </div>
                        </Col>
                    </Row>

                    <Row className="mt-4">
                        <Col>
                            {performanceChartData?.dataTable !== undefined && (
                                <BootstrapTablesWrapper
                                    data={performanceChartData?.dataTable}
                                    keyField="date"
                                    description={
                                        <ChartDescription
                                            title={"Sell Through Over Time by Sales Channel"}
                                            description={"Raw data of daily sales in dollar over time broken out by sales type"}
                                        />
                                    }
                                    hasPagination={true}
                                    hasFilter={true}
                                    defaultSorted={[
                                        {
                                            dataField: "date",
                                            order: "desc",
                                        },
                                    ]}
                                    columns={[
                                        {
                                            dataField: "date",
                                            text: "Date",
                                            sort: true,
                                            type: "date",
                                            insertFilter: "textFilter",
                                        },
                                        {
                                            dataField: "total_sales",
                                            text: "Total Sales",
                                            type: "number",
                                            sort: true,
                                            formatter: "dollarFormatter",
                                        },
                                        {
                                            dataField: "py_date",
                                            text: "PY Date",
                                            sort: true,
                                            type: "date",
                                            insertFilter: "textFilter",
                                            hidden: true,
                                        },
                                        {
                                            dataField: "py_total_sales",
                                            text: "Previous Year",
                                            type: "number",
                                            sort: true,
                                            formatter: "dollarFormatter",
                                            hidden: true,
                                        },
                                        {
                                            dataField: "py_change_total_sales",
                                            text: "PY Change ($)",
                                            type: "number",
                                            sort: true,
                                            formatter: "tableColorDollarFormatter",
                                            hidden: true,
                                        },
                                        {
                                            dataField: "py_change_pct_total_sales",
                                            text: "PY Change (%)",
                                            type: "number",
                                            sort: true,
                                            formatter: "percentColorFormatter",
                                            // hidden: false,
                                        },
                                        {
                                            dataField: "sns_sales",
                                            text: "SNS Sales",
                                            type: "number",
                                            sort: true,
                                            formatter: "dollarFormatter",
                                        },
                                        {
                                            dataField: "py_sns_sales",
                                            text: "PY SNS Sales",
                                            type: "number",
                                            sort: true,
                                            formatter: "dollarFormatter",
                                            hidden: true,
                                        },
                                        {
                                            dataField: "py_change_sns_sales",
                                            text: "PY SNS Change ($)",
                                            type: "number",
                                            sort: true,
                                            formatter: "tableColorDollarFormatter",
                                            hidden: true,
                                        },
                                        {
                                            dataField: "py_change_pct_sns_sales",
                                            text: "PY SNS Change (%)",
                                            type: "number",
                                            sort: true,
                                            formatter: "percentColorFormatter",
                                        },
                                        {
                                            dataField: "organic_sales",
                                            text: "Organic Sales",
                                            type: "number",
                                            sort: true,
                                            formatter: "dollarFormatter",
                                        },
                                        {
                                            dataField: "py_organic_sales",
                                            text: "PY Organic Sales",
                                            type: "number",
                                            sort: true,
                                            formatter: "dollarFormatter",
                                            hidden: true,
                                        },
                                        {
                                            dataField: "py_change_organic_sales",
                                            text: "PY Organic Change ($)",
                                            type: "number",
                                            sort: true,
                                            formatter: "tableColorDollarFormatter",
                                            hidden: true,
                                        },
                                        {
                                            dataField: "py_change_pct_organic_sales",
                                            text: "PY Organic Change (%)",
                                            type: "number",
                                            sort: true,
                                            formatter: "percentColorFormatter",
                                        },
                                        {
                                            dataField: "paid_sales",
                                            text: "Paid Sales",
                                            type: "number",
                                            sort: true,
                                            formatter: "intlCurrencyFormatter",
                                        },
                                        {
                                            dataField: "py_paid_sales",
                                            text: "PY Paid Sales",
                                            type: "number",
                                            sort: true,
                                            formatter: "intlCurrencyFormatter",
                                            hidden: true,
                                        },
                                        {
                                            dataField: "py_change_paid_sales",
                                            text: "PY Paid Change ($)",
                                            type: "number",
                                            sort: true,
                                            formatter: "intlColorCurrencyFormatter",
                                            hidden: true,
                                        },
                                        {
                                            dataField: "py_change_pct_paid_sales",
                                            text: "PY Paid Change (%)",
                                            type: "number",
                                            sort: true,
                                            formatter: "percentColorFormatter",
                                        },
                                    ]}
                                />
                            )}
                        </Col>
                    </Row>
                </Col>
            </main>
        );
    } catch (error) {
        reportError(error);
    }
};

export default BrandSummary;
