import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import { Router, HashRouter } from 'react-router-dom';

import AuthProvider from '../contexts/AuthContext';
import ToastProvider from '../contexts/ToastContext';
import SidebarProvider from '../contexts/SidebarContext';
import BrandProvider from '../contexts/BrandContext';
import HeaderProvider from '../contexts/HeaderContext';

import Routes from './Routes';
import history from '../history';

const App = () => {
  return (
    <ToastProvider>
      <AuthProvider>
        <SidebarProvider>
          <HeaderProvider>
            <BrandProvider>
              <Router history={history}>
                <HashRouter>
                  <Routes />
                </HashRouter>
              </Router>
            </BrandProvider>
          </HeaderProvider>
        </SidebarProvider>
      </AuthProvider>
    </ToastProvider>
  );
};

export default App;
