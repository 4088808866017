import fortress from './fortress';

export async function getBrand(token, params) {
  return fortress.get('brand', {
    headers: { Authorization: `Bearer ${token}` },
    params: params,
  });
}

export async function getVparts(token, params) {
  return fortress.get('brand/vpart', {
    headers: { Authorization: `Bearer ${token}` },
    params: params,
  });
}
