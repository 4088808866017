import React, { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { Navbar, Nav, Dropdown, Button, Col, Row } from 'react-bootstrap';
import moment from 'moment';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';

import { useError } from '../contexts/ErrorContext';

import { SidebarContext } from '../contexts/SidebarContext';
import { HeaderContext } from '../contexts/HeaderContext';
import { BrandContext } from '../contexts/BrandContext';
import { ToastContext } from '../contexts/ToastContext';

import CurrencyFilter from './header/CurrencyFilter';
import MarketplaceFilter from './header/MarketplaceFilter';
import DateTimeframeIntervalSelect from './header/DateTimeframeIntervalSelect';
import ComparativeSelect from './header/ComparativeSelect';

import question from '../assets/icons/svg/question.svg';
import DateRangeSelect from './header/DateRangeSelect';

const Header = ({ history }) => {
  const reportError = useError();

  const { collapsed, setCollapsed } = useContext(SidebarContext);
  const {
    // Page Title
    pageTitle,
    setPageTitle,

    // Header Configuration
    pageMarketplace,
    setPageMarketplace,

    // TimeFrame Interval
    timeFrameInterval,
    setTimeFrameInterval,
    displayTimeFrameInterval,
    setPageDisplayTimeFrameInterval,

    // Dates Configuration
    pageTimeframe,
    setPageTimeframe,
    setDates,
    selectedDefaultDates,
    getDefaultDates,
    verifyDates,

    // Dates Comparative Configuration
    pageComparativeSelector,
    setPageComparativeSelector,
  } = useContext(HeaderContext);

  const { brand } = useContext(BrandContext);
  const { toast } = useContext(ToastContext);

  const [displayStartDate, setDisplayStartDate] = useState(selectedDefaultDates?.tf_s);
  const [displayEndDate, setDisplayEndDate] = useState(selectedDefaultDates?.tf_e);
  const [tp_displayStartDate, setTPDisplayStartDate] = useState(selectedDefaultDates?.tp_s);
  const [tp_displayEndDate, setTPDisplayEndDate] = useState(selectedDefaultDates?.tp_e);
  const [displayTimeFrame, setDisplayTimeFrame] = useState(timeFrameInterval);

  // Render
  const [pageRender, setPageRender] = useState(null);
  const [infoPopUp, setInfoPopUp] = useState(false);

  // Responsive Vars
  const dateDropDownWidth = 500;

  const isMobile = useMediaQuery({ maxWidth: 768 });

  const xsDateDropdown = useMediaQuery({
    maxWidth: dateDropDownWidth + 75 + (!collapsed ? 270 : 0),
  });
  const [dateFilterResponsive, setDateFilterResponsive] = useState(xsDateDropdown ? true : false);

  const location = useLocation();

  useEffect(() => {
    document.title = `${pageTitle} | ${brand.brandName}`;
  }, [pageTitle, brand]);

  const setDisplayDates = (params) => {
    if (verifyDates(params) === true) {
      setDisplayStartDate(params?.tf_s);
      setDisplayEndDate(params?.tf_e);
      setTPDisplayStartDate(params?.tp_s);
      setTPDisplayEndDate(params?.tp_e);
      setDisplayTimeFrame(params?.timeframe);
    }
  };

  useEffect(() => {
    //On Page Change -> Reset Header Inputs to match the underlying value
    setPageTitle(process.env.REACT_APP_TITLE); // Default
    setPageMarketplace(true); // Defailt
    setPageTimeframe('timeframe'); // Default
    setPageDisplayTimeFrameInterval(false); // Default
    setPageComparativeSelector(false); // Default

    if (Object.keys(selectedDefaultDates).length === 0 || selectedDefaultDates === undefined) {
      const defaults = getDefaultDates();
      setDates(defaults);
      setDisplayDates(defaults);
    } else {
      setDisplayDates(selectedDefaultDates);
    }
  }, [location, setPageTitle, pageMarketplace]);

  useEffect(() => {
    // console.log('DateFilter:', xsDateDropdown);

    if ((isMobile && !collapsed) || xsDateDropdown) {
      setDateFilterResponsive(true);
    } else {
      setDateFilterResponsive(false);
    }
  }, [isMobile, collapsed, xsDateDropdown]);

  const displayCurrencyDropdown = () => {
    return (
      <Col className="px-0">
        <Col className="px-0" style={{ width: '8em' }}>
          <span className="body-1 text-enalytics-neon align-self-center">Currency</span>
        </Col>
        <Col className="px-0 w-100">
          <CurrencyFilter></CurrencyFilter>
        </Col>
      </Col>
    );
  };

  const displayMarketplaceDropdown = () => {
    if (!pageMarketplace) {
      return '';
    }

    return (
      <Col className="px-0">
        <Col className="px-0" style={{ width: '8em' }}>
          <span className="body-1 text-enalytics-neon align-self-center">Marketplace</span>
        </Col>
        <Col className="px-0 w-100">
          <MarketplaceFilter pageMarketplace={pageMarketplace}></MarketplaceFilter>
        </Col>
      </Col>
    );
  };

  const displayTimeFrameDropdown = () => {
    return (
      displayTimeFrameInterval && (
        <div className="w-100 p-2">
          <div className="px-0" style={{ width: '226px' }}>
            <DateTimeframeIntervalSelect
              value={displayTimeFrame}
              onChange={(value) => setDisplayTimeFrame(value)}
            />
          </div>
        </div>
      )
    );
  };

  const filterDisplayDate = (date) => {
    // Format the date
    const year = date.format('YYYY'); // year as four digits
    const month = date.format('MMM'); // month as a three-letter abbreviation
    const day = date.format('DD'); // day as two digits

    return `${month} ${day} ${year}`;
  };

  const displayDateDropdown = (start, end, timeframe = undefined) => {
    return (
      <span className="mr-2">
        {filterDisplayDate(start)} - {filterDisplayDate(end)}
      </span>
    );
  };

  const selectDates = (dates) => {
    const seletedDate = dates[0];

    if (seletedDate && seletedDate?.startDate) {
      if (pageTimeframe === 'timeframe') {
        setDisplayStartDate(moment(seletedDate.startDate));
        setDisplayEndDate(moment(seletedDate.endDate));
      }

      if (pageTimeframe === 'snapshot') {
        setTPDisplayStartDate(moment(seletedDate.startDate));
        setTPDisplayEndDate(moment(seletedDate.endDate));
      }

      const newDate = {
        tf_s: pageTimeframe === 'timeframe' ? moment(seletedDate.startDate) : displayStartDate,
        tf_e: pageTimeframe === 'timeframe' ? moment(seletedDate.endDate) : displayEndDate,
        tp_s: pageTimeframe === 'snapshot' ? moment(seletedDate.startDate) : tp_displayStartDate,
        tp_e: pageTimeframe === 'snapshot' ? moment(seletedDate.endDate) : tp_displayEndDate,
        timeframe: displayTimeFrame,
      };

      if (verifyDates(newDate) === false) {
        toast('Invalid Date');
      } else {
        setTimeFrameInterval(displayTimeFrame);
        setDates(newDate);
      }
    }
  };

  const displaySnapshot = () => {
    // console.log('displaySnapshot', tp_displayStartDate, tp_displayEndDate);

    return (
      <Dropdown id="dropdown-snapshot-container">
        <Dropdown.Toggle
          id="dropdown-snapshot"
          className="w-100 px-2 bg-white text-dark body-1 border-0"
          style={{ height: '38px' }}
        >
          {displayDateDropdown(tp_displayStartDate, tp_displayEndDate)}
        </Dropdown.Toggle>

        <Dropdown.Menu align="right" id="dropdown-snapshot-menu">
          <div className="w-100 p-2">
            <DateRangeSelect
              id="displaySnapshot-select"
              startDate={tp_displayStartDate}
              endDate={tp_displayEndDate}
              onSelectDate={(calDate) => selectDates(calDate)}
            />
          </div>
          {displayTimeFrameDropdown()}
        </Dropdown.Menu>
      </Dropdown>
    );
  };

  const displayTimeRange = () => {
    // console.log('displayTimeRange', displayStartDate, displayEndDate);

    return (
      <Dropdown id="dropdown-timeframe-container">
        <Dropdown.Toggle
          id="dropdown-timeframe"
          className="w-100 px-2 bg-white text-dark body-1 border-0"
          style={{ height: '38px' }}
        >
          {displayDateDropdown(displayStartDate, displayEndDate, displayTimeFrame)}
        </Dropdown.Toggle>

        <Dropdown.Menu align="right" id="dropdown-timeframe-menu">
          <div className="w-100 p-2">
            <DateRangeSelect
              id="displayTimeRange-select"
              startDate={displayStartDate}
              endDate={displayEndDate}
              onSelectDate={(calDate) => selectDates(calDate)}
            />
          </div>
          {displayTimeFrameDropdown()}
        </Dropdown.Menu>
      </Dropdown>
    );
  };

  const renderHeader = () => {
    switch (pageTimeframe) {
      case 'weekstart':
      case 'currency':
        // Only Currency + Marketplace (No Date Picker)
        return (
          <Row className="column-gap-3 row-gap-2">
            {displayMarketplaceDropdown()}
            {displayCurrencyDropdown()}
          </Row>
        );
      case 'snapshot': // Snapshot Date Range (No Date Interval Selector)
      case 'timeframe': // TimeFrame Date Range (With Date Interval Selector)
        return (
          <Row className="column-gap-3 row-gap-2">
            {displayMarketplaceDropdown()}
            {displayCurrencyDropdown()}
            <Col className="px-0">
              <Col className="px-0">
                <span className="body-1 text-enalytics-neon align-self-center">Time Range</span>
              </Col>
              <Col className="px-0">
                {pageTimeframe === 'snapshot' ? displaySnapshot() : displayTimeRange()}
              </Col>
            </Col>
            {pageComparativeSelector && <ComparativeSelect></ComparativeSelect>}
          </Row>
        );
      default:
        return null;
    }
  };

  useEffect(() => {
    try {
      setPageRender(renderHeader());
    } catch (error) {
      reportError(error);
    }
  }, [
    reportError,
    pageTimeframe,
    selectedDefaultDates,
    xsDateDropdown,
    displayStartDate,
    displayEndDate,
    tp_displayStartDate,
    tp_displayEndDate,
    displayTimeFrame,
    pageMarketplace,
    pageComparativeSelector,
    dateFilterResponsive,
  ]);

  const toggleInfoPopUp = () => {
    if (!infoPopUp) {
      setInfoPopUp(true);
    }
  };

  document.addEventListener('mousedown', (event) => {
    if (infoPopUp && event.target.closest('div.info-popup-container') === null) {
      setInfoPopUp(false);
    }
  });

  return (
    <Navbar sticky="top" className="border-bottom bg-black" style={{ padding: '10px 1em' }}>
      <Button
        className="btn bg-transparent border-0 text-enalytics-neon px-0 mr-4"
        style={{ minWidth: '18px' }}
        onClick={() => setCollapsed(!collapsed)}
      >
        <FontAwesomeIcon icon={faBars} />
      </Button>

      <Navbar.Brand>
        <h4 className="mb-0 text-enalytics-neon">{pageTitle}</h4>
      </Navbar.Brand>

      <Navbar.Collapse className="justify-content-end">
        <Nav className="align-items-center">
          <Col>
            <div className="text-enalytics-neon text-nowrap">
              <span className="pr-1">What&apos;s New</span>
              <img
                style={{ cursor: 'pointer', marginTop: '-5px' }}
                src={question}
                alt="question"
                width="25"
                height="25"
                onClick={toggleInfoPopUp}
              ></img>
            </div>
            {infoPopUp ? (
              <div className="info-popup-container">
                <div className="info-popup">
                  <p>What&apos;s New</p>
                  <ul>
                    <li>
                      You can now toggle between multiple sales metrics (Organic Sales, Paid Sales,
                      Repeat Customers, and more) to better analyze performance over time.
                    </li>
                    <li>Compare overlapping metrics to identify trends and correlations.</li>
                    <li>Opt to view a single metric for a more focused analysis.</li>
                  </ul>
                </div>
              </div>
            ) : null}
          </Col>
          <Col className="w-100 flex-fill">{pageRender && pageRender}</Col>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default Header;
