import React, { useContext, useState, useEffect } from "react";
import { ResponsiveContainer } from "recharts";
import Chart from "react-apexcharts";

import WidgetSpinner from "../../../components/helper/WidgetSpinner";

import { HeaderContext } from "../../../contexts/HeaderContext";

function SpendPie(props) {
    const { dataLoading } = useContext(HeaderContext);

    const [chartData, setChartData] = useState();
    // const [chartLabels, setChartLabels] = useState();
    const [widgetLoading, setWidgetLoading] = useState(true);

    const {
        headerTitle = "Bundle Percent of Sales",
        widgetData = null,
        widgetDataLoading = null,
    } = props;

    useEffect(() => {
        const getData = async () => {
            if (widgetData !== null) {
                // console.log(props.widgetID, 'bundlePie', widgetData);
                setWidgetLoading(true);
                setChartData(widgetData);
                setWidgetLoading(false);
            } else {
                setWidgetLoading(true);
            }
        };

        if (!dataLoading) {
            if (widgetDataLoading === null || widgetDataLoading === false) {
                getData();
            }
        }
    }, [
        dataLoading,
        widgetData,
        widgetDataLoading,
    ]);

    const renderChart = () => {
        if (chartData !== undefined) {

            var widgetColors = chartData[0]?.backgroundColor;
            var series = chartData[0]?.data;
            var widgetLabels = chartData[0]?.labels;

            var chartOptions = {
                chart: {
                    id: props.widgetID,
                    // height: "100%",
                    // width: "100%",
                    type: "pie",
                },
                legend: {
                    show: true,
                    position: "bottom",
                    horizontalAlign: "center",
                    floating: false,
                    offsetY: 0,
                    offsetX: 0
                },
                colors: widgetColors,
                labels: widgetLabels,
                dataLabels: {
                    enabled: false,
                },
                // plotOptions: {
                //     pie: {
                //         donut: {
                //             size: "65%",
                //             background: "#fff",
                //         },
                //     }
                // },
                tooltip: {
                    shared: true,
                    followCursor: true,
                    y: {
                        formatter: function (val, w) {
                            return props.widgetFormatter(val);
                        },
                    },
                },
            };
        }
        else {
            setWidgetLoading(true);
        }

        return chartOptions && widgetLabels && series && (
            <ResponsiveContainer>
                <Chart options={chartOptions} series={series} type="pie" />
            </ResponsiveContainer>
        );
    };

    return (
        <div className="card h-100 border-0" /*style={{ overflow: "auto" }}*/>
            <div className="card-header bg-white">
                <div className="d-flex flex-row align-items-center">
                    <div>
                        <h5>{headerTitle}</h5>
                        {props.headerSubtitle !== '' ? <p className="body-1 text-secondary mb-0">{props.headerSubtitle}</p> : ''}
                    </div>
                </div>
            </div>

            <div className="card-body w-100 h-100">{widgetLoading ? <WidgetSpinner /> : renderChart()}</div>
        </div>
    );
}

export default SpendPie;
