import React, { useEffect, useState, useContext } from "react";
import { Row, Col } from "react-bootstrap";
import {HeaderContext} from "../../contexts/HeaderContext";
import {AuthContext} from "../../contexts/AuthContext";
import {BrandContext} from "../../contexts/BrandContext";
import BootstrapTablesWrapper from "../../components/dataRender/BootstrapTablesWrapper";
import {IntFormatter} from "../../components/helper/NumberFormatters";
import fortress from "../../api/fortress";
import moment from "moment";
import WidgetSpinner from "../../components/helper/WidgetSpinner";
import SpendPie from "./charts/SpendPie";
import PerformanceOverTime from "./charts/PerformanceOverTime";

const DSPPerformance = (props) => {
    const { token } = useContext(AuthContext);
    const { brand } = useContext(BrandContext);
    const { selectedDefaultDates,currency,marketplace } = useContext(HeaderContext);

    const [pieChartLoading, setPieChartLoading] = useState(false);
    const [lineChartLoading, setLineChartLoading] = useState(false);
    const [tableLoading, setTableLoading] = useState(false);

    const pieColors = ["#c2d0f2","#8ea0d5","#c3d404","#9dac00","#a2566c","#84344e"];

    const [lineChartData, setLineChartData] = useState({
        labels: [],
        data: [
            {
                label: "Paid Sales",
                data: [
                ]
            }
        ]
    });


    const [productPerformance, setProductPerformance] = useState({
        columns: [
            {
                dataField: "impressions",
                text: "Impressions",
                sort: true,
                type:  "string",
                insertFilter: "textFilter",
            },
            {
                dataField: "views",
                text: "Viewability Rate",
                sort: true,
                type:  "string",
                insertFilter: "textFilter",
            },
            {
                dataField: "dpv",
                text: "DPV",
                sort: true,
                type:  "string",
                insertFilter: "textFilter",
            },
            {
                dataField: "dpvr",
                text: "DPVR",
                sort: true,
                type:  "string",
                insertFilter: "textFilter",
            },
            {
                dataField: "purchases",
                text: "Purchases",
                sort: true,
                type:  "string",
                insertFilter: "textFilter",
            },
        ],
        data: [],
    })

    useEffect(() => {
        async function fetchData() {
            try {
                console.log("not sure what data to fetch here");

            } catch (error) {
                console.error("Error fetching data:", error);
            }
        }
        fetchData();
    },[token, brand, marketplace, selectedDefaultDates, currency]);



return (
    <div className="p-3">
        <Row className="mt-4">
            <Col style={{height: "34em"}}>
                <SpendPie
                    widgetID={"targetingPie"}
                    headerTitle="Sponsored Ads Targeting"
                    widgetData={[{
                        backgroundColor: pieColors,
                        labels: ["Awareness", "Purchase", "Loyalty",],
                        data: [400, 267, 140]
                    }]}
                    widgetFormatter={IntFormatter}
                    widgetDataLoading={pieChartLoading}
                />
            </Col>
        </Row>

        <Row className="mt-4">
            <Col style={{height: "34em"}}>
                {lineChartLoading ? <WidgetSpinner /> :(
                    <PerformanceOverTime showConfigButton={false}
                                         headerTitle="Performance Over Time"
                                         widgetData={lineChartData}
                    />
                )}
            </Col>
        </Row>

        <Row className="mt-4">
            <Col style={{height: "34em"}}>
                {tableLoading ? <WidgetSpinner /> :(
                    <BootstrapTablesWrapper
                        data={productPerformance.rows}
                        columns={productPerformance.columns}
                        columnModal="none"
                        keyField="target"
                        hasPagination={true}
                        hasFilter={false}
                        hasSearch={true}
                        showButtons={true}
                    />
                )}
            </Col>
        </Row>
    </div>
)
}

export default DSPPerformance;