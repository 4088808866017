import fortress from './fortress';

export async function customerRepeatAOV(token, params) {
  return fortress.get('brand/customers/repeatAOV', {
    headers: { Authorization: `Bearer ${token}` },
    params: params,
  });
}

export async function customersHeatmapZip(token, params) {
  return fortress.post('brand/customers/heatmap/zip', params, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export async function customersHeatmapState(token, params) {
  return fortress.post('brand/customers/heatmap/state', params, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}
